import { styled } from '../../../../../../theme';
import { Input } from '../../../../Input/Input';

export const StyledInput = styled(Input)`
  .MuiFilledInput-input {
    font-weight: 500;

    &::placeholder {
      font-weight: 400;
    }
  }
`;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Total = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 16V4M26.387 10L5.612 22M4.2 18.2A11.613 11.613 0 014 16a11.987 11.987 0 018-11.313v9L4.2 18.2zM16 4A12 12 0 115.65 22.075"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 16V4M26.387 10L5.612 22M3.2 17.2A11.613 11.613 0 013 15a11.987 11.987 0 018-11.313v9L3.2 17.2zM16 4A12 12 0 115.65 22.075"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 16V4M26.387 10L5.612 22M3.2 17.2A11.613 11.613 0 013 15a11.987 11.987 0 018-11.313v9L3.2 17.2zM16 4A12 12 0 115.65 22.075"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 16V4M26.387 10L5.612 22M4.2 18.2A11.613 11.613 0 014 16a11.987 11.987 0 018-11.313v9L4.2 18.2zM16 4A12 12 0 115.65 22.075" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Total;

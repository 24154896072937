import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Search = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.689 25.225c5.799 0 10.5-4.701 10.5-10.5 0-5.8-4.701-10.5-10.5-10.5-5.8 0-10.5 4.7-10.5 10.5 0 5.799 4.7 10.5 10.5 10.5zM22.113 22.15l6.075 6.075"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12.595 24.72c5.635 1.368 11.313-2.091 12.68-7.726 1.369-5.636-2.09-11.313-7.726-12.681C11.914 2.945 6.236 6.404 4.87 12.04c-1.369 5.635 2.09 11.312 7.726 12.68zM20.535 23.483l4.47 7.337"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12.595 24.72c5.635 1.368 11.313-2.091 12.68-7.726 1.369-5.636-2.09-11.313-7.726-12.681C11.914 2.945 6.236 6.404 4.87 12.04c-1.369 5.635 2.09 11.312 7.726 12.68zM20.535 23.483l4.47 7.337"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14.689 25.225c5.799 0 10.5-4.701 10.5-10.5 0-5.8-4.701-10.5-10.5-10.5-5.8 0-10.5 4.7-10.5 10.5 0 5.799 4.7 10.5 10.5 10.5zM22.113 22.15l6.075 6.075" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Search;

import { Fragment, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';

import { KitTheme, useTheme } from '../../../theme';
import {
  useEditableRowId,
  useSetAllEditableValuesContext,
} from '../contexts/EditableContext';
import { getDisplaysOfTreeLines } from '../helpers';
import { IRenderRowProps, TableBodyProps, TOnPaste } from '../types';

import { EditableRow } from './EditableRow';
import { CustomContextMenu } from './RowContextMenu/RowContextMenu';
import { useRowContextMenu } from './RowContextMenu/UseRowContextMenu';
import { TableRow } from './TableRow';
import {
  copyTableRows,
  getIsEditable,
  onCopy,
  prepareDataForEditInput,
  prepareValue,
} from './helpers';

const Row = <Data extends object, Theme = KitTheme>({
  allColumns,
  data,
  editableRowId,
  expanded,
  getCellPropsExternal,
  getColumnPropsExternal,
  getRowPropsExternal,
  handleCellClick,
  handleRowClick,
  index,
  prepareRow,
  renderRowSubComponent,
  row,
  rowActions,
  rows,
  singleColored = false,
  style = {},
  tableState,
  locale,
}: IRenderRowProps<Data, Theme>) => {
  const theme = useTheme();
  const preparedData = prepareDataForEditInput(allColumns, data);
  prepareRow(row);

  if (rowActions && rowActions?.show && row?.id === editableRowId) {
    return (
      <EditableRow<Data, Theme>
        id={row.id}
        cells={row.cells}
        values={row.values as Data}
        singleColored={singleColored}
        dataForEditInput={preparedData}
        getCellPropsExternal={getCellPropsExternal}
        getColumnPropsExternal={getColumnPropsExternal}
        // @ts-expect-error -- we expect external theme to inherit KitTheme
        rowProps={{ ...row.getRowProps(getRowPropsExternal(row, theme)) }}
        index={index}
        tableState={tableState}
        allColumns={allColumns}
        locale={locale}
      />
    );
  }

  const displaysOfTreeLines = getDisplaysOfTreeLines(
    row,
    rows[index - 1],
    rows[index + 1],
    index,
  );

  return (
    <Fragment key={row.id}>
      {row.depth > 0 && renderRowSubComponent ? null : (
        <TableRow<Data, Theme>
          {...row}
          displaysOfTreeLines={displaysOfTreeLines}
          expanded={expanded}
          index={index}
          handleRowClick={handleRowClick}
          singleColored={singleColored}
          // @ts-expect-error -- we expect external theme to inherit KitTheme
          rowProps={{ ...row.getRowProps(getRowPropsExternal(row, theme)) }}
          style={style}
          getCellPropsExternal={getCellPropsExternal}
          getColumnPropsExternal={getColumnPropsExternal}
          handleCellClick={handleCellClick}
          tableState={tableState}
          values={row.values as Data}
          allColumns={allColumns}
        />
      )}

      {row.isExpanded && renderRowSubComponent
        ? renderRowSubComponent(row)
        : null}
    </Fragment>
  );
};

export const TableBody = <Data extends object, Theme = KitTheme>({
  data,
  expanded,
  prepareRow,
  rows,
  tbodyProps,
  handleRowClick,
  handleCellClick,
  copyPasteMode,
  singleColored,
  rowActions,
  allColumns,
  getCellPropsExternal,
  getRowPropsExternal,
  getColumnPropsExternal,
  tableState,
  renderRowSubComponent,
  virtualized,
  handleCellUpdate,
  locale,
}: TableBodyProps<Data, Theme>) => {
  const { editableRowId, setEditableRowId } = useEditableRowId();
  const setAllEditableRowValues = useSetAllEditableValuesContext<Data>();
  const tableBodyRef = useRef<HTMLBodyElement>(null);
  const columnsLength = allColumns.length;

  const onClipboardCopy = (event: { target: any }) => {
    if (tableBodyRef.current && tableBodyRef.current.contains(event.target)) {
      copyTableRows(event, columnsLength);
    }
  };

  useEffect(() => {
    window.addEventListener('copy', onClipboardCopy);

    return () => {
      window.removeEventListener('copy', onClipboardCopy);
    };
  }, [onClipboardCopy]);

  const onEditableCellsPaste: TOnPaste<Data> = async (cells) => {
    try {
      const text = await navigator.clipboard.readText();

      const updatedValues = text
        .split('\t')
        .splice(0, cells.length)
        .map((value, i) => {
          const { row, column } = cells[i];
          const { index } = row;
          const { id } = column;
          const convertedValue = prepareValue(value);

          return {
            index,
            id,
            value: convertedValue,
            row,
            column,
          };
        });
      if (handleCellUpdate) {
        handleCellUpdate(updatedValues);
      }
    } catch (err) {
      console.error('Failed to read data from clipboard:', err);
    }
  };

  const onEditableFieldsPaste: TOnPaste<Data> = async (cells) => {
    try {
      const text = await navigator.clipboard.readText();
      const pasteValues = text.split('\t').splice(0, cells.length);

      const values: Record<string, unknown> = cells.reduce((acc, cell) => {
        const {
          row: { index },
          column: { id },
          value,
        } = cell;

        if (id === 'actions') {
          return acc;
        }

        if (getIsEditable(cell)) {
          acc[id] = prepareValue(pasteValues.shift());
        } else {
          pasteValues.shift();
          acc[id] = value;
        }

        if (!editableRowId) {
          setEditableRowId(String(index));
        }

        return acc;
      }, {} as Record<string, unknown>);

      setAllEditableRowValues(values as Data);
    } catch (err) {
      console.error('Failed to read data from clipboard:', err);
    }
  };

  const {
    handleContextMenu,
    handleCopy,
    handlePaste,
    menuPosition,
    menuVisible,
    menuId,
    handleClose,
  } = useRowContextMenu({
    onCopy,
    onEditableFieldsPaste,
    onEditableCellsPaste,
  });

  if (virtualized) {
    return (
      <div
        {...tbodyProps}
        ref={tableBodyRef}
        onContextMenu={(event) => handleContextMenu(event)}
      >
        <List
          height={virtualized.height}
          itemCount={rows.length}
          itemSize={virtualized.itemSize}
          width={virtualized.width || 0}
          itemData={rows}
        >
          {(listProps) => {
            const { index, style } = listProps;
            const row = listProps.data[index];

            return (
              <Fragment key={row.id}>
                {Row<Data, Theme>({
                  allColumns,
                  data,
                  editableRowId,
                  expanded,
                  getCellPropsExternal,
                  getColumnPropsExternal,
                  getRowPropsExternal,
                  handleCellClick,
                  handleRowClick,
                  index,
                  prepareRow,
                  renderRowSubComponent,
                  row,
                  rowActions,
                  rows,
                  singleColored,
                  style,
                  tableState,
                  copyPasteMode,
                })}
                <CustomContextMenu
                  isVisible={
                    !!(
                      copyPasteMode &&
                      menuVisible &&
                      menuId === row.id &&
                      !row.canExpand
                    )
                  }
                  position={menuPosition}
                  handleCopy={() => handleCopy(row.cells)}
                  handlePaste={() =>
                    handlePaste(row.cells, rowActions && rowActions?.show)
                  }
                  handleClose={handleClose}
                />
              </Fragment>
            );
          }}
        </List>
      </div>
    );
  }

  return (
    <div
      {...tbodyProps}
      ref={tableBodyRef}
      onContextMenu={(event) => handleContextMenu(event)}
    >
      {rows.map((row, index) => (
        <Fragment key={row.id}>
          {Row<Data, Theme>({
            allColumns,
            data,
            editableRowId,
            expanded,
            getCellPropsExternal,
            getColumnPropsExternal,
            getRowPropsExternal,
            handleCellClick,
            handleRowClick,
            index,
            prepareRow,
            renderRowSubComponent,
            row,
            rowActions,
            rows,
            singleColored,
            tableState,
            copyPasteMode,
            locale,
          })}
          <CustomContextMenu
            isVisible={
              !!(
                menuVisible &&
                copyPasteMode &&
                menuId === row.id &&
                !row.canExpand
              )
            }
            position={menuPosition}
            handleCopy={() => handleCopy(row.cells)}
            handlePaste={() =>
              handlePaste(row.cells, rowActions && rowActions?.show)
            }
            handleClose={handleClose}
          />
        </Fragment>
      ))}
    </div>
  );
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Login = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M3 16h14M12 11l5 5-5 5M17 5h7a1 1 0 011 1v20a1 1 0 01-1 1h-7"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 16h14M15 11l5 5-5 5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 5h7a1 1 0 011 1v20a1 1 0 01-1 1h-7"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 16h14M15 11l5 5-5 5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 5h7a1 1 0 011 1v20a1 1 0 01-1 1h-7"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 16h14M12 11l5 5-5 5M17 5h7a1 1 0 011 1v20a1 1 0 01-1 1h-7" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Login;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const FileSettings = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 28H7a1 1 0 01-1-1V5a1 1 0 011-1h12l7 7v16a1 1 0 01-1 1v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 4v7h7.001M16.031 21.5a2 2 0 100-4 2 2 0 000 4zM16.031 17.5V16M14.299 18.5L13 17.75M14.299 20.5L13 21.25M16.031 21.5V23M17.763 20.5l1.3.75M17.763 18.5l1.3-.75"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M25 28H7a1 1 0 01-1-1V5a1 1 0 011-1h12l7 7v16a1 1 0 01-1 1v0z" />
            <path d="M19 4v7h7.001M16.031 21.5a2 2 0 100-4 2 2 0 000 4zM16.031 17.5V16M14.299 18.5L13 17.75M14.299 20.5L13 21.25M16.031 21.5V23M17.763 20.5l1.3.75M17.763 18.5l1.3-.75" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default FileSettings;

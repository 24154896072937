export const TABLE_FIRST_PAGE = 0; // table counts pages from 0
export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGINATION = { limit: DEFAULT_PAGE_SIZE, skip: 0 };
export const RADIX = 10;
export const PAGE_SIZES = [20, DEFAULT_PAGE_SIZE, 100, 200]; // todo 500 option removed because https://jira.exante.eu/browse/ERU-506

export const selectCls = {
  height: '32px',
  boxSizing: 'border-box',
} as const;

export const textFieldCls = {
  height: '32px',
  width: '45px',
  boxSizing: 'border-box',
} as const;

export const debounceTimeout = 600;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const OrderPlasticCard = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11M23.546 15.271v10M18.546 20.273h10"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11M28.546 20.271h-10M23.545 15.271v10"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11M28.546 20.271h-10M23.545 15.271v10"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11M23.546 15.271v10M18.546 20.273h10" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default OrderPlasticCard;

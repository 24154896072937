import { Skeleton } from './Skeleton';
import { TableInfoStyled } from './TableSkeleton.styled';

interface TableInfoSkeletonProps {
  isHiddenColumnSelect?: boolean;
}

export const TableInfoSkeleton = ({
  isHiddenColumnSelect,
}: TableInfoSkeletonProps) => (
  <TableInfoStyled data-test-id="table-info-skeleton-root">
    <Skeleton width={154} data-test-id="table-info-skeleton-skeleton" />

    {!isHiddenColumnSelect && (
      <Skeleton
        data-test-id="table-info-skeleton-hidden-column-select"
        height={24}
        width={24}
      />
    )}
  </TableInfoStyled>
);

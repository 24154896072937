import { setHours, setMinutes, setSeconds } from 'date-fns';

type TTime = {
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export function setTime(date: Date, time: TTime): Date;
export function setTime(
  date: Date,
  hours: number,
  minutes: number,
  seconds: number,
): Date;
export function setTime(
  date: Date = new Date(),
  timeOrHours: number | TTime = 0,
  minutes = 0,
  seconds = 0,
): Date {
  if (typeof timeOrHours === 'object') {
    return setHours(
      setMinutes(
        setSeconds(date, timeOrHours.seconds || 0),
        timeOrHours.minutes || 0,
      ),
      timeOrHours.hours || 0,
    );
  }

  return setHours(setMinutes(setSeconds(date, seconds), minutes), timeOrHours);
}

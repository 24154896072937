import { useState, useEffect } from 'react';

import { getParamsFromUrl } from '../helpers/getParamsFromUrl';

interface UseSearchParamsProps {
  excludedParams?: string[];
  hasNegativeFilters: boolean;
}

export function useSearchParams({
  excludedParams,
  hasNegativeFilters,
}: UseSearchParamsProps) {
  const [queryParams, setQueryParams] = useState(() => {
    return getParamsFromUrl({ excludedParams });
  });

  useEffect(() => {
    if (!hasNegativeFilters) {
      return () => {};
    }

    // Function to update queryParams when the URL changes
    const handleLocationChange = () => {
      setQueryParams(getParamsFromUrl({ excludedParams }));
    };

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // We are overriding pushState and replaceState methods to generate custom events
    // This allows us to listen for changes when these methods are called
    (
      ['pushState', 'replaceState'] as Array<
        keyof Pick<typeof window.history, 'pushState' | 'replaceState'>
      >
    ).forEach((type) => {
      const original = window.history[type]; // Store the original function

      // Override the function
      window.history[type] = function historyEventFunction(
        ...args: [any, string, (string | URL | null | undefined)?]
      ) {
        // eslint-disable-next-line prefer-rest-params
        const result = original.apply(this, args); // Call the original function

        // Generate and dispatch a custom event
        // This enables us to listen for pushState and replaceState events
        const event = new Event(type);
        window.dispatchEvent(event);

        return result; // Return the result of the original function
      };
    });

    // Listen for URL changes, including our custom pushState and replaceState events
    window.addEventListener('popstate', handleLocationChange);
    window.addEventListener('pushState', handleLocationChange);
    window.addEventListener('replaceState', handleLocationChange);

    // Cleanup: remove event listeners and restore original history methods
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      window.removeEventListener('pushState', handleLocationChange);
      window.removeEventListener('replaceState', handleLocationChange);

      // Restore the original methods of history to avoid side effects
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return queryParams;
}

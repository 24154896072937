import {
  ArrowDownIconStyled,
  ArrowTopIconStyled,
  SortButtonContainer,
} from './SortButton.styled';

interface ISortButtonProps {
  isSorted?: boolean;
  desc?: boolean;
}

export const SortButton = ({ isSorted, desc }: ISortButtonProps) => {
  const upDisabled = isSorted && !desc;
  const downDisabled = isSorted && desc;

  return (
    <SortButtonContainer>
      <ArrowTopIconStyled
        data-test-id="KeyboardArrowUpIcon"
        data-inactive={upDisabled}
        inactive={upDisabled}
        size={16}
      />
      <ArrowDownIconStyled
        data-test-id="KeyboardArrowDownIcon"
        data-inactive={downDisabled}
        inactive={downDisabled}
        size={16}
      />
    </SortButtonContainer>
  );
};

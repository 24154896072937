import { Hooks, Row } from 'react-table';

import { SwapRows } from '../components';
import { TSwapRows } from '../types';

type TCellProps<Data extends object> = {
  row: Row<Data>;
  rows: Row<Data>[];
};

export const addSwapRowsColumn = <Data extends object>(
  hooks: Hooks<Data>,
  swapRows: TSwapRows<Data>,
  onSwapRows: (data: Data[]) => void,
) =>
  hooks.visibleColumns.push((allColumns) => [
    {
      disableFilters: true,
      disableSortBy: true,
      hideInMenu: true,
      id: 'swapRows',
      minWidth: 65,
      width: 65,
      Cell: ({ row, rows }: TCellProps<Data>) => (
        <SwapRows<Data>
          onSwapRows={onSwapRows}
          row={row}
          rows={rows}
          swapRowDown={swapRows.swapRowDown}
          swapRowUp={swapRows.swapRowUp}
        />
      ),
      ...swapRows.headerOptions,
    },
    ...allColumns,
  ]);

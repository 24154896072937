import { isNumber, isObject, isString } from 'lodash';
import { ReactElement } from 'react';

import { Tooltip } from '../../Tooltip';
import { WithTooltipWrapperStyled } from '../Table.styled';
import { prepareTooltipOptions } from '../helpers';
import { TAdditionalTooltipOptions } from '../types';

interface WithTooltipProps {
  children: ReactElement;
  value: unknown;
  tooltip: TAdditionalTooltipOptions['tooltip'];
}

const tooltipDataTestId = 'table-body__custom-tooltip';

export const WithTooltip = ({ children, value, tooltip }: WithTooltipProps) => {
  const title =
    typeof value !== 'object' &&
    (value || (isNumber(value) && Number.isFinite(value)))
      ? String(value)
      : '';
  const isStringType = isString(tooltip);
  const isWithoutTooltip = !tooltip || (isStringType && tooltip === 'off');
  const isCustomTooltip = isStringType && tooltip === 'custom';
  const isDefaultTooltip = isStringType && tooltip === 'default';

  if (isWithoutTooltip) {
    return children;
  }

  if (isCustomTooltip) {
    return (
      <Tooltip
        title={title}
        dataTestId={tooltipDataTestId}
        style={{ maxWidth: '100%' }}
      >
        {children}
      </Tooltip>
    );
  }

  if (isDefaultTooltip) {
    return (
      <WithTooltipWrapperStyled title={title}>
        {children}
      </WithTooltipWrapperStyled>
    );
  }

  if (isObject(tooltip) && tooltip.type === 'custom') {
    const preparedOptions = tooltip.options
      ? prepareTooltipOptions(tooltip.options, value)
      : {};

    return (
      <Tooltip
        title={title}
        dataTestId={tooltipDataTestId}
        {...preparedOptions}
        style={{ maxWidth: '100%' }}
      >
        {children}
      </Tooltip>
    );
  }

  return children;
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ResetPassword = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 7v18M13 12v4M9.196 14.764L13 16M10.649 19.236L13 16M15.351 19.236L13 16M16.804 14.764L13 16M25 12v4M21.196 14.764L25 16M22.649 19.236L25 16M27.351 19.236L25 16M28.804 14.764L25 16"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 7v18M9.382 15.618h4M12.146 19.422l1.236-3.804M16.618 17.97l-3.236-2.351M16.618 13.267l-3.236 2.351M12.146 11.814l1.236 3.804M27.558 18.177l-2.828-2.829M28.294 13.532l-3.564 1.816M24.104 11.397l.626 3.951M20.779 14.722l3.951.626M22.915 18.912l1.815-3.563"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 7v18M9.382 15.618h4M12.146 19.422l1.236-3.804M16.618 17.97l-3.236-2.351M16.618 13.267l-3.236 2.351M12.146 11.814l1.236 3.804M27.558 18.177l-2.828-2.829M28.294 13.532l-3.564 1.816M24.104 11.397l.626 3.951M20.779 14.722l3.951.626M22.915 18.912l1.815-3.563"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 7v18M13 12v4M9.196 14.764L13 16M10.649 19.236L13 16M15.351 19.236L13 16M16.804 14.764L13 16M25 12v4M21.196 14.764L25 16M22.649 19.236L25 16M27.351 19.236L25 16M28.804 14.764L25 16" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ResetPassword;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Link2 = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.762 20.238l8.476-8.488M18.125 22.363L14.588 25.9A6.001 6.001 0 116.1 17.413l3.537-3.538M22.363 18.125l3.537-3.537A6.001 6.001 0 1017.413 6.1l-3.538 3.538"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M11.762 20.238l8.476-8.488M18.125 22.363L14.588 25.9A6.001 6.001 0 116.1 17.413l3.537-3.538M22.363 18.125l3.537-3.537A6.001 6.001 0 1017.413 6.1l-3.538 3.538" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Link2;

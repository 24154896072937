import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const CardSettings = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.321 22.857a2.857 2.857 0 100-5.714 2.857 2.857 0 000 5.714zM23.321 17.143V15M20.84 18.571L19 17.5M20.84 21.429L19 22.5M23.321 22.857V25M25.804 21.429l1.839 1.071M25.804 18.571l1.839-1.071"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.465 20a2.857 2.857 0 105.714 0 2.857 2.857 0 00-5.714 0zM26.179 20h2.142M24.75 17.518l1.071-1.84M21.893 17.518l-1.072-1.84M20.464 20h-2.143M21.893 22.482l-1.072 1.84M24.75 22.482l1.071 1.84"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.465 20a2.857 2.857 0 105.714 0 2.857 2.857 0 00-5.714 0zM26.179 20h2.142M24.75 17.518l1.071-1.84M21.893 17.518l-1.072-1.84M20.464 20h-2.143M21.893 22.482l-1.072 1.84M24.75 22.482l1.071 1.84"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11" />
            <path d="M23.321 22.857a2.857 2.857 0 100-5.714 2.857 2.857 0 000 5.714zM23.321 17.143V15M20.84 18.571L19 17.5M20.84 21.429L19 22.5M23.321 22.857V25M25.804 21.429l1.839 1.071M25.804 18.571l1.839-1.071" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default CardSettings;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Tablet = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 6v20a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2H8a2 2 0 00-2 2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.25 23.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 3.333v20a2 2 0 002 2h16a2 2 0 002-2v-20a2 2 0 00-2-2H8a2 2 0 00-2 2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.25 20.834a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 3.333v20a2 2 0 002 2h16a2 2 0 002-2v-20a2 2 0 00-2-2H8a2 2 0 00-2 2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 19.334a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M6 6v20a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2H8a2 2 0 00-2 2z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.25 23.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Tablet;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Partnership = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9 26a4 4 0 100-8 4 4 0 000 8zM3 29a7.501 7.501 0 0112 0M9 11a4 4 0 100-8 4 4 0 000 8zM3 14a7.501 7.501 0 0112 0M23 26a4 4 0 100-8 4 4 0 000 8zM17 29a7.501 7.501 0 0112 0M23 11a4 4 0 100-8 4 4 0 000 8zM17 14a7.501 7.501 0 0112 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10 25a4 4 0 100-8 4 4 0 000 8zM4 28a7.501 7.501 0 0112 0M10 12a4 4 0 100-8 4 4 0 000 8zM4 15a7.501 7.501 0 0112 0M22 25a4 4 0 100-8 4 4 0 000 8zM16 28a7.501 7.501 0 0112 0M22 12a4 4 0 100-8 4 4 0 000 8zM16 15a7.501 7.501 0 0112 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10 25a4 4 0 100-8 4 4 0 000 8zM4 28a7.501 7.501 0 0112 0M10 12a4 4 0 100-8 4 4 0 000 8zM4 15a7.501 7.501 0 0112 0M22 25a4 4 0 100-8 4 4 0 000 8zM16 28a7.501 7.501 0 0112 0M22 12a4 4 0 100-8 4 4 0 000 8zM16 15a7.501 7.501 0 0112 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9 26a4 4 0 100-8 4 4 0 000 8zM3 29a7.501 7.501 0 0112 0M9 11a4 4 0 100-8 4 4 0 000 8zM3 14a7.501 7.501 0 0112 0M23 26a4 4 0 100-8 4 4 0 000 8zM17 29a7.501 7.501 0 0112 0M23 11a4 4 0 100-8 4 4 0 000 8zM17 14a7.501 7.501 0 0112 0" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Partnership;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ExternalAccounts = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 20a5 5 0 100-10 5 5 0 000 10zM7.975 24.92a9 9 0 0116.05 0M22 7h6M25 4v6M27.834 14A11.99 11.99 0 1118 4.166"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 20a5 5 0 100-10 5 5 0 000 10zM7.975 24.92a9 9 0 0116.05 0M25 4v6M28 7h-6M27.834 14A11.99 11.99 0 1118 4.166"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 20a5 5 0 100-10 5 5 0 000 10zM7.975 24.92a9 9 0 0116.05 0M25 4v6M28 7h-6M27.834 14A11.99 11.99 0 1118 4.166"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 20a5 5 0 100-10 5 5 0 000 10zM7.975 24.92a9 9 0 0116.05 0M22 7h6M25 4v6M27.834 14A11.99 11.99 0 1118 4.166" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ExternalAccounts;

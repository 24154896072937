export const prepareValueForOption = (value: unknown, separator?: string) => {
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value)) {
    return value.join(separator);
  }
  if (typeof value === 'object') {
    return '';
  }
  return String(value);
};

import { HeaderProps, Hooks } from 'react-table';

import { IndeterminateCheckbox } from '../components/IndeterminateCheckbox';
import { IndeterminateCheckboxHead } from '../components/IndeterminateCheckboxHead';
import { IRow } from '../types';

export const addExtraColumn = <Data extends object>(hooks: Hooks<Data>) =>
  hooks.visibleColumns.push((allColumns) => [
    {
      id: 'selectionBulk',
      width: 65,
      minWidth: 65,
      hideInMenu: true,
      disableFilters: true,
      Header: (header: HeaderProps<Data>) => (
        <IndeterminateCheckboxHead<Data> header={header} />
      ),
      Cell: ({ row }: { row: IRow<Data> }) => (
        <IndeterminateCheckbox<Data>
          {...row.getToggleRowSelectedProps({ title: '' })}
          row={row}
        />
      ),
    },
    ...allColumns,
  ]);

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const RescticProcessing = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.362 11.164h-20a1 1 0 00-1 1v14a1 1 0 001 1h20a1 1 0 001-1v-14a1 1 0 00-1-1zM11.862 11.164v-4.5a4.5 4.5 0 119 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.112 19.164a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26 11.164H6a1 1 0 00-1 1v14a1 1 0 001 1h20a1 1 0 001-1v-14a1 1 0 00-1-1zM11.5 11.164v-4.5a4.5 4.5 0 119 0v4.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.75 19.164a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26 11.164H6a1 1 0 00-1 1v14a1 1 0 001 1h20a1 1 0 001-1v-14a1 1 0 00-1-1zM11.5 11.164v-4.5a4.5 4.5 0 119 0v4.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 20.664a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M26.362 11.164h-20a1 1 0 00-1 1v14a1 1 0 001 1h20a1 1 0 001-1v-14a1 1 0 00-1-1zM11.862 11.164v-4.5a4.5 4.5 0 119 0"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.112 19.164a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default RescticProcessing;

import cn from 'classnames';
import { FC } from 'react';

import { DefaultThemeProvider } from '../../theme';

import { LoaderContainer, LoaderWrapper } from './Loader.styled';
import { SpinnerIcon } from './SpinnerIcon';
import { CLASSNAME, DEFAULT_SIZE } from './constants';
import { TLoaderProps } from './types';

/**
 * Indicator that replaces some content at the time of loading
 */
export const Loader: FC<TLoaderProps> = ({
  size = DEFAULT_SIZE,
  children,
  className,
  isCentered,
  isInner,
}) => {
  return (
    <DefaultThemeProvider>
      <LoaderWrapper
        data-test-id="loader"
        isCentered={!!isCentered}
        isAbsolute={!isInner}
        className={cn(className, CLASSNAME)}
      >
        <LoaderContainer size={size}>
          {children || <SpinnerIcon />}
        </LoaderContainer>
      </LoaderWrapper>
    </DefaultThemeProvider>
  );
};

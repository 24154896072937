import { HeaderGroup } from 'react-table';

import { IColumn } from '../types';

export const checkIsShowFooter = <Data extends object>(
  columns: IColumn<Data>[],
  footerGroups: HeaderGroup<Data>[],
) => {
  return columns.some((col) => {
    const argForFooter = footerGroups?.[0]?.headers?.find(
      (column) => column.id === col.id || col.accessor === column.id,
    );
    return Boolean(
      col.Footer &&
        argForFooter &&
        (typeof col.Footer === 'string'
          ? col.Footer
          : (col.Footer as (...args: any) => unknown)?.({
              column: argForFooter,
              data: [], // empty data for contract type
            })),
    );
  });
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Demo = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.087 9.615L16 16.135l11.913-6.52"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.48 3.125l11 6.023a1 1 0 01.52.875v11.955a1 1 0 01-.52.875l-11 6.022a1 1 0 01-.96 0l-11-6.022a1 1 0 01-.52-.875V10.023a1 1 0 01.52-.875l11-6.023a1 1 0 01.96 0zM16 16.136V29"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.088 7.615L16 14.135l11.913-6.52"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.48 1.125l11 6.022a1 1 0 01.52.875v11.955a1 1 0 01-.52.875l-11 6.023a1 1 0 01-.96 0l-11-6.023a1 1 0 01-.52-.875V8.022a1 1 0 01.52-.875l11-6.022a1 1 0 01.96 0zM16 14.137V27"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.088 7.615L16 14.135l11.913-6.52"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.48 1.125l11 6.022a1 1 0 01.52.875v11.955a1 1 0 01-.52.875l-11 6.023a1 1 0 01-.96 0l-11-6.023a1 1 0 01-.52-.875V8.022a1 1 0 01.52-.875l11-6.022a1 1 0 01.96 0zM16 14.137V27"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4.088 9.615L16 16.135l11.913-6.52" />
            <path d="M16.48 3.125l11 6.023a1 1 0 01.52.875v11.955a1 1 0 01-.52.875l-11 6.022a1 1 0 01-.96 0l-11-6.022a1 1 0 01-.52-.875V10.023a1 1 0 01.52-.875l11-6.023a1 1 0 01.96 0zM16 16.136V29" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Demo;

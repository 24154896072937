import { ActionsCell } from '../TableBody/ActionsCell/ActionsCell';
import type { RenderActionColumnCellParams } from '../types';

export const renderActionColumnCell = <Data extends object>({
  rowId,
  previousValues,
  onSaveHandler,
  onCancelEditingHandler,
  additionalRowActions,
  values,
  columns,
  isEditedRow,
  order,
  hideEdit,
}: RenderActionColumnCellParams<Data>) => {
  const wrappedOnSaveHandler = (updatedValues: Data) => {
    if (onSaveHandler) {
      return onSaveHandler(previousValues, updatedValues);
    }
    return null;
  };

  const wrappedOnCancelEditingHandler = (updatedValues: Data) => {
    if (onCancelEditingHandler) {
      return onCancelEditingHandler(previousValues, updatedValues);
    }
    return null;
  };

  return (
    <ActionsCell<Data>
      rowId={rowId}
      onSaveRow={onSaveHandler ? wrappedOnSaveHandler : null}
      onCancelEditingRow={
        onCancelEditingHandler ? wrappedOnCancelEditingHandler : null
      }
      additionalActions={additionalRowActions}
      values={values}
      columns={columns}
      isEditedRow={isEditedRow}
      order={order}
      hideEdit={hideEdit}
    />
  );
};

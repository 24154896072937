import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const LinkCustomization = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 9.514l3.758-3.75a6 6 0 018.485 8.485L22.485 18M9.514 14l-3.75 3.758a6 6 0 008.485 8.485L18 22.485"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12 20l8-8M14 9.514l3.758-3.75a6 6 0 018.485 8.485L22.485 18M9.514 14l-3.75 3.758a6 6 0 008.485 8.485L18 22.485"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12 20l8-8M14 9.514l3.758-3.75a6 6 0 018.485 8.485L22.485 18M9.514 14l-3.75 3.758a6 6 0 008.485 8.485L18 22.485"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14 9.514l3.758-3.75a6 6 0 018.485 8.485L22.485 18M9.514 14l-3.75 3.758a6 6 0 008.485 8.485L18 22.485" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default LinkCustomization;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Phone = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8 5v22a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H10a2 2 0 00-2 2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.25 24.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8 2.75v22a2 2 0 002 2h12a2 2 0 002-2v-22a2 2 0 00-2-2H10a2 2 0 00-2 2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.25 22.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8 2.75v22a2 2 0 002 2h12a2 2 0 002-2v-22a2 2 0 00-2-2H10a2 2 0 00-2 2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 20.75a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M8 5v22a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H10a2 2 0 00-2 2z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.25 24.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Phone;

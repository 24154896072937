import { TTransientProps } from '../../types';

import { IRootProps } from './types';

export const TRANSIENT_PROPS: Array<TTransientProps<IRootProps, HTMLElement>> =
  ['disableContentPaddings', 'maxHeight', 'disableBodyPaddings'];

export const TRANSIENT_TITLE_PROPS: Array<
  TTransientProps<IRootProps, HTMLElement>
> = ['disableBodyPaddings'];

export const TITLE_SIZES = [1, 2, 3, 4, 5, 6] as const;

export const TITLE_DEFAULT_SIZE: (typeof TITLE_SIZES)[number] = 2 as const;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const TradingSettings = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.333 12V5.09c0-.602-.434-1.09-.97-1.09H4.97C4.434 4 4 4.488 4 5.09v21.82c0 .602.434 1.09.97 1.09h8.363"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 11.66l-5.454 5.455-2.182-2.182L8 19.297"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 14.387V11.66h-2.727M24.893 26.154a3.162 3.162 0 10-3.162-5.476 3.162 3.162 0 003.162 5.476zM21.73 20.678l-1.185-2.054M20.15 23.416h-2.372M21.73 26.155l-1.185 2.053M24.893 26.154l1.185 2.054M26.474 23.416h2.371M24.893 20.678l1.185-2.053"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.333 14V5.09c0-.602-.434-1.09-.97-1.09H4.97C4.434 4 4 4.488 4 5.09v21.82c0 .602.434 1.09.97 1.09H16"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 11.66l-5.454 5.455-2.182-2.182L8 19.297"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 14.387V11.66h-2.727M20.15 23.417a3.162 3.162 0 106.323 0 3.162 3.162 0 00-6.323 0zM26.474 23.416h2.371M24.893 20.678l1.186-2.054M21.73 20.678l-1.185-2.054M20.15 23.417h-2.372M21.73 26.155l-1.185 2.054M24.893 26.155l1.185 2.054"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.333 14V5.09c0-.602-.434-1.09-.97-1.09H4.97C4.434 4 4 4.488 4 5.09v21.82c0 .602.434 1.09.97 1.09H16"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 11.66l-5.454 5.455-2.182-2.182L8 19.297"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 14.387V11.66h-2.727M20.15 23.417a3.162 3.162 0 106.323 0 3.162 3.162 0 00-6.323 0zM26.474 23.416h2.371M24.893 20.678l1.186-2.054M21.73 20.678l-1.185-2.054M20.15 23.417h-2.372M21.73 26.155l-1.185 2.054M24.893 26.155l1.185 2.054"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M25.333 12V5.09c0-.602-.434-1.09-.97-1.09H4.97C4.434 4 4 4.488 4 5.09v21.82c0 .602.434 1.09.97 1.09h8.363" />
            <path d="M20 11.66l-5.454 5.455-2.182-2.182L8 19.297" />
            <path d="M20 14.387V11.66h-2.727M24.893 26.154a3.162 3.162 0 10-3.162-5.476 3.162 3.162 0 003.162 5.476zM21.73 20.678l-1.185-2.054M20.15 23.416h-2.372M21.73 26.155l-1.185 2.053M24.893 26.154l1.185 2.054M26.474 23.416h2.372M24.893 20.678l1.185-2.053" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default TradingSettings;

import { Pagination } from '@mui/material';

import { styled } from '../../../theme';

export const TextStyled = styled('span')(({ theme }) => ({
  color: theme.color.typo.promo,
  fontFamily: theme.font.main,
  fontSize: '15px',
}));

export const PaginationRootStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.color.typo.primary,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: '24px',
  fontFamily: theme.font.main,
  boxShadow: theme.color.table.boxShadow.basic1,
  backgroundColor: theme.color.table.bg.basic2,
  gap: '8px',
  [theme?.breakpoints?.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    '.MuiPaginationItem-root': {
      minWidth: '28px',
      margin: '0 1px',
    },
  },
  // todo replace on TextStyled when fix https://jira.exante.eu/browse/RUI-262
  '& > div > span': {
    marginRight: '12px',
  },
}));

export const PaginationStyled = styled(Pagination)(({ theme }) => ({
  display: 'inline-block',
  color: theme.color.typo.primary,
  '& .MuiPaginationItem-root': {
    color: `${theme.color.typo.secondary}`,
    borderRadius: '4px',
    fontFamily: `${theme.font.main}`,
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.color.typo.primary}`,
      color: `${theme.color.typo.primary}`,
    },
  },
}));

export const PaginationControlStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

import * as dateLocales from 'date-fns/locale';

import { DEFAULT_LOCALE } from '../consts';

export const getDateLocale: (locale: string) => Locale = (
  locale = DEFAULT_LOCALE,
) => {
  // convert language to 'date-fns'-compliant locale
  // example: zh-cn => zhCN
  const localeDF =
    locale === 'en'
      ? 'enUS'
      : locale
          .split('-')
          .map((v, i) => (i === 1 ? v.toUpperCase() : v))
          .join('');

  const dateLocale = (<any>dateLocales)[localeDF];

  if (dateLocale == null) {
    console.warn(
      `Locale '${locale}' is not found in date-fns, defaulting to en-us`,
    );

    return dateLocales.enUS;
  }

  return dateLocale;
};

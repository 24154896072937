import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Warning = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 13v5M14.275 5l-11 19A2 2 0 005 27h22a1.999 1.999 0 001.725-3l-11-19a1.987 1.987 0 00-3.45 0v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M16 13v5M14.275 5l-11 19A2 2 0 005 27h22a1.999 1.999 0 001.725-3l-11-19a1.987 1.987 0 00-3.45 0v0z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Warning;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Analysis2 = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 28v-7M4 16h7M11.819 13.095c-.633.885-.875 1.982-.813 3.165a5 5 0 105.813-5.194.99.99 0 01-.819-.983V5a1 1 0 011.084-1c6.175.553 11 5.827 10.915 12.167-.09 6.463-5.365 11.738-11.828 11.828A12.001 12.001 0 014 16a11.85 11.85 0 012.775-7.638 1 1 0 011.475-.07l3.45 3.525a1 1 0 01.119 1.278z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.407 12.891l6.761 1.812M19.104 4.406l-1.812 6.762M19.887 12.711c-.692-.84-1.689-1.358-2.847-1.604a4.999 4.999 0 103.512 6.959.99.99 0 011.161-.537l4.91 1.316a1 1 0 01.686 1.306c-2.133 5.821-8.476 9.117-14.578 7.394-6.219-1.76-9.949-8.22-8.363-14.486a12 12 0 0114.736-8.652 11.85 11.85 0 016.66 4.657 1.001 1.001 0 01-.315 1.443l-4.298 2.42a1 1 0 01-1.264-.216z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.407 12.891l6.761 1.812M19.104 4.406l-1.812 6.762M19.887 12.711c-.692-.84-1.689-1.358-2.847-1.604a4.999 4.999 0 103.512 6.959.99.99 0 011.161-.537l4.91 1.316a1 1 0 01.686 1.306c-2.133 5.821-8.476 9.117-14.578 7.394-6.219-1.76-9.949-8.22-8.363-14.486a12 12 0 0114.736-8.652 11.85 11.85 0 016.66 4.657 1.001 1.001 0 01-.315 1.443l-4.298 2.42a1 1 0 01-1.264-.216z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 28v-7M4 16h7M11.819 13.095c-.633.885-.875 1.982-.813 3.165a5 5 0 105.813-5.194.99.99 0 01-.819-.983V5a1 1 0 011.084-1c6.175.553 11 5.827 10.915 12.167-.09 6.463-5.365 11.738-11.828 11.828A12.001 12.001 0 014 16a11.85 11.85 0 012.775-7.638 1 1 0 011.475-.07l3.45 3.525a1 1 0 01.119 1.278z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Analysis2;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Percent = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 7L7 25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.5 13a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM22.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 7L7 25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 14a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM21.5 25a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 7L7 25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 14a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM21.5 25a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path d="M25 7L7 25" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M9.5 13a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM22.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
              strokeMiterlimit={10}
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Percent;

import { ButtonGroup, Popover } from '@mui/material';

import { styled } from '../../../theme';
import { IconButton } from '../../Button/IconButton';

export const StyledColumnSelectWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const StyledColumnSelectPopover = styled(Popover)(({ theme }) => ({
  '.MuiPopover-paper': {
    padding: '16px',
    color: theme?.color?.typo?.primary,
    backgroundColor: theme?.color?.bg?.primary,
    boxShadow: theme?.effect?.controls?.drop,
  },
}));

export const StyledColumnSelectList = styled('ul')(() => ({
  listStyle: 'none',
  padding: 0,
  margin: '16px 0 0',
}));

export const StyledColumnSelectListItem = styled('li')(({ theme }) => ({
  marginBottom: '16px',
  fontSize: theme?.size?.text?.md,
}));

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `1px solid ${theme?.color?.bg?.basic}`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  flexGrow: 1,
  padding: '8px 15px',

  '&:hover': {
    backgroundColor: theme?.color?.bg?.basic,
  },
}));

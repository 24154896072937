import { omit } from 'lodash';

export class LocalStorage<Schema extends Record<string, unknown>> {
  key: string;

  constructor(key: string, initialState?: Schema) {
    this.key = key;

    if (initialState) {
      localStorage.setItem(this.key, JSON.stringify(initialState));
    }
  }

  get<Field extends keyof Schema>(field: Field): Schema[Field] | null {
    const data = localStorage.getItem(this.key);

    let parsed: Schema;

    try {
      parsed = JSON.parse(data || '{}') || {};
      return parsed[field];
    } catch (e) {
      console.error(`LocalStorage: ${e}`);

      return null;
    }
  }

  set<Field extends keyof Schema, Value extends Schema[Field]>(
    field: Field,
    value: Value,
  ) {
    const data = localStorage.getItem(this.key);

    let parsed: Schema;

    try {
      parsed = JSON.parse(data || '{}') || {};
      parsed[field] = value;

      const newData = JSON.stringify(parsed);

      localStorage.setItem(this.key, newData);
    } catch (e) {
      console.error(`LocalStorage: ${e}`);
    }
  }

  remove<Field extends keyof Schema>(field: Field) {
    const data = localStorage.getItem(this.key);

    let parsed: Schema;

    try {
      parsed = JSON.parse(data || '{}') || {};

      const newData = omit(parsed || {}, field);
      const stringifiedNewData = JSON.stringify(newData);

      localStorage.setItem(this.key, stringifiedNewData);
    } catch (e) {
      console.error(`LocalStorage: ${e}`);
    }
  }
}

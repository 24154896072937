import { clamp, max, min } from 'date-fns';

export const clampDateSafe = (
  date: Date,
  start?: Date | null,
  end?: Date | null,
) => {
  if (start && end) {
    return clamp(date, { start, end });
  }

  if (!start && end) {
    return min([date, end]);
  }

  if (!end && start) {
    return max([date, start]);
  }

  return date;
};

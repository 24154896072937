import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SystemMonitor = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 28h-8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.334 17.761a2.626 2.626 0 10-2.626-4.547 2.626 2.626 0 002.626 4.547zM14.708 13.214l-.985-1.705M13.395 15.487h-1.97M14.708 17.762l-.985 1.705M17.333 17.762l.985 1.705M18.646 15.487h1.97M17.334 13.214l.984-1.705"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM18.5 28H13"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.708 17.762a2.626 2.626 0 102.625-4.548 2.626 2.626 0 00-2.625 4.548zM17.333 13.214l.985-1.705M14.707 13.213l-.984-1.705M13.395 15.488h-1.97M14.708 17.762l-.985 1.705M17.333 17.762l.985 1.705M18.646 15.488h1.97"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM18.5 28H13"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.708 17.762a2.626 2.626 0 102.625-4.548 2.626 2.626 0 00-2.625 4.548zM17.333 13.214l.985-1.705M14.707 13.213l-.984-1.705M13.395 15.488h-1.97M14.708 17.762l-.985 1.705M17.333 17.762l.985 1.705M18.646 15.488h1.97"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 28h-8" />
            <path d="M17.334 17.761a2.626 2.626 0 10-2.626-4.548 2.626 2.626 0 002.626 4.548zM14.708 13.214l-.985-1.705M13.395 15.487h-1.97M14.708 17.762l-.985 1.705M17.333 17.762l.985 1.705M18.646 15.487h1.97M17.334 13.214l.984-1.705" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default SystemMonitor;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Favorites = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.55 23.837l6.3 4c.813.513 1.813-.25 1.575-1.187L22.6 19.475a1.087 1.087 0 01.362-1.1l5.65-4.713c.738-.612.363-1.85-.6-1.912l-7.375-.475a1.037 1.037 0 01-.912-.675l-2.75-6.925a1.038 1.038 0 00-1.95 0l-2.75 6.925a1.038 1.038 0 01-.912.675l-7.376.475c-.962.063-1.337 1.3-.6 1.912l5.65 4.713a1.087 1.087 0 01.363 1.1l-1.688 6.65c-.287 1.125.913 2.038 1.875 1.425l5.863-3.713a1.025 1.025 0 011.1 0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.55 23.837l6.3 4c.813.513 1.813-.25 1.575-1.187L22.6 19.475a1.087 1.087 0 01.362-1.1l5.65-4.713c.738-.612.363-1.85-.6-1.912l-7.375-.475a1.037 1.037 0 01-.912-.675l-2.75-6.925a1.038 1.038 0 00-1.95 0l-2.75 6.925a1.038 1.038 0 01-.912.675l-7.376.475c-.962.063-1.337 1.3-.6 1.912l5.65 4.713a1.087 1.087 0 01.363 1.1l-1.688 6.65c-.287 1.125.913 2.038 1.875 1.425l5.863-3.713a1.025 1.025 0 011.1 0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.55 23.837l6.3 4c.813.513 1.813-.25 1.575-1.187L22.6 19.475a1.087 1.087 0 01.362-1.1l5.65-4.713c.738-.612.363-1.85-.6-1.912l-7.375-.475a1.037 1.037 0 01-.912-.675l-2.75-6.925a1.038 1.038 0 00-1.95 0l-2.75 6.925a1.038 1.038 0 01-.912.675l-7.376.475c-.962.063-1.337 1.3-.6 1.912l5.65 4.713a1.087 1.087 0 01.363 1.1l-1.688 6.65c-.287 1.125.913 2.038 1.875 1.425l5.863-3.713a1.025 1.025 0 011.1 0z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.55 23.837l6.3 4c.813.513 1.813-.25 1.575-1.187L22.6 19.475a1.087 1.087 0 01.362-1.1l5.65-4.713c.738-.612.363-1.85-.6-1.912l-7.375-.475a1.037 1.037 0 01-.912-.675l-2.75-6.925a1.038 1.038 0 00-1.95 0l-2.75 6.925a1.038 1.038 0 01-.912.675l-7.376.475c-.962.063-1.337 1.3-.6 1.912l5.65 4.713a1.087 1.087 0 01.363 1.1l-1.688 6.65c-.287 1.125.913 2.038 1.875 1.425l5.863-3.713a1.025 1.025 0 011.1 0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={0.4}
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default Favorites;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ProtectionCheck = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 14.333V7a1 1 0 011-1h20a1 1 0 011 1v7.333c0 10.502-8.913 13.982-10.693 14.572a.944.944 0 01-.614 0C13.913 28.315 5 24.835 5 14.333z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.5 13l-7.333 7-3.667-3.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 14.333V7a1 1 0 011-1h20a1 1 0 011 1v7.333c0 10.502-8.913 13.982-10.693 14.572a.944.944 0 01-.614 0C13.913 28.315 5 24.835 5 14.333z" />
            <path d="M21.5 13l-7.333 7-3.667-3.5" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ProtectionCheck;

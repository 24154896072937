import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SwapRowDown = ({ svgProps: props, ...restProps }: IconProps) => (
  <IconWrapper
    iconDefault={
      <svg viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L4 4L7 1"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
    iconHover={
      <svg viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L4 4L7 1"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
    iconActive={
      <svg viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L4 4L7 1"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
    iconDisabled={
      <svg viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L4 4L7 1"
          opacity={0.4}
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
    {...restProps}
  />
);

export default SwapRowDown;

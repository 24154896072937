import {
  ChangeEvent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';

import { Autocomplete } from '../../../Inputs/Autocomplete';
import { Checkbox } from '../../../Inputs/Checkbox';
import { DatePicker, selectDateFormat } from '../../../Inputs/Date';
import { Input as BaseInput } from '../../../Inputs/Input/Input';
import { IUIInputProps } from '../../../Inputs/Input/types';
import { ISelectOption, Select } from '../../../Inputs/Select';
import { TDateValue } from '../../TableFilters/types';
import { useEditableRowValuesContext } from '../../contexts/EditableContext';
import { DEFAULT_TEXT_AREA_MIN_ROWS } from '../constants';

import {
  prepareCheckboxValue,
  prepareSelectOptions,
  prepareValueForMultiSelect,
} from './EditableCellInput.helpers';
import { EditableCellInputProps } from './EditableCellInput.types';

export const EditableCellInput = <Data extends object>({
  type,
  columnId,
  options = [],
  onChange,
  additionalInputProps,
  values,
  locale,
}: EditableCellInputProps<Data>) => {
  const { editableRowValues, updateRowValue } =
    useEditableRowValuesContext<Data>();
  const value = editableRowValues[columnId];
  const setValue = useCallback(
    (newValue: any) => updateRowValue(columnId, newValue),
    [columnId, updateRowValue],
  );

  const handleInputBlur = useCallback(
    ({ target: { value: newValue } }: { target: { value: string } }) => {
      if (onChange) {
        onChange(newValue, values as Data);
      }
      setValue(newValue);
    },
    [setValue, onChange],
  );

  const handleTextInputChange = useCallback(
    ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
      if (onChange && values) {
        onChange(newValue, values);
      }
      setValue(newValue);
    },
    [setValue, onChange],
  );
  const handleCheckboxChange = useCallback(
    (event: ChangeEvent, checked: boolean) => {
      if (onChange && values) {
        onChange(checked, values);
      }
      setValue(checked);
    },
    [setValue, onChange],
  );

  const handleDateChange = useCallback(
    (dateValue: TDateValue | Date) => {
      if (onChange && values) {
        onChange(dateValue, values);
      }
      setValue(dateValue);
    },
    [setValue, onChange],
  );

  const selectOptions = useMemo(
    () => prepareSelectOptions(options as ISelectOption[]),
    [options],
  );

  const handleMultiSelectChange = useCallback(
    (event: SyntheticEvent, selectedOptions: ISelectOption[]) => {
      if (onChange && values) {
        onChange(selectedOptions, values);
      }
      setValue(selectedOptions);
    },
    [setValue, onChange],
  );

  const renderBaseInput = (props?: Partial<IUIInputProps>) => (
    <BaseInput
      onBlur={handleInputBlur}
      onChange={handleTextInputChange}
      key={type}
      value={value ? String(value) : ''}
      size="small"
      sx={{
        width: 'auto',
        '& .MuiInputBase-root': {
          padding: 0,
        },
      }}
      {...props}
      {...additionalInputProps}
    />
  );

  const renderInput = (): ReactNode => {
    switch (type) {
      case 'select': {
        return (
          <Select
            options={selectOptions}
            onChange={handleInputBlur}
            value={value ? String(value) : ''}
            size="small"
            sx={{
              width: 'auto',
            }}
            {...additionalInputProps}
          />
        );
      }
      case 'multiSelect': {
        return (
          <Autocomplete
            options={selectOptions}
            onChange={handleMultiSelectChange}
            isMultiple
            value={prepareValueForMultiSelect(value, selectOptions)}
            size="small"
            isOptionEqualToValue={(
              option: (typeof selectOptions)[number],
              selectedValue: (typeof selectOptions)[number],
            ) => {
              if (typeof selectedValue === 'string') {
                return selectedValue === option.value;
              }
              return selectedValue.value === option.value;
            }}
            {...additionalInputProps}
          />
        );
      }
      case 'text': {
        return renderBaseInput();
      }
      case 'textArea': {
        return renderBaseInput({
          multiline: true,
          minRows: DEFAULT_TEXT_AREA_MIN_ROWS,
        });
      }
      case 'date': {
        return (
          <DatePicker
            value={value ? String(value) : ''}
            onChange={handleDateChange}
            size="small"
            inputFormat={selectDateFormat(locale)}
            {...additionalInputProps}
          />
        );
      }
      case 'checkbox': {
        return (
          <Checkbox
            name={type}
            onChange={handleCheckboxChange}
            checked={prepareCheckboxValue(String(value))}
            {...additionalInputProps}
          />
        );
      }
      default: {
        return type;
      }
    }
  };

  return (
    <span data-test-id="table__body--editable-cell-input-container">
      {renderInput()}
    </span>
  );
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const AddTrade = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22 7h6M25 4v6M27.834 14A11.99 11.99 0 1118 4.166"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.25 17.25l-4.438 4.438a1.063 1.063 0 01-1.5 0 1.062 1.062 0 010-1.5l4.438-4.438M15.647 9.146l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM21.147 14.646l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM17.104 11.104l3.792 3.792M13.604 14.604l3.792 3.792"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 4v6M28 7h-6M27.834 14A11.99 11.99 0 1118 4.166"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.25 17.25l-4.438 4.438a1.063 1.063 0 01-1.5 0 1.062 1.062 0 010-1.5l4.438-4.438M15.647 9.146l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM21.147 14.646l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM17.104 11.104l3.792 3.792M13.604 14.604l3.792 3.792"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 4v6M28 7h-6M27.834 14A11.99 11.99 0 1118 4.166"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.25 17.25l-4.438 4.438a1.063 1.063 0 01-1.5 0 1.062 1.062 0 010-1.5l4.438-4.438M15.647 9.146l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM21.147 14.646l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM17.104 11.104l3.792 3.792M13.604 14.604l3.792 3.792"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 7h6M25 4v6M27.834 14A11.99 11.99 0 1118 4.166" />
            <path d="M16.25 17.25l-4.438 4.438a1.063 1.063 0 01-1.5 0 1.062 1.062 0 010-1.5l4.438-4.438M15.647 9.146l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM21.147 14.646l-4 4a.5.5 0 000 .708l1 1a.5.5 0 00.707 0l4-4a.5.5 0 000-.707l-1-1a.5.5 0 00-.707 0zM17.104 11.104l3.792 3.792M13.604 14.604l3.792 3.792" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default AddTrade;

import { isString } from 'lodash';

import type { IColumn } from '../types';

export const getColumnKey = <Data extends object>(
  column: IColumn<Data>,
): string | undefined => {
  if (column.id) {
    return column.id;
  }

  return isString(column.accessor) ? column.accessor : undefined;
};

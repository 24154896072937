import { OutlinedInput } from '@mui/material';

import { styled } from '../../../../../../theme';

export const TimeInputGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '280px',
  gap: '10px',
  color: theme?.color?.typo?.secondary,
}));

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  padding: '8px 12px',
  fontWeight: '500',
  fontSize: theme?.size?.text?.sm,
  fontFamily: theme?.font?.main,
  lineHeight: '16px',

  '& fieldset': {
    borderColor: theme?.color?.input?.border,

    '&:hover': {
      borderColor: theme?.color?.input?.border,
    },
  },

  '& .MuiOutlinedInput-input': {
    color: theme?.color?.typo?.secondary,
    textAlign: 'center',
    padding: '0',
    '&::-webkit-inner-spin-button': {
      display: 'none',
    },
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme?.color?.input?.border,
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme?.color?.input?.border,
  },
}));

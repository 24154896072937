import { KitTheme } from '../../../theme';

export const getCalendarStyles = (theme: KitTheme) => {
  return {
    '.MuiPaper-root': {
      backgroundColor: theme?.color?.datepicker?.background,
      color: theme?.color?.typo?.secondary,
      '.MuiPickersDay-root': {
        '&.Mui-selected': {
          fontWeight: 600,
          color: theme?.color?.typo?.primary,
          backgroundColor: theme?.color?.datepicker?.daySelected,
          border: `1px solid ${theme?.color?.datepicker?.dayBorder}`,
        },
        '&.MuiPickersDay-today': {
          backgroundColor: theme?.color?.datepicker?.daySelected,
          border: 'none',
        },
        '&:hover': {
          backgroundColor: theme?.color?.datepicker?.dayHover,
        },
      },
      '.MuiIconButton-root': {
        '&:hover': {
          backgroundColor: theme?.color?.datepicker?.dayHover,
        },
      },
      '.MuiTypography-root': {
        fontWeight: 600,
        color: theme?.color?.datepicker?.colorWeek,
      },
      '.PrivatePickersYear-yearButton': {
        '&.Mui-selected': {
          backgroundColor: theme?.color?.datepicker?.daySelected,
          border: 'none',
          color: theme?.color?.typo?.primary,
        },
        '&:hover': {
          backgroundColor: theme?.color?.datepicker?.dayHover,
        },
      },
      '.PrivatePickersFadeTransitionGroup-root': {
        textTransform: 'capitalize',
      },
    },
  };
};

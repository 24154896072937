import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const PushKeys = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.94 7H4.06A1.06 1.06 0 003 8.06v15.88c0 .585.475 1.06 1.06 1.06h23.88A1.06 1.06 0 0029 23.94V8.06A1.06 1.06 0 0027.94 7zM7 16h18M7 12h18M7 20h1M12 20h8M24 20h1"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.94 7H4.06A1.06 1.06 0 003 8.06v15.88c0 .585.475 1.06 1.06 1.06h23.88A1.06 1.06 0 0029 23.94V8.06A1.06 1.06 0 0027.94 7zM7 16h18M7 13h18M7 19h1M12 19h8M24 19h1"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.94 7H4.06A1.06 1.06 0 003 8.06v15.88c0 .585.475 1.06 1.06 1.06h23.88A1.06 1.06 0 0029 23.94V8.06A1.06 1.06 0 0027.94 7zM7 16h18M7 13h18M7 19h1M12 19h8M24 19h1"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M27.94 7H4.06A1.06 1.06 0 003 8.06v15.88c0 .585.475 1.06 1.06 1.06h23.88A1.06 1.06 0 0029 23.94V8.06A1.06 1.06 0 0027.94 7zM7 16h18M7 12h18M7 20h1M12 20h8M24 20h1" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default PushKeys;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Profile = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_458_7663)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path d="M13.375 2.382a5.25 5.25 0 015.25 0l7.856 4.535a5.25 5.25 0 012.625 4.547v9.072c0 1.875-1 3.609-2.625 4.546l-7.856 4.536a5.25 5.25 0 01-5.25 0l-7.856-4.535a5.25 5.25 0 01-2.625-4.547v-9.072c0-1.876 1-3.609 2.625-4.547l7.856-4.535z" />
            <path
              d="M26.667 24.533a5.333 5.333 0 00-5.334-5.333H10.667a5.334 5.334 0 00-5.334 5.333"
              strokeLinejoin="round"
            />
            <path
              d="M16 19.267A5.333 5.333 0 1016 8.6a5.333 5.333 0 000 10.667z"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_458_7663">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_8454_102762)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path d="M13.375 2.382a5.25 5.25 0 015.25 0l7.856 4.535a5.25 5.25 0 012.625 4.547v9.072c0 1.875-1 3.609-2.625 4.546l-7.856 4.536a5.25 5.25 0 01-5.25 0l-7.856-4.535a5.25 5.25 0 01-2.625-4.547v-9.072c0-1.876 1-3.609 2.625-4.547l7.856-4.535z" />
            <path
              d="M26.667 24.533a5.333 5.333 0 00-5.334-5.333H10.667a5.334 5.334 0 00-5.334 5.333M16 17.267A5.333 5.333 0 1016 6.6a5.333 5.333 0 000 10.667z"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_8454_102762">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_18834_142903)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path d="M13.375 2.382a5.25 5.25 0 015.25 0l7.856 4.535a5.25 5.25 0 012.625 4.547v9.072c0 1.875-1 3.609-2.625 4.546l-7.856 4.536a5.25 5.25 0 01-5.25 0l-7.856-4.535a5.25 5.25 0 01-2.625-4.547v-9.072c0-1.876 1-3.609 2.625-4.547l7.856-4.535z" />
            <path
              d="M26.667 24.533a5.333 5.333 0 00-5.334-5.333H10.667a5.334 5.334 0 00-5.334 5.333M16 17.267A5.333 5.333 0 1016 6.6a5.333 5.333 0 000 10.667z"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_18834_142903">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            clipPath="url(#prefix__clip0_26947_630895)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path d="M13.375 2.382a5.25 5.25 0 015.25 0l7.856 4.535a5.25 5.25 0 012.625 4.547v9.072c0 1.875-1 3.609-2.625 4.546l-7.856 4.536a5.25 5.25 0 01-5.25 0l-7.856-4.535a5.25 5.25 0 01-2.625-4.547v-9.072c0-1.876 1-3.609 2.625-4.547l7.856-4.535z" />
            <path
              d="M26.667 24.533a5.333 5.333 0 00-5.334-5.333H10.667a5.334 5.334 0 00-5.334 5.333"
              strokeLinejoin="round"
            />
            <path
              d="M16 19.267A5.333 5.333 0 1016 8.6a5.333 5.333 0 000 10.667z"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_26947_630895">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Profile;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const CircleWavyQuestion = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <mask id="prefix__a" fill="currentColor">
            <path d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" />
          </mask>
          <path
            d="M16 30.5C7.992 30.5 1.5 24.008 1.5 16h-3c0 9.665 7.835 17.5 17.5 17.5v-3zM30.5 16c0 8.008-6.492 14.5-14.5 14.5v3c9.665 0 17.5-7.835 17.5-17.5h-3zM16 1.5c8.008 0 14.5 6.492 14.5 14.5h3c0-9.665-7.835-17.5-17.5-17.5v3zm0-3C6.335-1.5-1.5 6.335-1.5 16h3C1.5 7.992 7.992 1.5 16 1.5v-3z"
            fill="currentColor"
            mask="url(#prefix__a)"
          />
          <path
            d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <mask id="prefix__a" fill="currentColor">
            <path d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" />
          </mask>
          <path
            d="M16 30.5C7.992 30.5 1.5 24.008 1.5 16h-3c0 9.665 7.835 17.5 17.5 17.5v-3zM30.5 16c0 8.008-6.492 14.5-14.5 14.5v3c9.665 0 17.5-7.835 17.5-17.5h-3zM16 1.5c8.008 0 14.5 6.492 14.5 14.5h3c0-9.665-7.835-17.5-17.5-17.5v3zm0-3C6.335-1.5-1.5 6.335-1.5 16h3C1.5 7.992 7.992 1.5 16 1.5v-3z"
            fill="currentColor"
            mask="url(#prefix__a)"
          />
          <path
            d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <mask id="prefix__a" fill="currentColor">
            <path d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" />
          </mask>
          <path
            d="M16 30.5C7.992 30.5 1.5 24.008 1.5 16h-3c0 9.665 7.835 17.5 17.5 17.5v-3zM30.5 16c0 8.008-6.492 14.5-14.5 14.5v3c9.665 0 17.5-7.835 17.5-17.5h-3zM16 1.5c8.008 0 14.5 6.492 14.5 14.5h3c0-9.665-7.835-17.5-17.5-17.5v3zm0-3C6.335-1.5-1.5 6.335-1.5 16h3C1.5 7.992 7.992 1.5 16 1.5v-3z"
            fill="currentColor"
            mask="url(#prefix__a)"
          />
          <path
            d="M16 24a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            fill="currentColor"
          />
          <path
            d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4}>
            <mask id="prefix__a" fill="currentColor">
              <path d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" />
            </mask>
            <path
              d="M16 30.5C7.992 30.5 1.5 24.008 1.5 16h-3c0 9.665 7.835 17.5 17.5 17.5v-3zM30.5 16c0 8.008-6.492 14.5-14.5 14.5v3c9.665 0 17.5-7.835 17.5-17.5h-3zM16 1.5c8.008 0 14.5 6.492 14.5 14.5h3c0-9.665-7.835-17.5-17.5-17.5v3zm0-3C6.335-1.5-1.5 6.335-1.5 16h3C1.5 7.992 7.992 1.5 16 1.5v-3z"
              fill="currentColor"
              mask="url(#prefix__a)"
            />
            <path
              d="M16.75 22.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth={1.5}
            />
            <path
              d="M16 18v-1a3.5 3.5 0 10-3.5-3.5"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default CircleWavyQuestion;

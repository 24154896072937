import { FC } from 'react';

import { CommonValueProps } from '../types';

import { Wrapper } from './CommonValue.styled';

interface Props {
  data?: CommonValueProps;
}

const CommonValue: FC<Props> = ({ data }) =>
  data ? <Wrapper>{`${data.label}: ${data.value}`}</Wrapper> : null;

export default CommonValue;

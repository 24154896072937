import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const CallMeBack = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M20 7v5h5M20 12l6-6M20.549 18.168a1 1 0 01.948-.087l5.895 2.642a.999.999 0 01.6 1.037A6.04 6.04 0 0122 27 17 17 0 015 10a6.041 6.041 0 015.24-5.993 1 1 0 011.037.6l2.642 5.9a1 1 0 01-.083.942l-2.671 3.176a.99.99 0 00-.067.976c1.033 2.117 3.22 4.278 5.343 5.301a.99.99 0 00.979-.073l3.129-2.662z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M18 9v5h5M18 14l6-6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.549 18.168a1 1 0 01.948-.087l5.895 2.642a.999.999 0 01.6 1.037A6.04 6.04 0 0122 27 17 17 0 015 10a6.041 6.041 0 015.24-5.993 1 1 0 011.037.6l2.642 5.9a1 1 0 01-.083.942l-2.671 3.176a.99.99 0 00-.067.976c1.033 2.117 3.22 4.278 5.343 5.301a.99.99 0 00.979-.073l3.129-2.662z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M18 9v5h5M18 14l6-6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.549 18.168a1 1 0 01.948-.086l5.895 2.641a.999.999 0 01.6 1.038A6.04 6.04 0 0122 27 17 17 0 015 10a6.041 6.041 0 015.24-5.993 1 1 0 011.037.6l2.642 5.9a1 1 0 01-.083.941l-2.671 3.177a.99.99 0 00-.067.976c1.033 2.116 3.22 4.277 5.343 5.301a.99.99 0 00.979-.074l3.129-2.661z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M20 7v5h5M20 12l6-6M20.549 18.168a1 1 0 01.948-.087l5.895 2.642a.999.999 0 01.6 1.037A6.04 6.04 0 0122 27 17 17 0 015 10a6.041 6.041 0 015.24-5.993 1 1 0 011.037.6l2.642 5.9a1 1 0 01-.083.942l-2.671 3.176a.99.99 0 00-.067.976c1.033 2.117 3.22 4.278 5.343 5.301a.99.99 0 00.979-.073l3.129-2.662z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default CallMeBack;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const EmplyFolder = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11 26H4.923A.925.925 0 014 25.078V24M20 26h-4M28 19v6.111a.889.889 0 01-.889.889H25M21 10h6a1 1 0 011 1v3M4 10V7a1 1 0 011-1h6.586a1 1 0 01.707.293L16 10H4zM4 15v4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.403 26H5.326a.925.925 0 01-.652-.27M20.403 26h-4M27.515 26h-2.112M21.403 10h6a1 1 0 011 1v3M4.403 10V7a1 1 0 011-1h6.587a1 1 0 01.706.293L16.403 10h-12zM4.403 15v4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.693 25.5l3.25-8.875A1 1 0 018.872 16h6.02c.197 0 .39-.059.555-.168l2.5-1.665c.165-.108.358-.167.555-.167h10.691a1 1 0 01.949 1.316L27.693 26"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M11 26H4.923A.925.925 0 014 25.078V24M20 26h-4M28 19v6.111a.889.889 0 01-.889.889H25M21 10h6a1 1 0 011 1v3M4 10V7a1 1 0 011-1h6.586a1 1 0 01.707.293L16 10H4zM4 15v4" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default EmplyFolder;

import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Checkbox } from '../../../Inputs/Checkbox';
import { Tooltip } from '../../../Tooltip';
import { useBulkActions } from '../../contexts/BulkActionsContext';
import { IndeterminateInputProps } from '../../types';

const IndeterminateCheckboxHeadInner = <Data extends object>(
  { header, indeterminate, ...rest }: IndeterminateInputProps<Data>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;
  const isAllRowsSelected = header?.getToggleAllRowsSelectedProps().checked;
  const bulkActions = useBulkActions();

  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      if (bulkActions?.disabledAllSelect) {
        return;
      }
      if (isAllRowsSelected) {
        if (bulkActions?.setIsSelectAll) {
          bulkActions.setIsSelectAll(false);
        }
        bulkActions.setSelectedRows([]);
        header?.toggleAllRowsSelected(false);
      } else if (bulkActions.setIsSelectAll) {
        header?.toggleAllRowsSelected(true);
        bulkActions.setIsSelectAll(true);
        bulkActions.setSelectedRows([]);
      } else {
        header?.toggleAllRowsSelected(true);
        bulkActions.setSelectedRows(header?.columns[0].filteredRows ?? []);
      }

      e.stopPropagation();
    },
    [bulkActions, isAllRowsSelected, header],
  );

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  useEffect(() => {
    if (header && bulkActions?.setToggleAllRowsSelected) {
      bulkActions.setToggleAllRowsSelected(() => header?.toggleAllRowsSelected);
    }
  }, [header]);

  return (
    <Tooltip title={bulkActions?.tooltipAllSelect || ''}>
      <Checkbox
        {...rest}
        ref={resolvedRef}
        onClick={handleOnClick}
        checked={isAllRowsSelected || false}
        disabled={bulkActions?.disabledAllSelect}
      />
    </Tooltip>
  );
};

IndeterminateCheckboxHeadInner.displayName = 'IndeterminateCheckboxHead';

export const IndeterminateCheckboxHead = forwardRef(
  IndeterminateCheckboxHeadInner,
) as <Data extends object>(
  props: IndeterminateInputProps<Data> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof IndeterminateCheckboxHeadInner<Data>>;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Trades = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.172 28.489a3 3 0 100-6 3 3 0 000 6zM12.172 15.489a3 3 0 100-6 3 3 0 000 6zM20.172 23.489a3 3 0 100-6 3 3 0 000 6zM27.172 10.489a3 3 0 100-6 3 3 0 000 6zM10.75 15.13l-4.156 7.717M18.05 18.367l-3.757-3.757M25.75 10.13l-4.156 7.717"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.172 26.489a3 3 0 100-6 3 3 0 000 6zM12.172 15.489a3 3 0 100-6 3 3 0 000 6zM20.172 23.489a3 3 0 100-6 3 3 0 000 6zM27.172 12.489a3 3 0 100-6 3 3 0 000 6zM10.75 15.13L7.5 21M18.05 18.367l-3.757-3.757M25 12.5l-3.406 5.347"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.172 26.489a3 3 0 100-6 3 3 0 000 6zM12.172 15.489a3 3 0 100-6 3 3 0 000 6zM20.172 23.489a3 3 0 100-6 3 3 0 000 6zM27.172 12.489a3 3 0 100-6 3 3 0 000 6zM10.75 15.13L7.5 21M18.05 18.367l-3.757-3.757M25 12.5l-3.406 5.347"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5.172 28.489a3 3 0 100-6 3 3 0 000 6zM12.172 15.489a3 3 0 100-6 3 3 0 000 6zM20.172 23.489a3 3 0 100-6 3 3 0 000 6zM27.172 10.489a3 3 0 100-6 3 3 0 000 6zM10.75 15.13l-4.156 7.717M18.05 18.367l-3.757-3.757M25.75 10.13l-4.156 7.717" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Trades;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ReissueCard = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.545 24.727H6.182A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 14h11M23.636 23.637L28 19.273l-4.364-4.364"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.09 24.727V22a2.727 2.727 0 012.728-2.727H28"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.545 24.727H6.182A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 14h11M25.636 23.637L30 19.273l-4.364-4.364"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.09 24.727V22a2.727 2.727 0 012.728-2.727H30"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.545 24.727H6.182A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 14h11M25.636 23.637L30 19.273l-4.364-4.364"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.09 24.727V22a2.727 2.727 0 012.728-2.727H30"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M10.546 24.727H6.182A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 14h11" />
            <path d="M23.636 23.637L28 19.273l-4.364-4.364" />
            <path d="M17.09 24.727V22a2.727 2.727 0 012.728-2.727H28" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ReissueCard;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Map = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 13.334c0 9.333-12 17.333-12 17.333s-12-8-12-17.334a12 12 0 1124 0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 17.334a4 4 0 100-8 4 4 0 000 8z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M28 13.334c0 9.333-12 17.333-12 17.333s-12-8-12-17.334a12 12 0 1124 0z" />
            <path d="M16 17.334a4 4 0 100-8 4 4 0 000 8z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Map;

export const DEFAULT_SKELETON_ROWS_COUNT = 20;

export const paginationCount = Array.from(Array(10).keys());

export const defaultSkeletonSizes = {
  width: 16,
  height: 16,
  borderRadius: 1,
};

export const RESIZER_WIDTH = 3;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const CustomReports = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.91 19.999H20v6.91M14.286 10.715h8M14.286 19.285h2.571M9.714 10.715h2.571M9.714 19.286h2.571M18.857 14.999h3.43M9.714 14.999h6.857M14.286 9v3.429M14.286 17.571v3.43M18.857 13.286v3.429"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.91 19.999H20v6.91M12.571 10.715H22M13.429 19.285h3.428M10 10.715h.857M10 19.286h1.2M17 15h5M10 15h4M12.571 9v3.429M13.428 17.571v3.43M16.429 13.286v3.429"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.91 19.999H20v6.91M12.571 10.715H22M13.429 19.285h3.428M10 10.715h.857M10 19.286h1.2M17 15h5M10 15h4M12.571 9v3.429M13.428 17.571v3.43M16.429 13.286v3.429"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19.586 27H6a1 1 0 01-1-1V6a1 1 0 011-1h20a1 1 0 011 1v13.586a1 1 0 01-.293.707l-6.415 6.415a1 1 0 01-.706.292z" />
            <path d="M26.91 19.999H20v6.91M14.286 10.715h8M14.286 19.285h2.571M9.714 10.715h2.571M9.714 19.286h2.571M18.857 14.999h3.43M9.714 14.999h6.857M14.286 9v3.429M14.286 17.571v3.43M18.857 13.286v3.429" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default CustomReports;

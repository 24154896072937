import { useState, MouseEvent } from 'react';

import { TCells } from '../../types';
import { canCopyPaste, getParentDataRowId } from '../helpers';

import { IUseRowContextMenu, TMenuPosition } from './types';

export const useRowContextMenu = <Data extends object>({
  onCopy,
  onEditableFieldsPaste,
  onEditableCellsPaste,
}: IUseRowContextMenu<Data>) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState<TMenuPosition>({
    left: 0,
    top: 0,
  });
  const [menuId, setMenuId] = useState<string | null>(null);

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { clientX, clientY, target } = event;
    const id = getParentDataRowId(target as Element);

    setMenuPosition({ left: clientX, top: clientY });
    setMenuVisible(true);
    setMenuId(id);
  };

  const handleCopy = (cells: TCells<Data>) => {
    const values = cells
      .filter((cell) => canCopyPaste(cell))
      .reduce((acc, cell) => ({ ...acc, [cell.column.id]: cell.value }), {});

    setMenuVisible(false);
    setMenuId(null);
    onCopy(values as Data);
  };

  const handlePaste = (cells: TCells<Data>, isEditableFields?: boolean) => {
    setMenuVisible(false);
    setMenuId(null);

    const filteredCells = cells.filter((cell) => canCopyPaste(cell));

    return isEditableFields
      ? onEditableFieldsPaste(filteredCells)
      : onEditableCellsPaste(filteredCells);
  };

  const handleClose = () => {
    setMenuVisible(false);
    setMenuId(null);
  };

  return {
    menuVisible,
    menuPosition,
    handleContextMenu,
    handleCopy,
    handlePaste,
    menuId,
    handleClose,
  };
};

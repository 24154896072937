import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ArrowsCounterClockwise = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.975 12.462h-6v-6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.775 8.225a10.988 10.988 0 00-15.55 0l-4.25 4.238M22.025 19.538h6v6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.225 23.775a10.988 10.988 0 0015.55 0l4.25-4.237"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.182 14.473l-5.713 1.835-1.834-5.713"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.026 6.22A10.987 10.987 0 006.22 10.975l-2.75 5.334M22.818 17.527l5.713-1.835 1.834 5.713"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.974 25.78a10.989 10.989 0 0014.806-4.754l2.75-5.334"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.182 14.473l-5.713 1.835-1.834-5.713"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.026 6.22A10.987 10.987 0 006.22 10.975l-2.75 5.334M22.818 17.527l5.713-1.835 1.834 5.713"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.974 25.78a10.989 10.989 0 0014.806-4.754l2.75-5.334"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9.975 12.462h-6v-6" />
            <path d="M23.775 8.225a10.988 10.988 0 00-15.55 0l-4.25 4.238M22.025 19.538h6v6" />
            <path d="M8.225 23.775a10.987 10.987 0 0015.55 0l4.25-4.237" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ArrowsCounterClockwise;

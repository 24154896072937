import { getColumnKey } from './helpers';
import type { IColumn } from './types';

export const setSavedWidth = <Data extends Record<string, any>>(
  column: IColumn<Data>,
  savedWidths: { [Key in keyof Data]: string | number },
) => {
  const columnClone = { ...column };
  const columnKey = getColumnKey(column);

  if (columnKey && savedWidths[columnKey]) {
    columnClone.width = savedWidths[columnKey];
  }

  return columnClone;
};

const rangeTypes = ['numberRange', 'dateRange'];

export const setFilterType = <Data extends object>(
  column: IColumn<Data>,
): IColumn<Data> => {
  const columnClone = { ...column };

  if (column.type && rangeTypes.includes(column.type)) {
    columnClone.filter = 'between';
  }
  return columnClone;
};

export type TSetSavedWidth = typeof setSavedWidth;
export type TSetFilterType = typeof setFilterType;

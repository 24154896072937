interface IUseNegative {
  queryParams: Record<string, unknown>;
}

export function useNegative({ queryParams }: IUseNegative) {
  return {
    negative: queryParams.negative,
  };
}

export type TUseNegativeResult = ReturnType<typeof useNegative>;

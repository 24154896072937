import type { THorizontalAlign } from 'types';

import { formatters } from '../constants';

import { getDefaultAlignment } from './getDefaultAlignment';

export const getStyles = (
  align?: THorizontalAlign,
  className?: string,
  formatting?: keyof typeof formatters,
) => {
  const alignment = align || getDefaultAlignment(formatting);
  const justifyContent = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };
  const style = {
    justifyContent: justifyContent[alignment],
    alignItems: 'center',
    display: 'flex',
  };

  return { style, className };
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Desktop = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 28h-8M4 19h24M16 24v4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 21.334h20a2 2 0 002-2v-14a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 25.334h-8M4 16.334h24M16 21.334v4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 21.334h20a2 2 0 002-2v-14a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 25.334h-8M4 16.334h24M16 21.334v4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 28h-8M4 19h24M16 24v4" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Desktop;

import { Paper } from '@mui/material';
import React, { FC } from 'react';
import { CalendarContainerProps } from 'react-datepicker';

import { CalendarBody, StyledCalendarContainer } from './DatePicker.styled';

export const DatePickerContainer: FC<CalendarContainerProps> = ({
  className,
  children,
  showPopperArrow,
  arrowProps,
}) => {
  // last child is the custom content of ReactDatePicker,
  // which should be placed in the separate div, outside of month container
  const childrenRest = [...(children as [])];
  const last = childrenRest.pop();

  return (
    <Paper elevation={5}>
      <StyledCalendarContainer className={className}>
        {showPopperArrow && (
          <div className="react-datepicker__triangle" {...arrowProps} />
        )}
        <CalendarBody>{childrenRest}</CalendarBody>
        {last}
      </StyledCalendarContainer>
    </Paper>
  );
};

import { NEGATIVE_FIELD_NAME } from '../constants';

/**
 * Modify the 'negative' parameter in the given URL by adding or removing a specified key.
 * If url is not provided, it defaults to the current browser URL.
 * @param {string} operation - The operation to perform: "add" or "remove".
 * @param {string} keyToModify - The key to add or remove from the 'negative' parameter.
 * @param {string} [url] - The original URL. If not provided, it defaults to the current browser URL.
 * @returns {string} - The modified URL.
 */
export function modifyNegative(
  operation: 'add' | 'remove',
  keyToModify: string,
  url: string = window.location.href,
): string {
  // Parse the URL into an object
  const urlObject = new URL(url);

  // Get the query parameters from the URL
  const params = new URLSearchParams(urlObject.search);

  // Retrieve the current value of the 'negative' parameter
  const negativeValue = params.get(NEGATIVE_FIELD_NAME) || '';

  const keys = new Set(negativeValue ? negativeValue.split(',') : []);

  // Add or remove the specified key from the 'negative' object based on the operation
  if (operation === 'remove') {
    keys.delete(keyToModify);
  } else if (operation === 'add') {
    keys.add(keyToModify);
  }

  // Convert the 'negative' object back to a string
  const newNegativeValue = Array.from(keys).join(',');

  // Set the new value for the 'negative' parameter
  if (newNegativeValue) {
    params.set(NEGATIVE_FIELD_NAME, newNegativeValue);
  } else {
    params.delete(NEGATIVE_FIELD_NAME);
  }

  // Update the URL with the modified parameters
  urlObject.search = params.toString();

  // Convert the URL object back to a string and return
  const modifiedUrl = urlObject.toString();

  // Update the browser's address bar with the modified URL
  if (modifiedUrl !== window.location.href) {
    window.history.replaceState(null, '', modifiedUrl);
  }

  return modifiedUrl;
}

import { TTransientProps } from '../../../types';

import { IUlProps } from './types';

export const TRANSIENT_PROPS: Array<TTransientProps<IUlProps, HTMLElement>> = [
  'loading',
];

export const ON_CHANGE_REASON = {
  CREATE_OPTION: 'createOption',
  SELECT_OPTION: 'selectOption',
  REMOVE_OPTION: 'removeOption',
  BLUR: 'blur',
  CLEAR: 'clear',
};

export const ON_INPUT_CHANGE_REASON = {
  INPUT: 'input',
  RESET: 'reset',
  CLEAR: 'clear',
};

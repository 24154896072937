import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Risk = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.272 14.463V7.114a1 1 0 011-1h20a1 1 0 011 1v7.346c0 10.523-8.914 14.01-10.693 14.6a.942.942 0 01-.615 0c-1.779-.588-10.692-4.07-10.692-14.597zM16.272 22.114v-5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.272 13.864a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.272 14.463V7.114a1 1 0 011-1h20a1 1 0 011 1v7.346c0 10.523-8.914 14.01-10.693 14.6a.942.942 0 01-.615 0c-1.779-.588-10.692-4.07-10.692-14.597zM16.272 20.114v-5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.272 11.864a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.272 14.463V7.114a1 1 0 011-1h20a1 1 0 011 1v7.346c0 10.523-8.914 14.01-10.693 14.6a.942.942 0 01-.615 0c-1.779-.588-10.692-4.07-10.692-14.597zM16.272 20.114v-5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.272 11.864a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4}>
            <path
              d="M5.272 14.463V7.114a1 1 0 011-1h20a1 1 0 011 1v7.346c0 10.523-8.914 14.01-10.693 14.6a.942.942 0 01-.615 0c-1.779-.588-10.692-4.07-10.692-14.597zM16.272 22.114v-5"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.272 13.864a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Risk;

import { forwardRef } from 'react';
// eslint-disable-next-line import/no-unresolved
import { IMaskInput } from 'react-imask';

import { StyledInput } from './DatePickerInput.styled';
import { IDatePickerInputProps } from './types';

const DatePickerInputMask = forwardRef<HTMLInputElement, any>((props, ref) => {
  const { onChange, isUseOnAccept, ...rest } = props;

  const handleAccept = (value: string) =>
    onChange({
      target: { name: props.name, value },
      isDefaultPrevented: () => false,
    });

  // Conditionally include onAccept if isUseOnAccept is true
  const inputProps = isUseOnAccept
    ? {
        ...rest,
        onAccept: handleAccept,
      }
    : rest;

  return <IMaskInput {...inputProps} inputRef={ref} onChange={onChange} />;
});
DatePickerInputMask.displayName = 'DatePickerInputMask';

export const DatePickerInput = forwardRef<
  HTMLDivElement,
  IDatePickerInputProps
>(({ mask, placeholder, label, onAccept, isUseOnAccept, ...rest }, ref) => {
  const isHiddenLabel = rest.size === 'small';
  return (
    <StyledInput
      {...rest}
      inputRef={ref}
      MaskedInputComponent={DatePickerInputMask}
      placeholder={label}
      inputProps={{
        mask,
        isUseOnAccept,
        onAccept,
        placeholder,
        label: isHiddenLabel ? label : '',
      }}
    />
  );
});
DatePickerInput.displayName = 'DatePickerInput';

import { times } from 'lodash';

export const generateYears = (
  date: Date,
  yearCount: number,
  minDate?: Date,
  maxDate?: Date,
): [number, string][] => {
  const years = times(yearCount * 2 + 1).map((y) => {
    return date.getFullYear() - yearCount + y;
  });

  return years
    .filter((y) => {
      const min = minDate?.getFullYear();
      const max = maxDate?.getFullYear();

      if (max && min) {
        return y <= max && y >= min;
      }

      if (!min && max) {
        return y <= max;
      }

      if (!max && min) {
        return y >= min;
      }

      return true;
    })
    .map((y) => [y, String(y)]);
};

import { entries } from 'lodash';
import { Filters } from 'react-table';

import { isValidFilterValue } from './isValidFilterValue';

type TFilters = Record<string, unknown>;

export const filtersToArray = (filters: TFilters) => {
  const filtersArray = entries(filters)
    .map(([id, value]) => ({
      id,
      value,
    }))
    .filter(({ value }) => isValidFilterValue(value));

  return filtersArray;
};

const isFiltersArray = (
  filters: TFilters | TFilters[],
): filters is TFilters[] => Array.isArray(filters);

export const filtersToMap = <Data extends object>(
  filters: TFilters | Filters<Data>,
) => {
  let filtersArray: Filters<Data>;

  if (isFiltersArray(filters)) {
    filtersArray = filters;
  } else {
    filtersArray = filtersToArray(filters);
  }

  return filtersArray.reduce<TFilters>((acc, t) => {
    acc[t.id] = t.value;
    return acc;
  }, {});
};

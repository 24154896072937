import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SendByEmail = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 12v13a1 1 0 01-1 1H5a1 1 0 01-1-1V12l12-8 12 8zM13.819 19l-9.51 6.718M27.691 25.718L18.181 19"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 12l-9.817 7h-4.364L4 12M15.836 8.151v4M12.033 10.915l3.803 1.236M13.485 15.388l2.351-3.237M18.188 15.388l-2.352-3.237M19.64 10.915l-3.804 1.236"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 12v13a1 1 0 01-1 1H5a1 1 0 01-1-1V12l12-8 12 8zM13.819 19l-9.51 6.718M27.691 25.718L18.181 19"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 12l-9.817 7h-4.364L4 12M19.331 12.706l-3.864-1.035M17.646 8.316l-2.178 3.355M12.95 8.562l2.518 3.109M11.733 13.104l3.735-1.433M15.677 15.664l-.21-3.994"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 12v13a1 1 0 01-1 1H5a1 1 0 01-1-1V12l12-8 12 8zM13.819 19l-9.51 6.718M27.691 25.718L18.181 19"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 12l-9.817 7h-4.364L4 12M19.331 12.706l-3.864-1.035M17.646 8.316l-2.178 3.355M12.95 8.562l2.518 3.109M11.733 13.104l3.735-1.433M15.677 15.664l-.21-3.994"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M28 12v13a1 1 0 01-1 1H5a1 1 0 01-1-1V12l12-8 12 8zM13.819 19l-9.51 6.718M27.691 25.718L18.181 19" />
            <path d="M28 12l-9.817 7h-4.364L4 12M15.836 8.151v4M12.033 10.915l3.803 1.236M13.485 15.388l2.351-3.237M18.188 15.388l-2.352-3.237M19.64 10.915l-3.804 1.236" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default SendByEmail;

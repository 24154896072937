import {
  getHours,
  getMinutes,
  getSeconds,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { ChangeEvent } from 'react';

import { TIME_PICKER_INPUT_GROUP_DATA_TEST_ID } from '../../constants';
import { TDateCond } from '../../types';
import { clampDateSafe } from '../helpers';

import { StyledOutlinedInput, TimeInputGroup } from './TimePicker.styled';
import { setTime } from './helpers';
import { TTimePickerProps, TTimeSectionModifier } from './types';

export const TimePicker = <WithRange extends boolean | undefined = undefined>({
  selected,
  onChange,
  minDate,
  maxDate,
  defaults,
}: TTimePickerProps<WithRange>) => {
  const selectedSafe = selected || setTime(new Date(), defaults || {});

  const handleChange = (
    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    timeModifier: TTimeSectionModifier,
  ) => {
    const valueInt = parseInt(evt.currentTarget.value, 10);

    if (!Number.isNaN(valueInt)) {
      const selectedNew = timeModifier(selectedSafe, valueInt);
      onChange(
        clampDateSafe(selectedNew, minDate, maxDate) as TDateCond<WithRange>,
        evt,
      );
    }
  };

  const hours = `0${getHours(selectedSafe) || 0}`.slice(-2);
  const minutes = `0${getMinutes(selectedSafe) || 0}`.slice(-2);
  const seconds = `0${getSeconds(selectedSafe) || 0}`.slice(-2);

  return (
    <TimeInputGroup data-test-id={TIME_PICKER_INPUT_GROUP_DATA_TEST_ID}>
      <StyledOutlinedInput
        type="number"
        inputProps={{ min: 0, max: 24 }}
        onChange={(evt) => handleChange(evt, setHours)}
        value={hours}
      />
      <span>:</span>
      <StyledOutlinedInput
        type="number"
        inputProps={{ min: 0, max: 60 }}
        onChange={(evt) => handleChange(evt, setMinutes)}
        value={minutes}
      />
      <span>:</span>
      <StyledOutlinedInput
        type="number"
        inputProps={{ min: 0, max: 60 }}
        onChange={(evt) => handleChange(evt, setSeconds)}
        value={seconds}
      />
    </TimeInputGroup>
  );
};

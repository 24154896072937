import { formatters } from '../../../helpers/formatters';
import type { DefaultTableCellProps } from '../TableBody/types';

export const FormattedTableCell = <Data extends object>({
  value,
  column,
}: DefaultTableCellProps<Data>) => {
  const { formatting, type } = column;
  const { dateFormat } = column;

  const isDateColumn =
    type === 'dateInput' || type === 'dateRange' || type === 'dateTimeRange';

  if (!formatting) {
    return value;
  }

  const format = formatters[formatting];

  if (isDateColumn) {
    return <div>{format(value, dateFormat, !!column?.useLocale)}</div>;
  }

  return <div>{format(value)}</div>;
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const PDF = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 21h-4v7M26 25h-3M6 26h2a2.5 2.5 0 000-5H6v7M14 21v7h2a3.5 3.5 0 100-7h-2zM6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 4v7h7"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 21h-4v7M26 25h-3M6 26h2a2.5 2.5 0 000-5H6v7M14 21v7h2a3.5 3.5 0 100-7h-2zM6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.831 9.5l4.95 4.95 4.95-4.95"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 21h-4v7M26 25h-3M6 26h2a2.5 2.5 0 000-5H6v7M14 21v7h2a3.5 3.5 0 100-7h-2zM6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.831 9.5l4.95 4.95 4.95-4.95"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M27 21h-4v7M26 25h-3M6 26h2a2.5 2.5 0 000-5H6v7M14 21v7h2a3.5 3.5 0 100-7h-2zM6 16V5a1 1 0 011-1h12l7 7v5" />
            <path d="M19 4v7h7" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default PDF;

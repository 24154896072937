import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const WebTrade = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 28h-8M16 24v4M4 19h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.667 10.333l-6.06 6.06-2.425-2.423-4.849 4.848"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.667 13.364v-3.03h-3.03"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 23.667h20a2 2 0 002-2v-14a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 27.667h-8M16 23.667v4M4 19h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.667 8l-6.06 6.06-2.425-2.424-4.849 4.849"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.667 11.03V8h-3.03"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 23.667h20a2 2 0 002-2v-14a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 27.667h-8M16 23.667v4M4 19h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.667 8l-6.06 6.06-2.425-2.424-4.849 4.849"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.667 11.03V8h-3.03"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 24h20a2 2 0 002-2V8a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2zM20 28h-8M16 24v4M4 19h24" />
            <path d="M22.667 10.333l-6.061 6.06-2.424-2.423-4.849 4.848" />
            <path d="M22.667 13.364v-3.03h-3.03" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default WebTrade;

import { PopoverProps } from '@mui/material';
import {
  DateTimePicker as DateTimePickerMUI,
  DateTimePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isDate, isValid } from 'date-fns';
import debounce from 'debounce';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_LOCALE } from '../../../../consts';
import { getDateLocale } from '../../../../helpers/getDateLocale';
import { DefaultThemeProvider } from '../../../../theme';
import { CalendarIcon } from '../../../Icons';
import { Input } from '../../Input/Input';
import { IUIInputProps } from '../../Input/types';
import { TSizes } from '../DatePicker/types';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_MASK,
  INPUT_DATE_TIME_PICKER_DATA_TEST_ID,
} from '../constants';
import { getCalendarStyles } from '../helpers';

const components = {
  OpenPickerIcon: CalendarIcon,
};

export interface IDateTimePickerProps
  extends Omit<DateTimePickerProps<Date | string, Date>, 'renderInput'> {
  size?: TSizes;
  name?: string;
  message?: ReactNode;
  placeholder?: string;
  iconLeft?: ReactNode;
  controlProps?: IUIInputProps;
  locale?: string;
}

/**
 * This component is deprecated.
 * Use `InputDatePicker` instead with the `showTimeInput` prop enabled.
 *
 * @deprecated
 */
export const DateTimePicker = ({
  onChange,
  value = null,
  iconLeft,
  size,
  controlProps: { inputProps, ...controlProps } = { inputProps: {} },
  label,
  ampm = false,
  inputFormat = DEFAULT_DATE_TIME_FORMAT,
  mask = DEFAULT_DATE_TIME_MASK,
  locale = DEFAULT_LOCALE,
  ...rest
}: IDateTimePickerProps) => {
  const [date, setDate] = useState(value);
  const isHiddenLabel = size === 'small';

  const handleAccept = useCallback(
    (newDate: Date | null) => {
      setDate(newDate);

      if (isValid(newDate)) {
        onChange(newDate);
      }
    },
    [onChange],
  );

  const debouncedHandleChange = useMemo(
    () => debounce(handleAccept, 500),
    [handleAccept],
  );

  const handleClosePicker = useCallback(() => {
    if (isDate(date)) {
      onChange(date as Date);
    }
  }, [date, onChange]);

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <DefaultThemeProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getDateLocale(locale)}
      >
        <DateTimePickerMUI
          onAccept={handleAccept}
          onClose={handleClosePicker}
          onChange={debouncedHandleChange}
          value={date}
          renderInput={({ inputProps: muiInputProps, ...params }) => (
            <Input
              iconLeft={iconLeft}
              size={size}
              inputProps={{ ...muiInputProps, ...inputProps }}
              data-test-id={INPUT_DATE_TIME_PICKER_DATA_TEST_ID}
              {...controlProps}
              {...params}
              error={params.error || controlProps?.error}
            />
          )}
          label={isHiddenLabel ? '' : label}
          components={components}
          ampm={ampm}
          inputFormat={inputFormat}
          mask={mask}
          PopperProps={{ sx: getCalendarStyles as PopoverProps['sx'] }}
          {...rest}
        />
      </LocalizationProvider>
    </DefaultThemeProvider>
  );
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Transfer = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11 16.863l11.238 9.887a1 1 0 001.637-.525l4.7-20.525a1 1 0 00-1.337-1.15L4.163 13.613c-.925.362-.8 1.712.175 1.912L11 16.863zM11 16.863L28.012 4.575"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.613 21.8l-3.9 3.9A1 1 0 0111 25v-8.138"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13 14.863l11.238 9.887a1 1 0 001.637-.525l4.7-20.525a1 1 0 00-1.337-1.15L6.163 11.613c-.925.362-.8 1.712.175 1.912L13 14.863zM13 14.863L30.012 2.575"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.613 19.8l-3.9 3.9A1 1 0 0113 23v-8.138"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13 14.863l11.238 9.887a1 1 0 001.637-.525l4.7-20.525a1 1 0 00-1.337-1.15L6.163 11.613c-.925.362-.8 1.712.175 1.912L13 14.863zM13 14.863L30.012 2.575"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.613 19.8l-3.9 3.9A1 1 0 0113 23v-8.138"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M11 16.863l11.238 9.887a1 1 0 001.637-.525l4.7-20.525a1 1 0 00-1.337-1.15L4.163 13.613c-.925.362-.8 1.712.175 1.912L11 16.863zM11 16.863L28.012 4.575" />
            <path d="M16.613 21.8l-3.9 3.9A1 1 0 0111 25v-8.138" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Transfer;

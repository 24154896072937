import type { CustomTooltipWithOptions } from '../types';

export const prepareTooltipOptions = (
  tooltipOptions: CustomTooltipWithOptions['options'],
  value: unknown,
) => {
  if (!tooltipOptions?.Title) {
    return tooltipOptions;
  }

  const { Title, ...rest } = tooltipOptions;

  return {
    ...rest,
    title: Title(value),
  };
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Agreements = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12 19h8M12 15h8M20 5h5a1 1 0 011 1v21a1 1 0 01-1 1H7a1 1 0 01-1-1V6a1 1 0 011-1h5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 9V8a5 5 0 0110 0v1H11z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 13v8M18 13v8M20 5h5a1 1 0 011 1v21a1 1 0 01-1 1H7a1 1 0 01-1-1V6a1 1 0 011-1h5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 9V8a5 5 0 0110 0v1H11z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 13v8M18 13v8M20 5h5a1 1 0 011 1v21a1 1 0 01-1 1H7a1 1 0 01-1-1V6a1 1 0 011-1h5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 9V8a5 5 0 0110 0v1H11z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 19h8M12 15h8M20 5h5a1 1 0 011 1v21a1 1 0 01-1 1H7a1 1 0 01-1-1V6a1 1 0 011-1h5" />
            <path d="M11 9V8a5 5 0 0110 0v1H11z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Agreements;

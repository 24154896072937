import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const PaymentsPartner = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 7H4a1 1 0 00-1 1v16a1 1 0 001 1h24a1 1 0 001-1V8a1 1 0 00-1-1zM21 21h4M15 21h2M3 12h26"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 5H4a1 1 0 00-1 1v16a1 1 0 001 1h24a1 1 0 001-1V6a1 1 0 00-1-1zM21 19h4M15 19h2M3 10h26"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 5H4a1 1 0 00-1 1v16a1 1 0 001 1h24a1 1 0 001-1V6a1 1 0 00-1-1zM21 19h4M15 19h2M3 10h26"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M28 7H4a1 1 0 00-1 1v16a1 1 0 001 1h24a1 1 0 001-1V8a1 1 0 00-1-1zM21 21h4M15 21h2M3 12h26" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default PaymentsPartner;

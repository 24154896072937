import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const KYC = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.172 21.194a.964.964 0 100-1.929.964.964 0 000 1.93z"
            fill="currentColor"
          />
          <path
            d="M16.172 17.337v-.642a2.25 2.25 0 10-2.25-2.25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.991 26.389a12 12 0 10-4.375-4.375l-1.564 4.669a1 1 0 001.265 1.265l4.674-1.56z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.172 21.194a.964.964 0 100-1.929.964.964 0 000 1.93z"
            fill="currentColor"
          />
          <path
            d="M16.172 17.337v-.642a2.25 2.25 0 10-2.25-2.25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.991 26.389a12 12 0 10-4.375-4.375l-1.564 4.669a1 1 0 001.265 1.265l4.674-1.56z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.172 21.194a.964.964 0 100-1.929.964.964 0 000 1.93z"
            fill="currentColor"
          />
          <path
            d="M16.172 17.337v-.642a2.25 2.25 0 10-2.25-2.25"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.991 26.389a12 12 0 10-4.375-4.375l-1.564 4.669a1 1 0 001.265 1.265l4.674-1.56z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M8.001 6h19a1 1 0 011 1v15"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.001 10h-18a1 1 0 00-1 1v14a1 1 0 001 1h18a1 1 0 001-1V11a1 1 0 00-1-1z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.386 21.23a.214.214 0 11-.428 0 .214.214 0 01.428 0z"
              fill="currentColor"
            />
            <path
              d="M14.172 18.337v-.642a2.25 2.25 0 10-2.25-2.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default KYC;

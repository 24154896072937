import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../consts';
import { formatDate, formatNumber } from '../formatters';

import { getBrowserLocale } from './getBrowserLocale';
import { toUTCDate } from './toUTCDate';

export const formatters = {
  number: (value: unknown, opt?: Intl.NumberFormatOptions) =>
    formatNumber(value, opt, getBrowserLocale),

  date: (value: string, dateFormat = DATE_FORMAT, useLocale = false) =>
    formatDate({ date: value, format: dateFormat, useLocale, toUTCDate }),

  dateTime: (
    value: string,
    dateFormat = DATE_WITH_TIME_FORMAT,
    useLocale = false,
  ) =>
    formatDate({
      date: value,
      withTime: true,
      format: dateFormat,
      useLocale,
      toUTCDate,
    }),

  dateUTC: (value: string, dateFormat = DATE_FORMAT, useLocale = false) =>
    formatDate({
      date: value,
      withTime: false,
      dateUTC: true,
      format: dateFormat,
      useLocale,
      toUTCDate,
    }),

  dateTimeUTC: (
    value: string,
    dateFormat = DATE_WITH_TIME_FORMAT,
    useLocale = false,
  ) =>
    formatDate({
      date: value,
      withTime: false,
      dateUTC: true,
      dateTimeUTC: true,
      format: dateFormat,
      useLocale,
      toUTCDate,
    }),
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Delete = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.75 8.5h22.5M11 8.5V6a2.5 2.5 0 012.5-2.5h5A2.5 2.5 0 0121 6v2.5m3.75 0V26a2.5 2.5 0 01-2.5 2.5H9.75a2.5 2.5 0 01-2.5-2.5V8.5h17.5zM13.5 14.75v7.5M18.5 14.75v7.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.75 8.5h22.5M11 8.5V6a2.5 2.5 0 012.5-2.5h5A2.5 2.5 0 0121 6v2.5m3.75 0V26a2.5 2.5 0 01-2.5 2.5H9.75a2.5 2.5 0 01-2.5-2.5V8.5h17.5zM19.75 14.169l-7.456 7.456M12.098 14.169l7.456 7.456"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.75 8.5h22.5M11 8.5V6a2.5 2.5 0 012.5-2.5h5A2.5 2.5 0 0121 6v2.5m3.75 0V26a2.5 2.5 0 01-2.5 2.5H9.75a2.5 2.5 0 01-2.5-2.5V8.5h17.5zM19.75 14.169l-7.456 7.456M12.098 14.169l7.456 7.456"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4.75 8.5h22.5M11 8.5V6a2.5 2.5 0 012.5-2.5h5A2.5 2.5 0 0121 6v2.5m3.75 0V26a2.5 2.5 0 01-2.5 2.5H9.75a2.5 2.5 0 01-2.5-2.5V8.5h17.5zM13.5 14.75v7.5M18.5 14.75v7.5" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Delete;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const General = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.991 28.003h8M23 3a12.838 12.838 0 014.536 5M4.464 8A12.839 12.839 0 019 3M7 14a9 9 0 0118 0c0 4.477 1.038 7.075 1.863 8.5A1 1 0 0126 24H6a1 1 0 01-.86-1.5C5.964 21.075 7 18.476 7 14z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.991 27.003h8M24 2a12.838 12.838 0 014.536 5M3.464 7A12.839 12.839 0 018 2M7 14a9 9 0 0118 0c0 4.477 1.038 7.075 1.863 8.5A1 1 0 0126 24H6a1 1 0 01-.86-1.5C5.964 21.075 7 18.476 7 14z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.991 27.003h8M24 2a12.838 12.838 0 014.536 5M3.464 7A12.839 12.839 0 018 2M7 14a9 9 0 0118 0c0 4.477 1.038 7.075 1.863 8.5A1 1 0 0126 24H6a1 1 0 01-.86-1.5C5.964 21.075 7 18.476 7 14z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M11.991 28.003h8M23 3a12.838 12.838 0 014.536 5M4.464 8A12.839 12.839 0 019 3M7 14a9 9 0 0118 0c0 4.477 1.038 7.075 1.863 8.5A1 1 0 0126 24H6a1 1 0 01-.86-1.5C5.964 21.075 7 18.476 7 14z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default General;

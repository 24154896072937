import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SecurityPassword = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 14.338V7a1 1 0 011-1h20a1 1 0 011 1v7.338c0 10.5-8.913 13.975-10.688 14.562a.9.9 0 01-.625 0C13.913 28.313 5 24.837 5 14.338zM16 12v5M11.25 15.45L16 17M13.063 21.05L16 17M18.938 21.05L16 17M20.75 15.45L16 17"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 14.338V7a1 1 0 011-1h20a1 1 0 011 1v7.338c0 10.5-8.913 13.975-10.688 14.562a.9.9 0 01-.625 0C13.913 28.313 5 24.837 5 14.338zM20.37 17.696l-4.829-1.294M18.267 12.215l-2.726 4.187M12.39 12.516l3.151 3.886M10.869 18.191l4.672-1.79M15.809 21.391l-.268-4.989"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 14.338V7a1 1 0 011-1h20a1 1 0 011 1v7.338c0 10.5-8.913 13.975-10.688 14.562a.9.9 0 01-.625 0C13.913 28.313 5 24.837 5 14.338zM20.37 17.696l-4.829-1.294M18.267 12.215l-2.726 4.187M12.39 12.516l3.151 3.886M10.869 18.191l4.672-1.79M15.809 21.391l-.268-4.989"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 14.338V7a1 1 0 011-1h20a1 1 0 011 1v7.338c0 10.5-8.913 13.975-10.688 14.562a.9.9 0 01-.625 0C13.913 28.313 5 24.837 5 14.338zM16 12v5M11.25 15.45L16 17M13.063 21.05L16 17M18.938 21.05L16 17M20.75 15.45L16 17" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default SecurityPassword;

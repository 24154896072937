import { FC } from 'react';

import { IconButton } from '../../Button/IconButton';

import { TEyeButtonProps } from './types';

export const EyeButton: FC<TEyeButtonProps> = ({ onClick, isPasswordType }) => {
  return (
    <IconButton
      className="IconButton"
      data-test-id="input_password-icon"
      iconColor="secondary"
      iconName="EyeIcon"
      iconSize={16}
      isActive={isPasswordType}
      onClick={onClick}
    />
  );
};

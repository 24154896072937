import { format, setMonth } from 'date-fns';
import { times } from 'lodash';

import { isWithinIntervalSafe } from '../../helpers';

export const generateMonths = (
  locale: Locale,
  date: Date,
  minDate?: Date,
  maxDate?: Date,
): [number, string][] => {
  return times(12)
    .filter((m) =>
      isWithinIntervalSafe({
        date: setMonth(date, m),
        start: minDate,
        end: maxDate,
        ignoreDayAndTime: true,
      }),
    )
    .map((m) => [m, format(setMonth(new Date(), m), 'LLLL', { locale })]);
};

import type { IColumn } from '../types';

export const flatColumns = <Data extends object>(columns: IColumn<Data>[]) => {
  const result: IColumn<Data>[] = [];
  const queue = [...columns];

  while (queue.length > 0) {
    const front = queue.shift();

    if (front === undefined) {
      break;
    }

    result.push(front);

    if ('columns' in front) {
      queue.push(...front.columns);
    }
  }

  return result;
};

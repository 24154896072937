import { isFinite } from 'lodash';
import { useCallback, useState } from 'react';

import {
  DEFAULT_PAGINATION,
  TABLE_FIRST_PAGE,
} from '../../TablePagination/const';
import { calculateOffset } from '../../utils';
import type { IUsePaginationProps, TSetPage } from '../types';
import { PaginationParams } from '../useQueryParams/constants';

export function usePagination({ getDefaultPagination }: IUsePaginationProps) {
  const { skip: defaultSkip, limit: defaultLimit } = getDefaultPagination
    ? getDefaultPagination()
    : DEFAULT_PAGINATION;

  const getInitialPage = () => {
    return isFinite(defaultSkip) && isFinite(defaultLimit)
      ? Math.floor(defaultSkip / defaultLimit)
      : TABLE_FIRST_PAGE;
  };

  const [limit, setLimit] = useState(defaultLimit);
  const [skip, setSkip] = useState(defaultSkip);
  const [page, setPage] = useState(getInitialPage);

  const resetPage = useCallback(() => setPage(TABLE_FIRST_PAGE), []);

  const handleLimitChange = useCallback(
    (newLimit: number) => {
      setLimit(newLimit);
      setSkip(calculateOffset(TABLE_FIRST_PAGE, newLimit));
      resetPage();
    },
    [resetPage],
  );

  const handlePageChange = useCallback<TSetPage>(
    (newPage) => {
      setPage(newPage);
      setSkip(calculateOffset(newPage as number, limit));
    },
    [limit],
  );

  // TODO: possible solution for pagination and sorting
  const setPagination = {
    [PaginationParams.page]: handlePageChange,
    [PaginationParams.limit]: handleLimitChange,
    [PaginationParams.skip]: setSkip,
  };

  return {
    page,
    limit,
    skip,
    setLimit: handleLimitChange,
    setPage: handlePageChange,
    setSkip,
    setPagination,
  };
}

export type TUsePaginationResult = ReturnType<typeof usePagination>;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Home = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 26v-6a1 1 0 00-1-1h-4a1 1 0 00-1 1v6a1 1 0 01-1 1H6a1 1 0 01-1-1V14.443a1 1 0 01.327-.74l10-9.443a1 1 0 011.347 0l10 9.443a.999.999 0 01.327.74V26a1 1 0 01-1 1H20a1 1 0 01-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 24v-6a1 1 0 00-1-1h-4a1 1 0 00-1 1v6a1 1 0 01-1 1H6a1 1 0 01-1-1V12.443a1 1 0 01.327-.74l10-9.443a1 1 0 011.347 0l10 9.443a.999.999 0 01.327.74V24a1 1 0 01-1 1H20a1 1 0 01-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 24v-6a1 1 0 00-1-1h-4a1 1 0 00-1 1v6a1 1 0 01-1 1H6a1 1 0 01-1-1V12.443a1 1 0 01.327-.74l10-9.443a1 1 0 011.347 0l10 9.443a.999.999 0 01.327.74V24a1 1 0 01-1 1H20a1 1 0 01-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 26v-6a1 1 0 00-1-1h-4a1 1 0 00-1 1v6a1 1 0 01-1 1H6a1 1 0 01-1-1V14.443a1 1 0 01.327-.74l10-9.443a1 1 0 011.347 0l10 9.443a.999.999 0 01.327.74V26a1 1 0 01-1 1H20a1 1 0 01-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={0.4}
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default Home;

import { ACTIONS_COLUMN } from '../hooks/useColumnsKeys/constants';
import type { IColumn } from '../types';

export const addInitialColumnKeysOnEditable = <Data extends object>(
  order: string[],
  columns: IColumn<Data>[],
): string[] => {
  const tableEditable = columns.some(
    (column) => column?.editParams && column.editParams.editable,
  );

  if (!tableEditable) {
    return order;
  }

  return !order.includes(ACTIONS_COLUMN) ? order.concat(ACTIONS_COLUMN) : order;
};

import { HTMLAttributes } from 'react';

import { ArrowDownIcon, ArrowTopIcon } from '../../../Icons';
import { blockNonNativeProps } from '../../../helpers';
import { styled } from '../../../theme';

export type SortButtonProps = HTMLAttributes<HTMLSpanElement>;

type TArrowIconStyledProps = {
  inactive?: boolean;
};

export const SortButtonContainer = styled('span')<SortButtonProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 2,
    height: '100%',
    justifyContent: 'center',

    '& .MuiIcon-colorAction': {
      color: theme.color.icon.primary,
    },
    '& .MuiIcon-colorDisabled': {
      color: theme.color.icon.secondary,
    },
  }),
);

export const ArrowTopIconStyled = styled(ArrowTopIcon, {
  shouldForwardProp: blockNonNativeProps(['inactive']),
})<TArrowIconStyledProps>(({ inactive }) => ({
  transform: `translateY(3px)`,
  ...(inactive ? { opacity: 0.6 } : {}),
}));

export const ArrowDownIconStyled = styled(ArrowDownIcon, {
  shouldForwardProp: blockNonNativeProps(['inactive']),
})<TArrowIconStyledProps>(({ inactive }) => ({
  transform: `translateY(-3px)`,
  ...(inactive ? { opacity: 0.6 } : {}),
}));

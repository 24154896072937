import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SendMessage = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 27H5.959A.957.957 0 015 26.041V15.5A11.5 11.5 0 1116.5 27z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM11 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM22 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 27H5.959A.957.957 0 015 26.041V15.5A11.5 11.5 0 1116.5 27z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM12 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM21 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 27H5.959A.957.957 0 015 26.041V15.5A11.5 11.5 0 1116.5 27z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM12 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM21 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4}>
            <path
              d="M16.5 27H5.959A.957.957 0 015 26.041V15.5A11.5 11.5 0 1116.5 27z"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM11 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM22 17.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default SendMessage;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Margin = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 20a4 4 0 100-8 4 4 0 000 8zM28 16h-8M24.488 7.513l-5.663 5.662"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10 26.392C15.74 29.706 23.079 27.74 26.392 22 29.706 16.26 27.74 8.922 22 5.608S8.921 4.26 5.608 10C2.294 15.74 4.26 23.08 10 26.392z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 19.464a4 4 0 104-6.928 4 4 0 00-4 6.928zM26.392 22l-6.928-4M27.594 12.894l-7.735 2.072"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10 26.392C15.74 29.706 23.079 27.74 26.392 22 29.706 16.26 27.74 8.922 22 5.608S8.921 4.26 5.608 10C2.294 15.74 4.26 23.08 10 26.392z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 19.464a4 4 0 104-6.928 4 4 0 00-4 6.928zM26.392 22l-6.928-4M27.594 12.894l-7.735 2.072"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z" />
            <path d="M16 20a4 4 0 100-8 4 4 0 000 8zM28 16h-8M24.488 7.513l-5.663 5.662" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Margin;

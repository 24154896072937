import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Stopwatch = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 28c6.075 0 11-4.925 11-11S22.075 6 16 6 5 10.925 5 17s4.925 11 11 11z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
          <path
            d="M16 17l4.95-4.95M13 2h6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M16 28c6.075 0 11-4.925 11-11S22.075 6 16 6 5 10.925 5 17s4.925 11 11 11z"
              strokeMiterlimit={10}
            />
            <path
              d="M16 17l4.95-4.95M13 2h6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Stopwatch;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Clip = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.314 13.196l-12.979 12.52a5.758 5.758 0 01-3.997 1.598c-1.499 0-2.936-.575-3.996-1.597a5.357 5.357 0 01-1.656-3.856c0-1.446.596-2.833 1.656-3.855L19.334 5.485a2.88 2.88 0 011.999-.799c.75 0 1.468.288 1.998.799.53.511.828 1.205.828 1.928 0 .723-.298 1.416-.828 1.928l-11.99 11.553"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.314 13.196l-12.979 12.52a5.759 5.759 0 01-3.997 1.598c-1.499 0-2.936-.575-3.996-1.597a5.357 5.357 0 01-1.656-3.856c0-1.446.596-2.833 1.656-3.855L19.334 5.485a2.88 2.88 0 011.999-.799c.75 0 1.468.288 1.998.799.53.511.828 1.205.828 1.928 0 .723-.298 1.416-.828 1.928l-11.99 11.553"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={0.4}
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default Clip;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const RectangleUserLine = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 14h5M19 18h5M11.512 18a3 3 0 100-6 3 3 0 000 6zM7.637 21a4 4 0 017.75 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 6H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 14h5M19 18h5M11.512 16a3 3 0 100-6 3 3 0 000 6zM7.637 21a4 4 0 017.75 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 6H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 14h5M19 18h5M11.512 16a3 3 0 100-6 3 3 0 000 6zM7.637 21a4 4 0 017.75 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 6H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 14h5M19 18h5M11.512 18a3 3 0 100-6 3 3 0 000 6zM7.637 21a4 4 0 017.75 0" />
            <path d="M27 6H5a1 1 0 00-1 1v18a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default RectangleUserLine;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Trading = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.578 14.642l-4.156 7.716M17.879 17.878l-3.758-3.757M25.578 9.642l-4.156 7.716"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.701 9.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0zM18.701 17.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0zM25.701 4.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0zM3.701 22.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0z"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.578 14.642l-4.156 7.716M17.879 17.878l-3.758-3.757M25.578 9.642l-4.156 7.716"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.5 9.289a1 1 0 011 0l1.598.922a1 1 0 01.5.866v1.846a1 1 0 01-.5.866l-1.598.922a1 1 0 01-1 0l-1.598-.922a1 1 0 01-.5-.866v-1.846a1 1 0 01.5-.866L11.5 9.29zM19.5 17.289a1 1 0 011 0l1.598.922a1 1 0 01.5.866v1.846a1 1 0 01-.5.866l-1.598.922a1 1 0 01-1 0l-1.598-.922a1 1 0 01-.5-.866v-1.846a1 1 0 01.5-.866l1.598-.922z"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M25.5 6.622a1 1 0 011 0l1.598.923a1 1 0 01.5.866v1.845a1 1 0 01-.5.866l-1.598.923a1 1 0 01-1 0l-1.598-.923a1 1 0 01-.5-.866V8.411a1 1 0 01.5-.866l1.598-.923zM5.5 19.289a1 1 0 011 0l1.598.922a1 1 0 01.5.866v1.846a1 1 0 01-.5.866L6.5 24.71a1 1 0 01-1 0l-1.598-.922a1 1 0 01-.5-.866v-1.846a1 1 0 01.5-.866L5.5 19.29z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.578 14.642l-4.156 7.716M17.879 17.878l-3.758-3.757M25.578 9.642l-4.156 7.716"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.5 9.289a1 1 0 011 0l1.598.922a1 1 0 01.5.866v1.846a1 1 0 01-.5.866l-1.598.922a1 1 0 01-1 0l-1.598-.922a1 1 0 01-.5-.866v-1.846a1 1 0 01.5-.866L11.5 9.29zM19.5 17.289a1 1 0 011 0l1.598.922a1 1 0 01.5.866v1.846a1 1 0 01-.5.866l-1.598.922a1 1 0 01-1 0l-1.598-.922a1 1 0 01-.5-.866v-1.846a1 1 0 01.5-.866l1.598-.922z"
            stroke="currentColor"
            strokeWidth={1.5}
          />
          <path
            d="M25.5 6.622a1 1 0 011 0l1.598.923a1 1 0 01.5.866v1.845a1 1 0 01-.5.866l-1.598.923a1 1 0 01-1 0l-1.598-.923a1 1 0 01-.5-.866V8.411a1 1 0 01.5-.866l1.598-.923zM5.5 19.289a1 1 0 011 0l1.598.922a1 1 0 01.5.866v1.846a1 1 0 01-.5.866L6.5 24.71a1 1 0 01-1 0l-1.598-.922a1 1 0 01-.5-.866v-1.846a1 1 0 01.5-.866L5.5 19.29z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M10.578 14.642l-4.156 7.716M17.879 17.878l-3.758-3.757M25.578 9.642l-4.156 7.716"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M10.701 9.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0zM18.701 17.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0zM25.701 4.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0zM3.701 22.75a2.598 2.598 0 012.598 0v0a2.598 2.598 0 011.3 2.25v0c0 .928-.496 1.786-1.3 2.25v0a2.598 2.598 0 01-2.598 0v0a2.598 2.598 0 01-1.3-2.25v0c0-.928.496-1.786 1.3-2.25v0z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Trading;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const NotificationYes = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.025 13a8.987 8.987 0 019.037-9c4.95.038 8.913 4.15 8.913 9.113V14c0 4.475.938 7.075 1.762 8.5a1 1 0 01-.862 1.5H6.125a1 1 0 01-.862-1.5c.825-1.425 1.762-4.025 1.762-8.5v-1zM12 24v1a4 4 0 108 0v-1"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx={22.5} cy={6.5} r={4} fill="currentColor" />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.57 14.535A8.988 8.988 0 0113.99 4.162c4.888-.783 9.477 2.617 10.299 7.51l.147.876c.741 4.413 2.096 6.822 3.146 8.09a1.001 1.001 0 01-.602 1.623L7.504 25.532a1 1 0 01-1.1-1.336c.578-1.542 1.072-4.262.33-8.675l-.165-.986z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.297 24.559l.166.986a4 4 0 107.89-1.325l-.166-.986"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx={21.5} cy={6.5} r={4} fill="currentColor" />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.57 14.535A8.988 8.988 0 0113.99 4.162c4.888-.783 9.477 2.617 10.299 7.51l.147.876c.741 4.413 2.096 6.822 3.146 8.09a1.001 1.001 0 01-.602 1.623L7.504 25.532a1 1 0 01-1.1-1.336c.578-1.542 1.072-4.262.33-8.675l-.165-.986z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.297 24.559l.166.986a4 4 0 107.89-1.325l-.166-.986"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx={21.5} cy={6.5} r={4} fill="currentColor" />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4}>
            <path
              d="M7.025 13a8.987 8.987 0 019.037-9c4.95.038 8.913 4.15 8.913 9.113V14c0 4.475.938 7.075 1.762 8.5a1 1 0 01-.862 1.5H6.125a1 1 0 01-.862-1.5c.825-1.425 1.762-4.025 1.762-8.5v-1zM12 24v1a4 4 0 108 0v-1"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx={22.5} cy={6.5} r={4} fill="currentColor" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default NotificationYes;

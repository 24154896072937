import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const DepositWithdrawals = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26 7H6a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2V9a2 2 0 00-2-2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 15h7.101a.999.999 0 01.979.8 4.001 4.001 0 007.84 0 .998.998 0 01.979-.8H28M4 11h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26 7H6a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2V9a2 2 0 00-2-2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 17h7.101a.999.999 0 01.979.8 4.001 4.001 0 007.84 0 .998.998 0 01.979-.8H28M4 13h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26 7H6a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2V9a2 2 0 00-2-2z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 17h7.101a.999.999 0 01.979.8 4.001 4.001 0 007.84 0 .998.998 0 01.979-.8H28M4 13h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M26 7H6a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2V9a2 2 0 00-2-2z" />
            <path d="M4 15h7.101a.999.999 0 01.979.8 4.001 4.001 0 007.84 0 .998.998 0 01.979-.8H28M4 11h24" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default DepositWithdrawals;

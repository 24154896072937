import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Notifications = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12 28h8M7.025 13a8.987 8.987 0 019.043-9c4.95.037 8.908 4.15 8.908 9.113V14c0 4.477.936 7.075 1.761 8.495a1 1 0 01-.86 1.505H6.123a1 1 0 01-.86-1.506c.825-1.42 1.762-4.017 1.762-8.494v-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.667 28h2.666M7.025 14a8.975 8.975 0 1117.95 0v0c0 4.477.937 7.075 1.762 8.495a1 1 0 01-.86 1.505H6.123a1 1 0 01-.86-1.505c.825-1.42 1.762-4.018 1.762-8.495h0zM22.929 2.998a13.058 13.058 0 014.74 5.264M4.331 8.262a13.058 13.058 0 014.74-5.264"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.667 28h2.666M7.025 14a8.975 8.975 0 1117.95 0v0c0 4.477.937 7.075 1.762 8.495a1 1 0 01-.86 1.505H6.123a1 1 0 01-.86-1.505c.825-1.42 1.762-4.018 1.762-8.495h0zM22.93 2.998a13.058 13.058 0 014.739 5.264M4.331 8.262a13.058 13.058 0 014.74-5.264"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 28h8M7.025 13a8.987 8.987 0 019.044-9c4.948.037 8.907 4.15 8.907 9.113V14c0 4.477.936 7.075 1.761 8.495a1 1 0 01-.86 1.505H6.123a1 1 0 01-.86-1.506c.825-1.42 1.762-4.017 1.762-8.494v-1z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Notifications;

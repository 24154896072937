export function getMaxHeight(maxHeight: unknown) {
  switch (typeof maxHeight) {
    case 'number':
      return `${maxHeight}px`;
    case 'string':
      return maxHeight;
    default:
      return 'none';
  }
}

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Perfomance = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M3 23v-2.862C3 12.95 8.775 7.025 15.95 7A13 13 0 0129 20v3a1 1 0 01-1 1H4a1 1 0 01-1-1zM16 7v4M3.438 16.637l3.874 1.038M28.563 16.637l-3.875 1.038M16 22l-7-9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M3 23v-2.862C3 12.95 8.775 7.025 15.95 7A13 13 0 0129 20v3a1 1 0 01-1 1H4a1 1 0 01-1-1zM16 7v4M3.438 16.637l3.874 1.038M28.563 16.637l-3.875 1.038M16.06 22.045l6.88-9.09"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M3 23v-2.862C3 12.95 8.775 7.025 15.95 7A13 13 0 0129 20v3a1 1 0 01-1 1H4a1 1 0 01-1-1zM16 7v4M3.438 16.637l3.874 1.038M28.563 16.637l-3.875 1.038M16.06 22.045l6.88-9.09"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 23v-2.862C3 12.95 8.775 7.025 15.95 7A13 13 0 0129 20v3a1 1 0 01-1 1H4a1 1 0 01-1-1zM16 7v4M3.438 16.637l3.874 1.038M28.563 16.637l-3.875 1.038M16 22l-7-9" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Perfomance;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SystemSettings = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 19.952a4 4 0 100-8 4 4 0 000 8z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.958 8.136c.32.283.623.586.906.906l3.415.488c.554.964.981 1.996 1.27 3.07l-2.068 2.759s.026.855 0 1.282l2.07 2.76a12.922 12.922 0 01-1.273 3.069l-3.414.488s-.586.623-.906.906l-.488 3.415c-.964.554-1.996.981-3.07 1.27l-2.759-2.069c-.427.026-.855.026-1.282 0l-2.76 2.07a12.925 12.925 0 01-3.069-1.272l-.487-3.414c-.32-.283-.623-.586-.907-.906l-3.415-.488a12.92 12.92 0 01-1.27-3.07l2.069-2.76s-.026-.854 0-1.28L3.45 12.6c.29-1.074.717-2.106 1.272-3.07l3.414-.488c.283-.32.586-.623.906-.906L9.53 4.72c.965-.554 1.996-.981 3.07-1.27l2.759 2.068c.427-.026.855-.026 1.282 0l2.76-2.07a12.92 12.92 0 013.069 1.273l.488 3.414z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default SystemSettings;

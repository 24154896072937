import { blockNonNativeProps } from '../../../helpers';
import { styled } from '../../../theme';
import { Loader } from '../../Loader';

import { TRANSIENT_PROPS } from './constants';
import { IUlProps } from './types';

export const Ul = styled('ul', {
  shouldForwardProp: blockNonNativeProps(TRANSIENT_PROPS),
})(({ loading }: IUlProps) => ({
  opacity: loading ? 0.4 : 1,
  pointerEvents: loading ? 'none' : 'auto',
  overflowY: loading ? 'hidden' : 'auto',

  '.MuiAutocomplete-option[role="option"]': {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const LoaderStyled = styled(Loader)(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginLeft: '-16px',
  marginTop: '-16px',
}));

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const XLS = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 4v7h7M14.665 21.494v6h3.5M6.165 21.494l4.5 6M10.665 21.494l-4.5 6M21.665 26.994a3.15 3.15 0 001.875.625c1.125 0 2.125-.375 2.125-1.625 0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.55 12.5l4.95 4.95 4.95-4.95M14.665 21.494v6h3.5M6.165 21.494l4.5 6M10.665 21.494l-4.5 6M21.665 26.994a3.15 3.15 0 001.875.625c1.125 0 2.125-.375 2.125-1.625 0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.55 12.5l4.95 4.95 4.95-4.95M14.665 21.494v6h3.5M6.165 21.494l4.5 6M10.665 21.494l-4.5 6M21.665 26.994a3.15 3.15 0 001.875.625c1.125 0 2.125-.375 2.125-1.625 0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 16V5a1 1 0 011-1h12l7 7v5" />
            <path d="M19 4v7h7M14.665 21.494v6h3.5M6.165 21.494l4.5 6M10.665 21.494l-4.5 6M21.665 26.994a3.15 3.15 0 001.875.625c1.125 0 2.125-.375 2.125-1.625 0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default XLS;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ArrowsClockwise = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22.025 12.462h6v-6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.225 8.225a10.988 10.988 0 0115.55 0l4.25 4.238M9.975 19.538h-6v6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.775 23.775a10.988 10.988 0 01-15.55 0l-4.25-4.237"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22.189 19.242l3.173 5.093 5.092-3.173"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.487 5.29a10.988 10.988 0 018.224 13.197l-1.35 5.848M9.811 12.758L6.638 7.665l-5.092 3.173"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.513 26.71A10.989 10.989 0 015.29 13.514l1.35-5.848"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22.189 19.242l3.173 5.093 5.092-3.173"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.487 5.29a10.988 10.988 0 018.224 13.197l-1.35 5.848M9.811 12.758L6.638 7.665l-5.092 3.173"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.513 26.71A10.989 10.989 0 015.29 13.514l1.35-5.848"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22.025 12.462h6v-6" />
            <path d="M8.225 8.225a10.988 10.988 0 0115.55 0l4.25 4.238M9.975 19.538h-6v6" />
            <path d="M23.775 23.775a10.988 10.988 0 01-15.55 0l-4.25-4.237" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ArrowsClockwise;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const App = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 18.5l-8.875 8.875a2.125 2.125 0 01-3 0 2.125 2.125 0 010-3L13.5 15.5M15.294 2.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM26.294 13.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM18.207 6.207l7.585 7.585M11.207 13.207l7.585 7.585"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 18.5l-8.875 8.875a2.125 2.125 0 01-3 0 2.125 2.125 0 010-3L13.5 15.5M15.294 2.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM26.294 13.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM18.207 6.207l7.585 7.585M11.207 13.207l7.585 7.585"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 18.5l-8.875 8.875a2.125 2.125 0 01-3 0 2.125 2.125 0 010-3L13.5 15.5M15.294 2.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM26.294 13.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM18.207 6.207l7.585 7.585M11.207 13.207l7.585 7.585"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16.5 18.5l-8.875 8.875a2.125 2.125 0 01-3 0 2.125 2.125 0 010-3L13.5 15.5M15.294 2.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM26.294 13.292l-8 8a1 1 0 000 1.414l2 2a1 1 0 001.415 0l8-8a1 1 0 000-1.414l-2-2a1 1 0 00-1.415 0zM18.207 6.207l7.585 7.585M11.207 13.207l7.585 7.585" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default App;

import { Cell } from 'react-table';

import { formatters } from 'Components/Table/constants';
import type { THorizontalAlign } from 'types';

import { getStyles } from './getStyles';
import { hasColumnStyleAndFormattingAttrs } from './hasColumnStyleAndFormattingAttrs';

export const getCellPropsWithStyles =
  <Data extends object>(params: Record<string, unknown>[]) =>
  (props: Record<string, unknown>, { cell }: { cell?: Cell<Data> }) =>
    [
      props,
      getStyles(
        ...(hasColumnStyleAndFormattingAttrs(cell?.column)
          ? ([
              cell?.column.align,
              cell?.column.className,
              cell?.column.formatting,
            ] as [THorizontalAlign, string, keyof typeof formatters])
          : ([] as never)),
      ),
      ...params,
    ];

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Doc = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.25 27a3 3 0 000-6H5.5v6h1.75zM16.25 27c1.519 0 2.75-1.343 2.75-3s-1.231-3-2.75-3-2.75 1.343-2.75 3 1.231 3 2.75 3zM26.5 26.313a2.625 2.625 0 01-1.75.687C23.225 27 22 25.663 22 24s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 4v7h7"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.25 27a3 3 0 000-6H5.5v6h1.75zM16.25 27c1.519 0 2.75-1.343 2.75-3s-1.231-3-2.75-3-2.75 1.343-2.75 3 1.231 3 2.75 3zM26.5 26.313a2.625 2.625 0 01-1.75.687C23.225 27 22 25.663 22 24s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.55 12.5l4.95 4.95 4.95-4.95"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.25 27a3 3 0 000-6H5.5v6h1.75zM16.25 27c1.519 0 2.75-1.343 2.75-3s-1.231-3-2.75-3-2.75 1.343-2.75 3 1.231 3 2.75 3zM26.5 26.313a2.625 2.625 0 01-1.75.687C23.225 27 22 25.663 22 24s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.55 12.5l4.95 4.95 4.95-4.95"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M7.25 27a3 3 0 000-6H5.5v6h1.75zM16.25 27c1.519 0 2.75-1.343 2.75-3s-1.231-3-2.75-3-2.75 1.343-2.75 3 1.231 3 2.75 3zM26.5 26.313a2.625 2.625 0 01-1.75.687C23.225 27 22 25.663 22 24s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M6 16V5a1 1 0 011-1h12l7 7v5" />
            <path d="M19 4v7h7" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Doc;

import { useEffect, useRef, useState } from 'react';

import type { ITableVirtualized, TAdaptiveTableHeightState } from '../../types';

export const useAdaptiveTableHeight = (
  isPinnedHeader: boolean | undefined,
  paginationHeight: number,
  virtualized?: ITableVirtualized,
) => {
  const refTableContent = useRef<HTMLDivElement>(null);
  const [heightTable, setHeightTable] =
    useState<TAdaptiveTableHeightState>(null);
  const calculateHeightTable = (yDistance: number, outerHeight: number) =>
    outerHeight - yDistance - paginationHeight;

  const currentElementContent = refTableContent?.current;
  const yDistance = Math.abs(
    currentElementContent?.getBoundingClientRect()?.y || 0,
  );

  useEffect(() => {
    if (isPinnedHeader && currentElementContent && virtualized) {
      setHeightTable({
        maxHeight: calculateHeightTable(yDistance, window.outerHeight),
      });
    } else {
      setHeightTable({
        maxHeight: 'unset',
      });
    }
  }, [currentElementContent]);

  return { refTableContent, heightTable };
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const OrderManagement = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22 25V7a2 2 0 00-2-2h-8a2 2 0 00-2 2v18a2 2 0 002 2h8a2 2 0 002-2zM26 11v10M30 13v6M6 11v10M2 13v6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22 25V7a2 2 0 00-2-2h-8a2 2 0 00-2 2v18a2 2 0 002 2h8a2 2 0 002-2zM26 17v10M30 21v6M6 17v10M2 21v6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22 25V7a2 2 0 00-2-2h-8a2 2 0 00-2 2v18a2 2 0 002 2h8a2 2 0 002-2zM26 17v10M30 21v6M6 17v10M2 21v6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 25V7a2 2 0 00-2-2h-8a2 2 0 00-2 2v18a2 2 0 002 2h8a2 2 0 002-2zM26 11v10M30 13v6M6 11v10M2 13v6" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default OrderManagement;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Link = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.287 8.925l2.475-2.475a5.512 5.512 0 017.788 7.788l-3.538 3.525a5.487 5.487 0 01-7.774 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.712 23.075l-2.474 2.475a5.513 5.513 0 01-7.788-7.787l3.538-3.525a5.488 5.488 0 017.774 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.352 9.561l2.252-2.252a5.017 5.017 0 017.087 7.087l-3.22 3.208a4.994 4.994 0 01-7.075 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.648 22.439l-2.252 2.252a5.017 5.017 0 01-7.087-7.087l3.22-3.208a4.994 4.994 0 017.075 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.352 9.561l2.252-2.252a5.017 5.017 0 017.087 7.087l-3.22 3.208a4.994 4.994 0 01-7.075 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.648 22.439l-2.252 2.252a5.017 5.017 0 01-7.087-7.087l3.22-3.208a4.994 4.994 0 017.075 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M15.287 8.925l2.475-2.475a5.512 5.512 0 017.788 7.788l-3.538 3.525a5.487 5.487 0 01-7.774 0" />
            <path d="M16.712 23.075l-2.474 2.475a5.513 5.513 0 01-7.788-7.787l3.538-3.525a5.488 5.488 0 017.774 0" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Link;

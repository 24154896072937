import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Counterparties = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M22.5 5H26a1 1 0 011 1v3.5M22.5 27H26a1 1 0 001-1v-3.5M9.5 27H6a1 1 0 01-1-1v-3.5M9.5 5H6a1 1 0 00-1 1v3.5M16 18a4 4 0 100-8 4 4 0 000 8zM9.365 22a7.504 7.504 0 0113.27 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 5h7a1 1 0 011 1v3.5M13 27h13a1 1 0 001-1V13M10 27H6a1 1 0 01-1-1V11M16 5H6a1 1 0 00-1 1v2M16 18a4 4 0 100-8 4 4 0 000 8zM9.365 22a7.504 7.504 0 0113.27 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19 5h7a1 1 0 011 1v3.5M13 27h13a1 1 0 001-1V13M10 27H6a1 1 0 01-1-1V11M16 5H6a1 1 0 00-1 1v2M16 18a4 4 0 100-8 4 4 0 000 8zM9.365 22a7.504 7.504 0 0113.27 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22.5 5H26a1 1 0 011 1v3.5M22.5 27H26a1 1 0 001-1v-3.5M9.5 27H6a1 1 0 01-1-1v-3.5M9.5 5H6a1 1 0 00-1 1v3.5M16 18a4 4 0 100-8 4 4 0 000 8zM9.365 22a7.503 7.503 0 0113.27 0" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Counterparties;

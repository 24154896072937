import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Portfolio = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.62 8.39h-22a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1v-16a1 1 0 00-1-1zM21.62 8.39v-2a2 2 0 00-2-2h-6a2 2 0 00-2 2v2"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.62 15.177a23.914 23.914 0 01-12 3.212 23.788 23.788 0 01-12-3.212M15.12 14.39h3"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.62 8.39h-22a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1v-16a1 1 0 00-1-1zM21.62 8.39v-2a2 2 0 00-2-2h-6a2 2 0 00-2 2v2"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.62 16.51a23.914 23.914 0 01-12 3.213 23.788 23.788 0 01-12-3.213M16.62 12.89v3"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.62 8.39h-22a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1v-16a1 1 0 00-1-1zM21.62 8.39v-2a2 2 0 00-2-2h-6a2 2 0 00-2 2v2"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.62 16.51a23.914 23.914 0 01-12 3.213 23.788 23.788 0 01-12-3.213M16.62 12.89v3"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M27.62 8.39h-22a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1v-16a1 1 0 00-1-1zM21.62 8.39v-2a2 2 0 00-2-2h-6a2 2 0 00-2 2v2" />
            <path d="M28.62 15.177a23.913 23.913 0 01-12 3.212 23.789 23.789 0 01-12-3.212M15.12 14.39h3" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Portfolio;

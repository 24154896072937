import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Settings = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.534 20.524a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.712 21.785a12.393 12.393 0 01-1.258-3.036l2.097-2.625a10.199 10.199 0 010-1.205l-2.096-2.625A12.43 12.43 0 014.71 9.256l3.339-.375c.267-.3.55-.584.851-.85l.375-3.338c.951-.55 1.971-.97 3.033-1.25l2.625 2.098c.401-.023.803-.023 1.205 0l2.625-2.096A12.43 12.43 0 0121.8 4.701l.375 3.34c.3.266.585.55.851.85l3.338.375a12.39 12.39 0 011.259 3.037l-2.098 2.625c.024.4.024.803 0 1.205l2.096 2.625c-.28 1.063-.7 2.084-1.25 3.037l-3.338.375c-.267.3-.551.585-.852.851l-.375 3.338c-.951.552-1.972.976-3.036 1.259l-2.625-2.098a10.22 10.22 0 01-1.205 0l-2.625 2.096c-1.063-.28-2.085-.7-3.037-1.25l-.375-3.338c-.3-.267-.585-.551-.852-.852l-3.34-.39z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.534 20.524a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.712 21.785a12.393 12.393 0 01-1.258-3.036l2.097-2.625a10.199 10.199 0 010-1.205l-2.096-2.625A12.43 12.43 0 014.71 9.256l3.339-.375c.267-.3.55-.584.851-.85l.375-3.338c.951-.55 1.971-.97 3.033-1.25l2.625 2.098c.401-.023.803-.023 1.205 0l2.625-2.096A12.43 12.43 0 0121.8 4.701l.375 3.34c.3.266.585.55.851.85l3.338.375a12.39 12.39 0 011.259 3.037l-2.098 2.625c.024.4.024.803 0 1.205l2.096 2.625c-.28 1.063-.7 2.084-1.25 3.037l-3.338.375c-.267.3-.551.585-.852.851l-.375 3.338c-.951.552-1.972.976-3.036 1.259l-2.625-2.098a10.22 10.22 0 01-1.205 0l-2.625 2.096c-1.063-.28-2.085-.7-3.037-1.25l-.375-3.338c-.3-.267-.585-.551-.852-.852l-3.34-.39z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.534 20.524a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.712 21.785a12.393 12.393 0 01-1.258-3.036l2.097-2.625a10.199 10.199 0 010-1.205l-2.096-2.625A12.43 12.43 0 014.71 9.256l3.339-.375c.267-.3.55-.584.851-.85l.375-3.338c.951-.55 1.971-.97 3.033-1.25l2.625 2.098c.401-.023.803-.023 1.205 0l2.625-2.096A12.43 12.43 0 0121.8 4.701l.375 3.34c.3.266.585.55.851.85l3.338.375a12.39 12.39 0 011.259 3.037l-2.098 2.625c.024.4.024.803 0 1.205l2.096 2.625c-.28 1.063-.7 2.084-1.25 3.037l-3.338.375c-.267.3-.551.585-.852.851l-.375 3.338c-.951.552-1.972.976-3.036 1.259l-2.625-2.098a10.22 10.22 0 01-1.205 0l-2.625 2.096c-1.063-.28-2.085-.7-3.037-1.25l-.375-3.338c-.3-.267-.585-.551-.852-.852l-3.34-.39z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M15.534 20.524a5 5 0 100-10 5 5 0 000 10z" />
            <path d="M4.712 21.785a12.393 12.393 0 01-1.258-3.036l2.097-2.625a10.199 10.199 0 010-1.205l-2.096-2.625A12.43 12.43 0 014.71 9.256l3.339-.375c.267-.3.55-.584.851-.85l.375-3.338c.951-.55 1.971-.97 3.033-1.25l2.625 2.098c.401-.023.803-.023 1.205 0l2.625-2.096A12.43 12.43 0 0121.8 4.701l.375 3.34c.3.266.585.55.851.85l3.338.375a12.39 12.39 0 011.259 3.037l-2.098 2.625c.024.4.024.803 0 1.205l2.096 2.625c-.28 1.063-.7 2.084-1.25 3.037l-3.338.375c-.267.3-.551.585-.852.851l-.375 3.338c-.951.552-1.972.976-3.036 1.259l-2.625-2.098a10.22 10.22 0 01-1.205 0l-2.625 2.096c-1.063-.28-2.085-.7-3.037-1.25l-.375-3.338c-.3-.267-.585-.551-.852-.852l-3.34-.39z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Settings;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Equals = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            d="M.75 11.25h30.5M.75 21.916h30.5M.75 21.916h30.5"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            d="M.75 11.25h30.5M.75 21.916h30.5M.75 21.916h30.5"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            d="M.75 11.25h30.5M.75 21.916h30.5M.75 21.916h30.5"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path d="M.75 11.25h30.5M.75 21.916h30.5M.75 21.916h30.5" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Equals;

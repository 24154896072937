import type { IRowExpand } from '../types';

export function closeSubRows<Data extends object>(
  subRows: IRowExpand<Data>['subRows'],
) {
  subRows.forEach((subRow) => {
    if (subRow.canExpand && subRow.isExpanded) {
      subRow.toggleRowExpanded();
    }

    if (subRow.subRows) {
      closeSubRows(subRow.subRows);
    }
  });
}

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Connect = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.5 19.5l-3.875 3.875a3 3 0 01-4.25 0l-2.75-2.75a3 3 0 010-4.25L12.5 12.5M10 22l-4 4M26 6l-4 4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.5 19.5l3.875-3.875a2.999 2.999 0 000-4.25l-2.75-2.75a3 3 0 00-4.25 0L12.5 12.5M11.5 11.5l9 9M11.5 11.5l9 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 22.5l-3.875 3.875a3 3 0 01-4.25 0l-2.75-2.75a3 3 0 010-4.25L9.5 15.5M7 25l-4 4M29 3l-4 4M18 18l-3 3M14 14l-3 3M22.5 16.5l3.875-3.875a2.999 2.999 0 000-4.25l-2.75-2.75a3 3 0 00-4.25 0L15.5 9.5M14.5 8.5l9 9M8.5 14.5l9 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.5 22.5l-3.875 3.875a3 3 0 01-4.25 0l-2.75-2.75a3 3 0 010-4.25L9.5 15.5M7 25l-4 4M29 3l-4 4M18 18l-3 3M14 14l-3 3M22.5 16.5l3.875-3.875a2.999 2.999 0 000-4.25l-2.75-2.75a3 3 0 00-4.25 0L15.5 9.5M14.5 8.5l9 9M8.5 14.5l9 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19.5 19.5l-3.875 3.875a3 3 0 01-4.25 0l-2.75-2.75a3 3 0 010-4.25L12.5 12.5M10 22l-4 4M26 6l-4 4" />
            <path d="M19.5 19.5l3.875-3.875a2.999 2.999 0 000-4.25l-2.75-2.75a3 3 0 00-4.25 0L12.5 12.5M11.5 11.5l9 9M11.5 11.5l9 9" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Connect;

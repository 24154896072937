import { Tab, Tabs } from '@mui/material';

import { styled } from '../../theme';

export const StyledTabs = styled(Tabs)(({ theme: { color } }) => ({
  borderBottom: `1px solid ${color.dropdown.border}`,
  minHeight: '40px',
  '& .MuiTabs-indicator': {
    backgroundColor: color.typo.action,
  },

  '& .MuiTabs-scrollableX .MuiTab-root:last-child': {
    marginRight: '24px',
  },
}));

export const StyledTab = styled(Tab)(({ theme: { font, color } }) => ({
  color: color.typo.action,
  opacity: 1,
  textTransform: 'none',
  minWidth: 'fit-content',
  minHeight: 'auto',
  padding: 0,
  marginRight: '24px',
  fontFamily: font?.main,
  fontSize: '15px',
  lineHeight: '24px',
  '&:last-child': {
    marginRight: 0,
  },
  '&:hover': {
    color: color.typo.promo,
  },
  '&.Mui-selected': {
    color: color.typo.primary,
  },
  '&.Mui-disabled': {
    color: color.typo.action,
    opacity: '.4',
  },
}));

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Tool = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.6 8.4a1.333 1.333 0 000 1.867l2.133 2.133a1.333 1.333 0 001.867 0l5.027-5.027A8 8 0 0118.04 17.96l-9.213 9.213a2.828 2.828 0 11-4-4l9.213-9.213A8 8 0 0124.627 3.373l-5.014 5.013-.013.014z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.6 8.4a1.333 1.333 0 000 1.867l2.133 2.133a1.333 1.333 0 001.867 0l5.027-5.027A8 8 0 0118.04 17.96l-9.213 9.213a2.828 2.828 0 11-4-4l9.213-9.213A8 8 0 0124.627 3.373l-5.014 5.013-.013.014z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={0.4}
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default Tool;

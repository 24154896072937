import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Julia = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.838 20.438l-2.9 7.975a1 1 0 01-1.875 0l-2.9-7.975a1.013 1.013 0 00-.6-.6l-7.975-2.9a1 1 0 010-1.875l7.975-2.9a1.013 1.013 0 00.6-.6l2.9-7.975a1 1 0 011.875 0l2.9 7.975a1.011 1.011 0 00.6.6l7.975 2.9a1 1 0 010 1.875l-7.975 2.9a1.011 1.011 0 00-.6.6v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.838 20.438l-2.9 7.975a1 1 0 01-1.875 0l-2.9-7.975a1.013 1.013 0 00-.6-.6l-7.975-2.9a1 1 0 010-1.875l7.975-2.9a1.013 1.013 0 00.6-.6l2.9-7.975a1 1 0 011.875 0l2.9 7.975a1.011 1.011 0 00.6.6l7.975 2.9a1 1 0 010 1.875l-7.975 2.9a1.011 1.011 0 00-.6.6v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity={0.4}
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default Julia;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Day = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 23.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM16 4.5V2M7.863 7.863L6.1 6.1M4.5 16H2M7.863 24.137L6.1 25.9M16 27.5V30M24.137 24.137L25.9 25.9M27.5 16H30M24.137 7.863L25.9 6.1"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.697 21.303a7.5 7.5 0 1010.606-10.607 7.5 7.5 0 00-10.606 10.607zM24.132 7.868l1.767-1.767M16 4.492V2M7.868 7.868L6.1 6.101M4.492 16H2M7.868 24.132L6.101 25.9M16 27.509V30M24.132 24.132l1.767 1.768M27.508 16h2.493"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.697 21.303a7.5 7.5 0 1010.606-10.607 7.5 7.5 0 00-10.606 10.607zM24.132 7.868l1.767-1.767M16 4.492V2M7.868 7.868L6.1 6.101M4.492 16H2M7.868 24.132L6.101 25.9M16 27.509V30M24.132 24.132l1.767 1.768M27.508 16h2.493"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 23.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM16 4.5V2M7.863 7.863L6.1 6.1M4.5 16H2M7.863 24.137L6.1 25.9M16 27.5V30M24.137 24.137L25.9 25.9M27.5 16H30M24.137 7.863L25.9 6.1" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Day;

import * as Icons from '../../../Icons';
import { KitTheme } from '../../../theme';

import { defaultIconColors } from './const';
import { TIconColor } from './types';

export function getColor(
  theme: KitTheme,
  iconColor: TIconColor,
  iconName?: keyof typeof Icons,
): string {
  return theme?.color.icon[
    iconColor || (iconName && defaultIconColors[iconName]) || 'primary'
  ];
}

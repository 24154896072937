import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Reconciliations = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28.106 8.959l-2.113 2.732-6.928-5.165 2.135-2.76a1 1 0 011.389-.19l5.324 3.97a1 1 0 01.193 1.413v0zM6.114 21.117l-3.454-.051a.998.998 0 01-.985-1.032l.215-6.638a1 1 0 011.014-.968l3.49.052-.28 8.637zM25.993 11.691l-.787 2.976-2.148 6.14a1 1 0 01-.741.65l-7.313 1.511a1 1 0 01-.416-.002l-8.474-1.849"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.206 14.667l-6.69-1.217-.922 1.776a4 4 0 01-4.324 2.082l-.831-.163a1 1 0 01-.75-1.312l2.284-6.53a1 1 0 01.51-.57l4.582-2.207M6.458 12.448l4.968-4.468a1 1 0 01.645-.256l4.742-.114M18.55 26.197l-3.803.787a1 1 0 01-.466-.015l-3.603-.985"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M30.09 15.223L27 16.768l-4-7.66 3.121-1.561a1 1 0 011.334.432l3.074 5.887a1 1 0 01-.44 1.357v0zM5 16.634l-3.09-1.545a1 1 0 01-.439-1.357l3.074-5.887a1 1 0 011.334-.432L9 8.973l-4 7.661zM27 16.768l-2 2.34-4.6 4.6a1 1 0 01-.95.262l-7.244-1.811a1 1 0 01-.373-.182L5 16.634"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25 19.107l-5.5-4-1.6 1.2a4 4 0 01-4.8 0l-.678-.508a1 1 0 01-.107-1.507L17.207 9.4a1 1 0 01.707-.293H23"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.072 8.974l6.415-1.87a1 1 0 01.692.048L20.5 9.108M14 26.608l-3.767-.942a1 1 0 01-.414-.216L7 23"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M30.09 15.223L27 16.768l-4-7.66 3.121-1.561a1 1 0 011.334.432l3.074 5.887a1 1 0 01-.44 1.357v0zM5 16.634l-3.09-1.545a1 1 0 01-.439-1.357l3.074-5.887a1 1 0 011.334-.432L9 8.973l-4 7.661zM27 16.768l-2 2.34-4.6 4.6a1 1 0 01-.95.262l-7.244-1.811a1 1 0 01-.373-.182L5 16.634"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25 19.107l-5.5-4-1.6 1.2a4 4 0 01-4.8 0l-.678-.508a1 1 0 01-.107-1.507L17.207 9.4a1 1 0 01.707-.293H23"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.072 8.974l6.415-1.87a1 1 0 01.692.048L20.5 9.108M14 26.608l-3.767-.942a1 1 0 01-.414-.216L7 23"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M28.106 8.959l-2.113 2.732-6.928-5.165 2.135-2.76a1 1 0 011.389-.19l5.324 3.97a1 1 0 01.193 1.413v0zM6.114 21.117l-3.454-.051a.998.998 0 01-.985-1.032l.215-6.638a1 1 0 011.014-.968l3.49.052-.28 8.637zM25.993 11.691l-.787 2.976-2.148 6.14a1 1 0 01-.741.65l-7.313 1.511a1 1 0 01-.416-.002l-8.474-1.849" />
            <path d="M25.206 14.667l-6.69-1.217-.922 1.776a4 4 0 01-4.324 2.082l-.831-.163a1 1 0 01-.75-1.312l2.284-6.53a1 1 0 01.51-.57l4.582-2.207M6.458 12.448l4.968-4.468a1 1 0 01.645-.256l4.742-.114M18.55 26.197l-3.803.787a1 1 0 01-.466-.015l-3.603-.985" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Reconciliations;

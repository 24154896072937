import { createContext, useContext, useMemo } from 'react';

import { DEFAULT_LOCALE } from '../consts';

import {
  TTranslationContext,
  TTranslationProviderProps,
  TTranslator,
} from './TranslationContext.types';

const defaultTranslator: TTranslator = (key) => key;

const TranslationContext = createContext<TTranslationContext>({
  translator: undefined,
  locale: DEFAULT_LOCALE,
});

/**
 * Provides translator function to child components
 *
 * @param children   child components that may use the translator
 * @param translator key-to-translation mapping function
 * @param locale     current locale
 */
export const TranslationProvider = ({
  children,
  translator,
  locale,
}: TTranslationProviderProps) => {
  const providerValue = useMemo(
    () => ({ translator, locale }),
    [translator, locale],
  );

  return (
    <TranslationContext.Provider value={providerValue}>
      {children}
    </TranslationContext.Provider>
  );
};

/**
 * Returns current locale and
 * translator function from {@link TranslationContext}
 *
 * @param fallbackTranslator translator to use if context is not defined
 */
export const useTranslator: (fallbackTranslator?: TTranslator) => {
  t: TTranslator;
  locale: string;
} = (fallbackTranslator) => {
  const { translator, locale } = useContext(TranslationContext);

  const safeTranslator = translator || fallbackTranslator || defaultTranslator;
  const safeLocale = locale || DEFAULT_LOCALE;

  return { t: safeTranslator, locale: safeLocale };
};

import classNames from 'classnames';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { DefaultThemeProvider } from '../../theme';
import { Tooltip } from '../Tooltip';

import { Root, Title } from './Panel.styled';
import { TITLE_DEFAULT_SIZE, TITLE_SIZES } from './constants';
import { IPanelProps, TTags } from './types';

export const Panel = ({
  action,
  children,
  className = '',
  disableContentPaddings = false,
  disableBodyPaddings = false,
  maxHeight = null,
  size = 2,
  title,
}: PropsWithChildren<IPanelProps>) => {
  const ref = useRef<HTMLHeadingElement | null>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsOverflown(element.scrollWidth > element.clientWidth);
    }
  }, [ref, title]);

  const Tag: TTags = TITLE_SIZES.includes(size)
    ? `h${size}`
    : `h${TITLE_DEFAULT_SIZE}`;
  const titleTag = (
    <Tag className="PanelHeading" ref={ref}>
      {title}
    </Tag>
  );

  return (
    <DefaultThemeProvider>
      <Root
        className={classNames(['Panel', className])}
        disableContentPaddings={disableContentPaddings}
        disableBodyPaddings={disableBodyPaddings}
        maxHeight={maxHeight}
      >
        {title && (
          <Title
            className="PanelTitle"
            disableBodyPaddings={disableBodyPaddings}
          >
            {isOverflown ? (
              <Tooltip title={title}>{titleTag}</Tooltip>
            ) : (
              titleTag
            )}
            {action}
          </Title>
        )}
        {children}
      </Root>
    </DefaultThemeProvider>
  );
};

import { ISelectOption } from '../../../Inputs/Select';

export const prepareCheckboxValue = (value: unknown): boolean => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }

  return false;
};

export function prepareSelectOptions(options: (string | ISelectOption)[]) {
  return options.map((option) => {
    if (typeof option === 'string') {
      return { value: option, label: option };
    }

    return option;
  });
}

export const prepareValueForMultiSelect = (
  value: unknown,
  options: ISelectOption[],
): (ISelectOption | string)[] => {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  if (typeof value === 'string') {
    return value.split(',').reduce((acc: (ISelectOption | string)[], item) => {
      const option = options.find(
        ({ value: optionValue }) => optionValue === item.trim(),
      );
      return option ? [...acc, option] : acc;
    }, []);
  }
  return [];
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Language = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 16c0 4.683 1.666 8.865 4.285 11.686a.972.972 0 001.43 0C19.334 24.866 21 20.683 21 16c0-4.682-1.666-8.865-4.285-11.686a.972.972 0 00-1.43 0C12.666 7.135 11 11.318 11 16zM4 16h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 26c6.627 0 12-5.373 12-12S22.627 2 16 2 4 7.373 4 14s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 14c0 4.683 1.666 8.865 4.285 11.686a.972.972 0 001.43 0C19.334 22.866 21 18.683 21 14c0-4.682-1.666-8.865-4.285-11.686a.972.972 0 00-1.43 0C12.666 5.135 11 9.318 11 14zM4 14h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 26c6.627 0 12-5.373 12-12S22.627 2 16 2 4 7.373 4 14s5.373 12 12 12z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 14c0 4.683 1.666 8.865 4.285 11.686a.972.972 0 001.43 0C19.334 22.866 21 18.683 21 14c0-4.682-1.666-8.865-4.285-11.686a.972.972 0 00-1.43 0C12.666 5.135 11 9.318 11 14zM4 14h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z" />
            <path d="M11 16c0 4.683 1.666 8.865 4.285 11.686a.972.972 0 001.43 0C19.334 24.866 21 20.683 21 16c0-4.682-1.666-8.865-4.285-11.686a.972.972 0 00-1.43 0C12.666 7.135 11 11.318 11 16zM4 16h24" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Language;

import { isBoolean, isNaN, isNumber, isString } from 'lodash';

export const formatNumber = (
  value: unknown,
  options?: Intl.NumberFormatOptions,
  getBrowserLocale = () => 'en',
) => {
  const currentLocale = getBrowserLocale();
  const isValidNumber = isNumber(value) && !isNaN(value);
  const isValidNumericString = isString(value) && !isNaN(Number(value));

  if (isValidNumber) {
    return value.toLocaleString(currentLocale, options || {});
  }
  if (isValidNumericString) {
    return Number(value).toLocaleString(currentLocale, options || {});
  }
  if (isNaN(value) || (!isBoolean(value) && !value)) {
    return '0';
  }

  return isString(value) ? value : JSON.stringify(value);
};

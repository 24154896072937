import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Marketing = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28.28 18.21a1 1 0 00.72-.96v-4.5a1 1 0 00-.72-.96l-22-6.75A1 1 0 005 6v18a1 1 0 001.28.96l22-6.75z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 19.523V24a1 1 0 01-1 1h-4a1 1 0 01-1-1V8.637"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.065 21.02a1 1 0 00.944-.74l1.165-4.347a1 1 0 00-.447-1.114L9.223 2.605a1 1 0 00-1.485.596L3.08 20.588a1 1 0 00.987 1.258l22.998-.826z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.59 21.18l-1.158 4.326a1 1 0 01-1.225.707l-3.863-1.036a1 1 0 01-.708-1.224l3.977-14.84"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.065 21.02a1 1 0 00.944-.74l1.165-4.347a1 1 0 00-.447-1.114L9.223 2.605a1 1 0 00-1.485.596L3.08 20.588a1 1 0 00.987 1.258l22.998-.826z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.59 21.18l-1.158 4.326a1 1 0 01-1.225.707l-3.863-1.036a1 1 0 01-.708-1.224l3.977-14.84"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M28.28 18.21a1 1 0 00.72-.96v-4.5a1 1 0 00-.72-.96l-22-6.75A1 1 0 005 6v18a1 1 0 001.28.96l22-6.75z" />
            <path d="M24 19.523V24a1 1 0 01-1 1h-4a1 1 0 01-1-1V8.637" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Marketing;

import { IArrowIconProps } from './types';

export const ArrowIcon = ({
  direction = 'left',
  size = 'md',
  color,
  ...rest
}: IArrowIconProps) => {
  let path;
  let width = size === 'md' ? 10 : 5;
  let height = size === 'md' ? 18 : 8;

  switch (direction) {
    case 'down':
      path = size === 'md' ? 'M 1 1.5 L 8.5 9 L 16 1.5' : 'M7 1L4 4L1 1';
      [width, height] = [height, width];
      break;
    case 'up':
      path = size === 'md' ? 'M 1 9 L 8 1.5 L 16 9' : 'M1 4L4 1L7 4';
      [width, height] = [height, width];
      break;
    case 'right':
      path = size === 'md' ? 'M1 1.5L8.5 9L0.999997 16.5' : 'M1 1L4 4L1 7';
      break;
    case 'left':
    default:
      path = size === 'md' ? 'M9 16.5L1.5 9L9 1.5' : 'M4 7L1 4L4 1';
      break;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d={path}
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

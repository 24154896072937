import { styled } from '../../../theme';

export const HeaderTitle = styled('div')(({ theme }) => ({
  '.HeaderTitle': {
    font: `400 24px/1 ${theme.font.header}`,
    color: theme.color.typo.primary,
    margin: 0,
    padding: 0,
    [theme?.breakpoints?.up('md')]: {
      fontSize: '32px',
    },
  },
}));

export const Description = styled('p')(({ theme }) => ({
  margin: '8px 0 0',
  fontSize: theme.size.text.md,
  color: theme.color.typo.promo,
}));

export const Header = styled('header')(({ theme }) => ({
  padding: '24px 24px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.color.bg.primary,
}));

export const HeaderActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  '& > * + *': {
    marginLeft: 12,
  },
}));

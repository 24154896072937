import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const UserCircleArrow = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 20a5 5 0 100-10 5 5 0 000 10zM7.975 24.925a9 9 0 0116.05 0M25 16l3 3 3-3M1 16l3-3 3 3"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 19v-3A12 12 0 005.913 9.487M4 13v3a12 12 0 0022.087 6.512"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 20a5 5 0 100-10 5 5 0 000 10zM7.975 24.925a9 9 0 0116.05 0M25 16l3 3 3-3M1 16l3-3 3 3" />
            <path d="M28 19v-3A12 12 0 005.913 9.487M4 13v3a12 12 0 0022.087 6.512" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default UserCircleArrow;

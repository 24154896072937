import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const UserManagement = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 22.5a5 5 0 100-10 5 5 0 000 10zM24.5 14.5a7.488 7.488 0 016 3M1.5 17.5a7.489 7.489 0 016-3M8.804 27a8.003 8.003 0 0114.392 0M7.5 14.5a4 4 0 113.93-4.75M20.57 9.749a4 4 0 113.93 4.75"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 21.167a5 5 0 100-10 5 5 0 000 10zM21.833 15.834a7.488 7.488 0 016 3M4.166 18.834a7.488 7.488 0 016-3M8.804 25.667a8.003 8.003 0 0114.392 0M10.167 15.834a4 4 0 113.93-4.751M17.904 11.083a4 4 0 113.93 4.75"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 21.167a5 5 0 100-10 5 5 0 000 10zM21.833 15.834a7.488 7.488 0 016 3M4.166 18.834a7.488 7.488 0 016-3M8.804 25.667a8.003 8.003 0 0114.392 0M10.167 15.834a4 4 0 113.93-4.751M17.904 11.083a4 4 0 113.93 4.75"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 22.5a5 5 0 100-10 5 5 0 000 10zM24.5 14.5a7.488 7.488 0 016 3M1.5 17.5a7.489 7.489 0 016-3M8.804 27a8.003 8.003 0 0114.392 0M7.5 14.5a4 4 0 113.93-4.75M20.57 9.749a4 4 0 113.93 4.75" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default UserManagement;

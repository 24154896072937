import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const List = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.333 8h17.334M9.333 13.333h17.334M9.333 18.667h17.334M9.333 24h17.334M4 8h.013M4 13.333h.013M4 18.667h.013M4 24h.013"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.333 10h17.334M9.333 14h17.334M9.333 18h17.334M9.333 22h17.334M4 10h.013M4 14h.013M4 18h.013M4 22h.013"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.333 10h17.334M9.333 14h17.334M9.333 18h17.334M9.333 22h17.334M4 10h.013M4 14h.013M4 18h.013M4 22h.013"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9.333 8h17.334M9.333 13.333h17.334M9.333 18.667h17.334M9.333 24h17.334M4 8h.013M4 13.333h.013M4 18.667h.013M4 24h.013" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default List;

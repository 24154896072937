import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Dividents = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.5 13a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 26H2a1 1 0 01-1-1v-5a1 1 0 011-1h4M14 20h4l8.375-1.926a2.075 2.075 0 011.477 3.856L23 24l-8 2H6v-7l3.125-3.125A3 3 0 0111.25 15h6.25a2.5 2.5 0 010 5H14zM23 9.661a3.5 3.5 0 112-3.322"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 26H2a1 1 0 01-1-1v-5a1 1 0 011-1h4M14 20h4l8.375-1.926a2.075 2.075 0 011.477 3.856L23 24l-8 2H6v-7l3.125-3.125A3 3 0 0111.25 15h6.25a2.5 2.5 0 010 5H14zM22 12.661a3.5 3.5 0 112-3.322"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 26H2a1 1 0 01-1-1v-5a1 1 0 011-1h4M14 20h4l8.375-1.926a2.075 2.075 0 011.477 3.856L23 24l-8 2H6v-7l3.125-3.125A3 3 0 0111.25 15h6.25a2.5 2.5 0 010 5H14zM22 12.661a3.5 3.5 0 112-3.322"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M26.5 13a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM6 26H2a1 1 0 01-1-1v-5a1 1 0 011-1h4M14 20h4l8.375-1.926a2.075 2.075 0 011.477 3.856L23 24l-8 2H6v-7l3.125-3.125A3 3 0 0111.25 15h6.25a2.5 2.5 0 010 5H14zM23 9.661a3.5 3.5 0 112-3.322" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Dividents;

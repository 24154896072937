import { FC } from 'react';

import { Skeleton as SkeletonRUI } from '../../Skeleton';

import { defaultSkeletonSizes } from './const';
import { ISkeletonProps } from './types';

export const Skeleton: FC<ISkeletonProps> = ({
  width = defaultSkeletonSizes.width,
  height = defaultSkeletonSizes.height,
  borderRadius = defaultSkeletonSizes.borderRadius,
  variant = 'rectangular',
  sx,
  animation = 'wave',
  ...props
}) => {
  return (
    <SkeletonRUI
      variant={variant}
      width={width}
      height={height}
      sx={{
        borderRadius,
        ...sx,
      }}
      animation={animation}
      {...props}
    />
  );
};

import { KitTheme } from '../../../theme';

export const getBorderColor = (
  theme: KitTheme,
  error?: boolean,
  warning?: boolean,
) => {
  if (error) {
    return theme.color.input.error;
  }

  if (warning) {
    return theme.color.input.warning;
  }

  return theme.color.input.border;
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const AutoLiquidateAll = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4 26V7a1 1 0 011-1h22a1 1 0 011 1v19l-4-2-4 2-4-2-4 2-4-2-4 2zM19 12l-6 6M13 12l6 6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4 26V7a1 1 0 011-1h22a1 1 0 011 1v19l-4-2-4 2-4-2-4 2-4-2-4 2zM19 18l-6-6M19 12l-6 6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4 26V7a1 1 0 011-1h22a1 1 0 011 1v19l-4-2-4 2-4-2-4 2-4-2-4 2zM19 18l-6-6M19 12l-6 6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4 26V7a1 1 0 011-1h22a1 1 0 011 1v19l-4-2-4 2-4-2-4 2-4-2-4 2zM19 12l-6 6M13 12l6 6" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default AutoLiquidateAll;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Hat = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M1 12l15-8 15 8-15 8-15-8z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.5 30V16L16 12"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.5 13.862v6.825a.975.975 0 01-.2.588C26.462 22.4 22.913 26.5 16 26.5c-6.912 0-10.463-4.1-11.3-5.225a.975.975 0 01-.2-.588v-6.825"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M1 12l15-8 15 8-15 8-15-8z" />
            <path d="M23.5 30V16L16 12" />
            <path d="M27.5 13.862v6.825a.975.975 0 01-.2.588C26.462 22.4 22.913 26.5 16 26.5c-6.912 0-10.463-4.1-11.3-5.225a.975.975 0 01-.2-.588v-6.825" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Hat;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Account = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10 18a5 5 0 100-10 5 5 0 000 10zM19 10h12M19 16h12M22 22h9M2 24c.888-3.45 4.272-6 8-6 3.727 0 7.113 2.55 8 6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10 16a5 5 0 100-10 5 5 0 000 10zM22 10h9M22 16h9M26 22h5M2 24c.888-3.45 4.272-6 8-6 3.727 0 7.113 2.55 8 6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10 16a5 5 0 100-10 5 5 0 000 10zM22 10h9M22 16h9M26 22h5M2 24c.888-3.45 4.272-6 8-6 3.727 0 7.113 2.55 8 6"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M10 18a5 5 0 100-10 5 5 0 000 10zM19 10h12M19 16h12M22 22h9M2 24c.888-3.45 4.272-6 8-6 3.727 0 7.113 2.55 8 6" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Account;

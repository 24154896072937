import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ProfileRun = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.938 5.5a12.124 12.124 0 0112.124 0v0A12.124 12.124 0 0128.124 16v0c0 4.332-2.31 8.334-6.062 10.5v0a12.124 12.124 0 01-12.124 0v0A12.124 12.124 0 013.876 16v0c0-4.332 2.31-8.334 6.062-10.5v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          />
          <path
            d="M16 19a5 5 0 100-10 5 5 0 000 10zM7.975 24.922a9.004 9.004 0 0116.05 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15 2.577a2 2 0 012 0l10.124 5.846a2 2 0 011 1.732v11.69a2 2 0 01-1 1.732L17 29.423a2 2 0 01-2 0L4.876 23.577a2 2 0 01-1-1.732v-11.69a2 2 0 011-1.732L15 2.577z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          />
          <path
            d="M16 19a5 5 0 100-10 5 5 0 000 10zM7.975 24.922a9.004 9.004 0 0116.05 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15 2.577a2 2 0 012 0l10.124 5.846a2 2 0 011 1.732v11.69a2 2 0 01-1 1.732L17 29.423a2 2 0 01-2 0L4.876 23.577a2 2 0 01-1-1.732v-11.69a2 2 0 011-1.732L15 2.577z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          />
          <path
            d="M16 19a5 5 0 100-10 5 5 0 000 10zM7.975 24.922a9.004 9.004 0 0116.05 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path d="M9.938 5.5a12.124 12.124 0 0112.124 0v0A12.124 12.124 0 0128.124 16v0c0 4.332-2.31 8.334-6.062 10.5v0a12.124 12.124 0 01-12.124 0v0A12.124 12.124 0 013.876 16v0c0-4.332 2.31-8.334 6.062-10.5v0z" />
            <path
              d="M16 19a5 5 0 100-10 5 5 0 000 10zM7.975 24.922a9.004 9.004 0 0116.05 0"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ProfileRun;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Balance = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 5v22M13 27h6M7 11l18-4M3 21c0 2.212 2.5 3 4 3s4-.788 4-3L7 11 3 21zM21 17c0 2.212 2.5 3 4 3s4-.788 4-3L25 7l-4 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 5v22M13 27h6M6.944 7.274l18.113 3.452M3 18c0 2.212 2.5 3 4 3s4-.788 4-3L7 8 3 18zM21 21c0 2.212 2.5 3 4 3s4-.788 4-3l-4-10-4 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 5v22M13 27h6M6.944 7.274l18.113 3.452M3 18c0 2.212 2.5 3 4 3s4-.788 4-3L7 8 3 18zM21 21c0 2.212 2.5 3 4 3s4-.788 4-3l-4-10-4 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 5v22M13 27h6M7 11l18-4M3 21c0 2.212 2.5 3 4 3s4-.788 4-3L7 11 3 21zM21 17c0 2.212 2.5 3 4 3s4-.788 4-3L25 7l-4 10z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Balance;

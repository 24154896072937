import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const FileCsv = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M21.5 21l2.5 6 2.5-6M10 26.313A2.625 2.625 0 018.25 27c-1.525 0-2.75-1.337-2.75-3s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M14 26.5a3.15 3.15 0 001.875.625C17 27.125 18 26.75 18 25.5c0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 4v7h7"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M21.5 21l2.5 6 2.5-6M10 26.313A2.625 2.625 0 018.25 27c-1.525 0-2.75-1.337-2.75-3s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M14 26.5a3.15 3.15 0 001.875.625C17 27.125 18 26.75 18 25.5c0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.55 10.449l4.9 5 5-4.899"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M21.5 21l2.5 6 2.5-6M10 26.313A2.625 2.625 0 018.25 27c-1.525 0-2.75-1.337-2.75-3s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M14 26.5a3.15 3.15 0 001.875.625C17 27.125 18 26.75 18 25.5c0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625M6 16V5a1 1 0 011-1h12l7 7v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.55 10.449l4.9 5 5-4.899"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21.5 21l2.5 6 2.5-6M10 26.313A2.625 2.625 0 018.25 27c-1.525 0-2.75-1.337-2.75-3s1.225-3 2.75-3c.648.005 1.272.25 1.75.688M14 26.5a3.15 3.15 0 001.875.625C17 27.125 18 26.75 18 25.5c0-2-4-1.125-4-3 0-1 .75-1.625 1.875-1.625a3.15 3.15 0 011.875.625M6 16V5a1 1 0 011-1h12l7 7v5" />
            <path d="M19 4v7h7" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default FileCsv;

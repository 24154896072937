import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Clients = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 7.242A6.5 6.5 0 1115.764 20M15.764 20a10.986 10.986 0 019 4.674"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 20a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
          />
          <path
            d="M7 24.675a11.002 11.002 0 0118-.001"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.426 7.242A6.5 6.5 0 1121.19 20M21.19 20a10.986 10.986 0 019 4.674"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 20a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
          />
          <path
            d="M2 24.675a11.002 11.002 0 0118-.001"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M19.426 7.242A6.5 6.5 0 1121.19 20M21.19 20a10.987 10.987 0 019 4.674"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 20a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
          />
          <path
            d="M2 24.675a11.002 11.002 0 0118-.001"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path
              d="M14 7.242A6.5 6.5 0 1115.764 20M15.764 20a10.986 10.986 0 019 4.674"
              strokeLinejoin="round"
            />
            <path
              d="M16 20a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
              strokeMiterlimit={10}
            />
            <path
              d="M7 24.675a11.002 11.002 0 0118-.001"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Clients;

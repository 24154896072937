import { ChangeEvent, useCallback } from 'react';

import { SearchIcon } from '../../../Icons';

import { SearchInputStyled } from './SearchInput.styled';
import { ISearchInput } from './SearchInput.types';

export const SearchInput = ({
  inputProps,
  placeholder = 'Search',
  size = 'small',
  onChange,
}: ISearchInput) => {
  const handleTextInputChange = useCallback(
    ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) =>
      onChange?.(newValue),
    [onChange],
  );

  return (
    <SearchInputStyled
      iconLeft={
        <SearchIcon size={size === 'medium' ? 24 : 16} className="SearchIcon" />
      }
      onChange={handleTextInputChange}
      placeholder={placeholder}
      size={size}
      type="search"
      {...inputProps}
    />
  );
};

import { isString, isNumber, isArray, isNull, isUndefined } from 'lodash';

export const isValidFilterValue = (
  value: unknown,
): value is string | number | unknown[] | null | undefined => {
  return (
    isString(value) ||
    isNumber(value) ||
    isArray(value) ||
    isNull(value) ||
    isUndefined(value)
  );
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SplitStep = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 26V6M16 26H5c-.265 0-.52-.15-.707-.418-.188-.268-.293-.632-.293-1.01V7.428c0-.38.105-.743.293-1.01C4.48 6.15 4.735 6 5 6h11M16 6h11c.265 0 .52.15.707.418.188.268.293.632.293 1.01v17.143c0 .38-.105.743-.293 1.01-.187.268-.442.419-.707.419H16"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 26V6M11 26H2c-.265 0-.52-.15-.707-.418-.188-.268-.293-.632-.293-1.01V7.428c0-.38.105-.743.293-1.01C1.48 6.15 1.735 6 2 6h9M21 6h9c.265 0 .52.15.707.418.188.268.293.632.293 1.01v17.143c0 .38-.105.743-.293 1.01-.187.268-.442.419-.707.419h-9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 26V6M11 26H2c-.265 0-.52-.15-.707-.418-.188-.268-.293-.632-.293-1.01V7.428c0-.38.105-.743.293-1.01C1.48 6.15 1.735 6 2 6h9M21 6h9c.265 0 .52.15.707.418.188.268.293.632.293 1.01v17.143c0 .38-.105.743-.293 1.01-.187.268-.442.419-.707.419h-9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 26V6M16 26H5c-.265 0-.52-.15-.707-.418-.188-.268-.293-.632-.293-1.01V7.428c0-.38.105-.743.293-1.01C4.48 6.15 4.735 6 5 6h11M16 6h11c.265 0 .52.15.707.418.188.268.293.632.293 1.01v17.143c0 .38-.105.743-.293 1.01-.187.268-.442.419-.707.419H16" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default SplitStep;

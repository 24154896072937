import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Services = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 18h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zM13 6H7a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V7a1 1 0 00-1-1zM25 6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V7a1 1 0 00-1-1zM13 18H7a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8.222 20.95l4.242-4.243a1 1 0 000-1.414L8.223 11.05a1 1 0 00-1.414 0l-4.243 4.243a1 1 0 000 1.414l4.243 4.243a1 1 0 001.414 0zM25.192 20.95l4.243-4.243a1 1 0 000-1.414l-4.243-4.243a1 1 0 00-1.414 0l-4.242 4.243a1 1 0 000 1.414l4.242 4.243a1 1 0 001.414 0zM16.707 29.435l4.243-4.242a1 1 0 000-1.415l-4.243-4.242a1 1 0 00-1.414 0l-4.243 4.242a1 1 0 000 1.415l4.243 4.242a1 1 0 001.414 0zM16.707 12.464l4.243-4.242a1 1 0 000-1.414l-4.243-4.243a1 1 0 00-1.414 0L11.05 6.808a1 1 0 000 1.414l4.243 4.242a1 1 0 001.414 0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8.222 20.95l4.242-4.243a1 1 0 000-1.414L8.223 11.05a1 1 0 00-1.414 0l-4.243 4.243a1 1 0 000 1.414l4.243 4.243a1 1 0 001.414 0zM25.192 20.95l4.243-4.243a1 1 0 000-1.414l-4.243-4.243a1 1 0 00-1.414 0l-4.242 4.243a1 1 0 000 1.414l4.242 4.243a1 1 0 001.414 0zM16.707 29.435l4.243-4.242a1 1 0 000-1.415l-4.243-4.242a1 1 0 00-1.414 0l-4.243 4.242a1 1 0 000 1.415l4.243 4.242a1 1 0 001.414 0zM16.707 12.464l4.243-4.242a1 1 0 000-1.414l-4.243-4.243a1 1 0 00-1.414 0L11.05 6.808a1 1 0 000 1.414l4.243 4.242a1 1 0 001.414 0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M25 18h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zM13 6H7a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V7a1 1 0 00-1-1zM25 6h-6a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1V7a1 1 0 00-1-1zM13 18H7a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Services;

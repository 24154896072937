import { cloneDeep } from 'lodash';
import { createContext, useContext } from 'react';

import { BulkActionsProps } from '../types';

import { BulkActionsProviderProps } from './EditableContext.types';

const BulkActionsContext = createContext<BulkActionsProps<any> | undefined>(
  undefined,
);

export const useBulkActions = () => {
  const context = useContext(BulkActionsContext);
  if (!context) {
    throw new Error('useBulkActions must be used within a BulkActionsProvider');
  }
  return context;
};

export const BulkActionsProvider = <Data extends object>({
  children,
  bulkActions,
}: BulkActionsProviderProps<Data>) => {
  const value = cloneDeep(bulkActions);

  return (
    <BulkActionsContext.Provider value={value}>
      {children}
    </BulkActionsContext.Provider>
  );
};

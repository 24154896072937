import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ManualMapping = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM22.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM13 22.5h6M1 15h30M5 15l6.162-8.8a1 1 0 011.65.013l2.35 3.537a1 1 0 001.675 0l2.35-3.537a1 1 0 011.65-.013L27 15"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM21.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM14 22.5h4M1 13h30M5 13l6.162-8.8a1 1 0 011.65.013l2.35 3.537a1 1 0 001.675 0l2.35-3.537a1 1 0 011.65-.013L27 13"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M10.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM21.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM14 22.5h4M1 13h30M5 13l6.162-8.8a1 1 0 011.65.013l2.35 3.537a1 1 0 001.675 0l2.35-3.537a1 1 0 011.65-.013L27 13"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM22.5 26a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM13 22.5h6M1 15h30M5 15l6.162-8.8a1 1 0 011.65.013l2.35 3.537a1 1 0 001.675 0l2.35-3.537a1 1 0 011.65-.013L27 15" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ManualMapping;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Analysis = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 6H5a1 1 0 00-1 1v15a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1zM20 23l4 5M12 23l-4 5M12 15v3M16 13v5M20 11v7M16 6V3"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 6H5a1 1 0 00-1 1v15a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1zM20 23l4 5M12 23l-4 5M12 13v5M16 11v7M20 12v6M16 6V3"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 6H5a1 1 0 00-1 1v15a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1zM20 23l4 5M12 23l-4 5M12 13v5M16 11v7M20 12v6M16 6V3"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M27 6H5a1 1 0 00-1 1v15a1 1 0 001 1h22a1 1 0 001-1V7a1 1 0 00-1-1zM20 23l4 5M12 23l-4 5M12 15v3M16 13v5M20 11v7M16 6V3" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Analysis;

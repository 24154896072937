import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const CorporateActions = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13 10v15.465a1 1 0 01-.445.832l-1.374.916a1 1 0 01-1.525-.59L8 20"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 20a5 5 0 010-10h5s6.806 0 13.356-5.494A1 1 0 0128 5.271v19.458a1 1 0 01-1.644.764C19.806 20 13 20 13 20H8z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.253 14.752l4.963 11.256a.796.796 0 01-.057.748l-.706 1.108a.795.795 0 01-1.3.06l-3.33-4.29"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.823 23.635a3.977 3.977 0 01-3.209-7.278l3.64-1.605s4.953-2.184 7.957-8.284a.796.796 0 011.442.03l6.244 14.16a.795.795 0 01-.951 1.084c-6.53-1.896-11.484.288-11.484.288l-3.639 1.605zM26.052 5.2l-1.307 2.584-1.322-.593M29.89 13.807l-2.458 1.533-.822-1.192M30.727 8.255l-2.72 3.075-1.599-1.287"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.253 14.752l4.963 11.255a.795.795 0 01-.057.749l-.706 1.107a.795.795 0 01-1.3.06l-3.33-4.289"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.823 23.635a3.977 3.977 0 01-3.209-7.278l3.64-1.604s4.953-2.184 7.957-8.284a.795.795 0 011.442.029l6.244 14.161a.795.795 0 01-.951 1.084c-6.53-1.896-11.484.288-11.484.288l-3.639 1.604zM26.052 5.2l-1.307 2.584-1.322-.593M29.89 13.807l-2.458 1.533-.822-1.192M30.727 8.255l-2.72 3.075-1.599-1.287"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M13 10v15.465a1 1 0 01-.445.832l-1.374.916a1 1 0 01-1.525-.59L8 20" />
            <path d="M8 20a5 5 0 010-10h5s6.806 0 13.356-5.494A1 1 0 0128 5.271v19.458a1 1 0 01-1.644.764C19.806 20 13 20 13 20H8z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default CorporateActions;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const NoData = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M21.1 23.588l.487 2.95M16 24v4M10.9 23.588l-.488 2.95M22.125 8.6C26.788 9.575 30 11.625 30 14c0 3.312-6.262 6-14 6S2 17.312 2 14c0-2.375 3.237-4.438 7.925-5.4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 12.488a1.975 1.975 0 001.55 1.938c1.789.4 3.617.592 5.45.575a23.919 23.919 0 005.45-.575A1.975 1.975 0 0023 12.487v-.487a6.998 6.998 0 00-7.088-7C12.063 5.05 9 8.263 9 12.1v.387z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21.1 23.588l.487 2.95M16 24v4M10.9 23.588l-.488 2.95M22.125 8.6C26.788 9.575 30 11.625 30 14c0 3.312-6.262 6-14 6S2 17.312 2 14c0-2.375 3.237-4.438 7.925-5.4" />
            <path d="M9 12.488a1.975 1.975 0 001.55 1.938c1.789.4 3.617.592 5.45.575a23.919 23.919 0 005.45-.575A1.975 1.975 0 0023 12.487v-.487a6.998 6.998 0 00-7.088-7C12.063 5.05 9 8.263 9 12.1v.387z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default NoData;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const RequestReport1 = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_36789_881)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 4v7h7" />
            <path d="M18 28h7a1 1 0 001-1V11l-7-7H7a1 1 0 00-1 1v11M7.243 22.414h8.485" />
            <path d="M11.728 18.414l4 4-4 4" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_36789_881">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_36789_879)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 4v7h7" />
            <path d="M18 28h7a1 1 0 001-1V11l-7-7H7a1 1 0 00-1 1v11M7.243 22.414h8.485" />
            <path d="M11.728 18.414l4 4-4 4" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_36789_879">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_36789_878)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 4v7h7" />
            <path d="M18 28h7a1 1 0 001-1V11l-7-7H7a1 1 0 00-1 1v11M7.243 22.414h8.485" />
            <path d="M11.728 18.414l4 4-4 4" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_36789_878">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            clipPath="url(#prefix__clip0_36789_880)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 4v7h7" />
            <path d="M18 28h7a1 1 0 001-1V11l-7-7H7a1 1 0 00-1 1v11M7.243 22.414h8.485" />
            <path d="M11.728 18.414l4 4-4 4" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_36789_880">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};

export default RequestReport1;

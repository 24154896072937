import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const WhiteLabel = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M18.667 2.667H8a2.667 2.667 0 00-2.667 2.666v21.334A2.667 2.667 0 008 29.332h16a2.667 2.667 0 002.667-2.666v-16l-8-8z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.667 2.667v8h8M12 17.334h-1.333M12 22.666h-1.333M12 12H10.667"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M18.667 2.667H8a2.667 2.667 0 00-2.667 2.666v21.334A2.667 2.667 0 008 29.332h16a2.667 2.667 0 002.667-2.666v-16l-8-8z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.667 2.667v8h8M21.333 17.334H10.667M21.333 22.666H10.667M13.333 12h-2.666"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M18.667 2.667H8a2.667 2.667 0 00-2.667 2.666v21.334A2.667 2.667 0 008 29.332h16a2.667 2.667 0 002.667-2.666v-16l-8-8z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.667 2.667v8h8M21.333 17.334H10.667M21.333 22.666H10.667M13.333 12h-2.666"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M18.667 2.667H8a2.667 2.667 0 00-2.667 2.666v21.334A2.667 2.667 0 008 29.332h16a2.667 2.667 0 002.667-2.666v-16l-8-8z" />
            <path d="M18.667 2.667v8h8M12 17.334h-1.333M12 22.666h-1.333M12 12H10.667" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default WhiteLabel;

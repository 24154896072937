import { useTheme as useMuiTheme } from '@mui/material/styles';
import { merge } from 'lodash';

import { defaultTheme } from './theme';
import { KitTheme } from './theme.types';

export const useTheme = <T = KitTheme,>(): T => {
  const theme = useMuiTheme<T>();
  return merge(defaultTheme, theme);
};

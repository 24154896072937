import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const BrandingSettings = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 9H5a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1V10a1 1 0 00-1-1zM11 9a5 5 0 0110 0M27.5 22H4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.676 23.85a1.86 1.86 0 100-3.718 1.86 1.86 0 000 3.719z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 15.334H4.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.726 17.002a1.86 1.86 0 100-3.719 1.86 1.86 0 000 3.72z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 9H5a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1V10a1 1 0 00-1-1zM11 9a5 5 0 0110 0M27.5 22H4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.86 23.85a1.86 1.86 0 100-3.718 1.86 1.86 0 000 3.719z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 15.334H4.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.86 17.002a1.86 1.86 0 100-3.719 1.86 1.86 0 000 3.72z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 9H5a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1V10a1 1 0 00-1-1zM11 9a5 5 0 0110 0M27.5 22H4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.86 23.85a1.86 1.86 0 100-3.718 1.86 1.86 0 000 3.719z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 15.334H4.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.86 17.002a1.86 1.86 0 100-3.719 1.86 1.86 0 000 3.72z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M27 9H5a1 1 0 00-1 1v16a1 1 0 001 1h22a1 1 0 001-1V10a1 1 0 00-1-1zM11 9a5 5 0 0110 0M27.5 22H4" />
            <path
              d="M20.676 23.85a1.86 1.86 0 100-3.718 1.86 1.86 0 000 3.719z"
              fill="currentColor"
            />
            <path d="M28 15.334H4.5" />
            <path
              d="M10.726 17.002a1.86 1.86 0 100-3.719 1.86 1.86 0 000 3.72z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default BrandingSettings;

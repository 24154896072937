import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Block = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5 14.333V7a1 1 0 011-1h20a1 1 0 011 1v7.333c0 10.502-8.913 13.982-10.693 14.572a.944.944 0 01-.614 0C13.913 28.315 5 24.835 5 14.333z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 22.001A6 6 0 1016 10a6 6 0 000 12.001z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
          />
          <path
            d="M12 12l8 8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M5 14.333V7a1 1 0 011-1h20a1 1 0 011 1v7.333c0 10.502-8.913 13.982-10.693 14.572a.944.944 0 01-.614 0C13.913 28.315 5 24.835 5 14.333z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16 22.001A6 6 0 1016 10a6 6 0 000 12.001z"
              strokeMiterlimit={10}
            />
            <path d="M12 12l8 8" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Block;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const CardDetails = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 25a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.583 19.583H23V22.5h.417"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.77 17.708a.125.125 0 11.25 0 .125.125 0 01-.25 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 24a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.583 18.583H23V21.5h.417"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.77 16.708a.125.125 0 11.25 0 .125.125 0 01-.25 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 24a5 5 0 100-10 5 5 0 000 10z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.583 18.583H23V21.5h.417"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.77 16.708a.125.125 0 11.25 0 .125.125 0 01-.25 0z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4} stroke="currentColor" strokeWidth={1.5}>
            <path
              d="M14.91 24.727H6.181A2.182 2.182 0 014 22.545V9.455c0-1.206.977-2.183 2.182-2.183h19.636c1.205 0 2.182.977 2.182 2.182v2.182M4 13.817h11"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23 25a5 5 0 100-10 5 5 0 000 10z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.583 19.583H23V22.5h.417"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.77 17.708a.125.125 0 11.25 0 .125.125 0 01-.25 0z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default CardDetails;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Money = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M29 8H3a1 1 0 00-1 1v14a1 1 0 001 1h26a1 1 0 001-1V9a1 1 0 00-1-1z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 20a4 4 0 100-8 4 4 0 000 8zM22 8l8 7M22 24l8-7M10 8l-8 7M10 24l-8-7"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M29 8H3a1 1 0 00-1 1v14a1 1 0 001 1h26a1 1 0 001-1V9a1 1 0 00-1-1z" />
            <path d="M16 20a4 4 0 100-8 4 4 0 000 8zM22 8l8 7M22 24l8-7M10 8l-8 7M10 24l-8-7" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Money;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const BookFunds = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 11a4 4 0 014-4h8a1 1 0 011 1v16a1 1 0 01-1 1h-8a4 4 0 00-4 4M3 24a1 1 0 001 1h8a4 4 0 014 4V11a4 4 0 00-4-4H4a1 1 0 00-1 1v16zM20 12h5M20 16h5M20 20h5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 9a4 4 0 014-4h8a1 1 0 011 1v16a1 1 0 01-1 1h-8a4 4 0 00-4 4M3 22a1 1 0 001 1h8a4 4 0 014 4V9a4 4 0 00-4-4H4a1 1 0 00-1 1v16zM7 10h5M7 14h5M7 18h5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 9a4 4 0 014-4h8a1 1 0 011 1v16a1 1 0 01-1 1h-8a4 4 0 00-4 4M3 22a1 1 0 001 1h8a4 4 0 014 4V9a4 4 0 00-4-4H4a1 1 0 00-1 1v16zM7 10h5M7 14h5M7 18h5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 11a4 4 0 014-4h8a1 1 0 011 1v16a1 1 0 01-1 1h-8a4 4 0 00-4 4M3 24a1 1 0 001 1h8a4 4 0 014 4V11a4 4 0 00-4-4H4a1 1 0 00-1 1v16zM20 12h5M20 16h5M20 20h5" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default BookFunds;

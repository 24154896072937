import { Checkbox } from '../../../Inputs/Checkbox/Checkbox';
import type { DefaultTableCellProps } from '../../TableBody/types';

/**
 * boolean: disabled Checkbox
 * null/undefined: –
 * string[]: string with ,
 */
export const DefaultTableCell = <Data extends object>(
  props: DefaultTableCellProps<Data> & {
    FormattedTableCell: (props: DefaultTableCellProps<Data>) => JSX.Element;
  },
) => {
  const { value, column, FormattedTableCell } = props;
  const { formatting } = column;
  if (formatting) {
    return <FormattedTableCell value={value} column={column} />;
  }
  switch (true) {
    case value === null || value === undefined || value === '':
      return '–';

    case typeof value === 'boolean':
      return <Checkbox checked={value} disabled title="" />;

    case Array.isArray(value) &&
      value.every((item) => typeof item === 'string'):
      return value.join(', ');

    case typeof value === 'object':
      return JSON.stringify(value);

    default:
      return value;
  }
};

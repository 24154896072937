import * as React from 'react';

import { StyledIconWrapper } from './IconWrapper.styled';
import { DEFAULT_APPLY_STATES } from './constants';
import { IconProps, IconStates } from './types';

export const IconWrapper: React.FC<IconStates & IconProps> = ({
  applyStates = DEFAULT_APPLY_STATES,
  dataTestId = '',
  iconActive,
  iconDefault,
  iconDisabled,
  iconHover,
  isActive,
  isHover,
  disabled,
  ...restProps
}) => {
  let icon = iconDefault;

  if (isHover && applyStates) {
    icon = iconHover || iconDefault;
  }
  if (isActive && applyStates) {
    icon = iconActive || iconDefault;
  }
  if (disabled && applyStates) {
    icon = iconDisabled || iconDefault;
  }

  return (
    <StyledIconWrapper data-test-id={dataTestId} {...restProps}>
      {icon}
    </StyledIconWrapper>
  );
};

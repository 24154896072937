import { Skeleton } from './Skeleton';
import {
  PaginationStyled,
  PaginationContainerStyled,
  SkeletonContainerStyled,
} from './TableSkeleton.styled';
import { paginationCount } from './const';

export const TableFooterSkeleton = () => {
  const paginationPart = (
    <SkeletonContainerStyled data-test-id="pagination-part">
      <Skeleton width={112} height={16} sx={{ mr: 2 }} />
      <Skeleton width={56} height={32} />
    </SkeletonContainerStyled>
  );

  return (
    <PaginationContainerStyled data-test-id="pagination-container">
      {paginationPart}
      <PaginationStyled>
        {paginationCount.map((i) => (
          <Skeleton
            data-test-id="pagination-count-skeleton"
            key={i}
            sx={{ mx: 1 }}
          />
        ))}
      </PaginationStyled>
      {paginationPart}
    </PaginationContainerStyled>
  );
};

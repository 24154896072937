export const getMaskFromFormat = (dateFormat: string) => {
  return dateFormat
    .replaceAll('yyyy', '0000')
    .replaceAll('MM', '00')
    .replaceAll('dd', '00')
    .replaceAll('M', '0[0]')
    .replaceAll('d', '0[0]')
    .replaceAll('HH', '00')
    .replaceAll('mm', '00')
    .replaceAll('ss', '00')
    .replaceAll('H', '0[0]')
    .replaceAll('m', '0[0]')
    .replaceAll('s', '0[0]');
};

import { Row, IdType } from 'react-table';

const sortFn = (a: string | number, b: string | number) => {
  if (a > b) {
    return 1;
  }

  if (a < b) {
    return -1;
  }

  return 0;
};

const convertToNumberOrString = (value: any): string | number =>
  Number.isNaN(Number(value)) ? value.toLowerCase() : Number(value);

export const sortNumeric = <Data extends object>(
  { original: originalA }: Partial<Row<Data>>,
  { original: originalB }: Partial<Row<Data>>,
  accessor: IdType<Data>,
) => {
  const fieldA = convertToNumberOrString(originalA?.[accessor as keyof Data]);
  const fieldB = convertToNumberOrString(originalB?.[accessor as keyof Data]);

  return sortFn(fieldA, fieldB);
};

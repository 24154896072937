import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Build = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28 22.163V9.838a1.013 1.013 0 00-.512-.875l-11-6.188a.975.975 0 00-.976 0l-11 6.188A1.012 1.012 0 004 9.838v12.325a1.012 1.012 0 00.513.875l11 6.187a.976.976 0 00.975 0l11-6.187a1.013 1.013 0 00.512-.875z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.862 9.325L16.112 16 4.138 9.325M16.113 16L16 29.35"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.125 5.938L16 9.387l-6.125-3.45M22 26.125v-6.888l6-3.375M10 26.125v-6.888l-6-3.375"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.883 20.268V7.943a1.013 1.013 0 00-.512-.875l-11-6.187a.975.975 0 00-.975 0l-11 6.187a1.013 1.013 0 00-.513.875v12.325a1.012 1.012 0 00.513.875l11 6.188a.975.975 0 00.975 0l11-6.188a1.013 1.013 0 00.512-.875z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.746 7.43l-11.75 6.676L4.02 7.43M15.996 14.105l-.113 13.35"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.008 4.043l-6.125 3.45-6.125-3.45M21.883 24.23v-6.887l6-3.375M9.883 24.23v-6.887l-6-3.375"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27.883 20.268V7.943a1.013 1.013 0 00-.512-.875l-11-6.187a.975.975 0 00-.975 0l-11 6.187a1.013 1.013 0 00-.513.875v12.325a1.012 1.012 0 00.513.875l11 6.188a.975.975 0 00.975 0l11-6.188a1.013 1.013 0 00.512-.875z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.746 7.43l-11.75 6.676L4.02 7.43M15.996 14.105l-.113 13.35"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.008 4.043l-6.125 3.45-6.125-3.45M21.883 24.23v-6.887l6-3.375M9.883 24.23v-6.887l-6-3.375"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M28 22.163V9.838a1.013 1.013 0 00-.512-.875l-11-6.188a.975.975 0 00-.976 0l-11 6.188A1.012 1.012 0 004 9.838v12.325a1.012 1.012 0 00.513.875l11 6.187a.976.976 0 00.975 0l11-6.187a1.013 1.013 0 00.512-.875z" />
            <path d="M27.862 9.325L16.112 16 4.138 9.325M16.113 16L16 29.35" />
            <path d="M22.125 5.938L16 9.387l-6.125-3.45M22 26.125v-6.888l6-3.375M10 26.125v-6.888l-6-3.375" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Build;

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Exit = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16.333 5.333v9.5M22.333 6.108a11 11 0 11-12 0"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 6.667v2.666M21.09 8a9.414 9.414 0 013.669 4.646 9.522 9.522 0 01.189 5.94 9.431 9.431 0 01-3.366 4.875A9.27 9.27 0 0116 25.333a9.271 9.271 0 01-5.583-1.872 9.43 9.43 0 01-3.365-4.875 9.522 9.522 0 01.19-5.94A9.413 9.413 0 0110.908 8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 6.667v2.666M21.09 8a9.414 9.414 0 013.669 4.646 9.522 9.522 0 01.189 5.94 9.431 9.431 0 01-3.366 4.875A9.27 9.27 0 0116 25.333a9.271 9.271 0 01-5.583-1.872 9.43 9.43 0 01-3.365-4.875 9.522 9.522 0 01.19-5.94A9.413 9.413 0 0110.908 8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16.333 5.333v9.5M22.333 6.108a11 11 0 11-12 0" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Exit;

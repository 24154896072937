import { Locale } from 'date-fns';

import {
  DATE_FORMATS,
  DEFAULT_DATE_FORMAT,
  DEFAULT_LOCALE,
} from '../../../constants';

export const selectDateFormat = (
  locale?: string | Locale,
  datetime?: boolean,
  withSeconds?: boolean,
): string => {
  let selectedDateFormat: string = DEFAULT_DATE_FORMAT;

  if (locale) {
    const localeISO: string =
      typeof locale === 'string'
        ? locale
        : locale.code || DEFAULT_LOCALE.toLowerCase();
    const localeDateFormat = DATE_FORMATS[localeISO.toLowerCase()];
    if (localeDateFormat) {
      selectedDateFormat = localeDateFormat;
    }
  }

  if (datetime) {
    const suffix = withSeconds ? ':ss' : '';
    selectedDateFormat = `${selectedDateFormat} HH:mm${suffix}`;
  }

  return selectedDateFormat;
};

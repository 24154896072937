import { Button as MaterialButton } from '@mui/material';

import { blockNonNativeProps } from '../../../helpers';
import { styled } from '../../../theme';

import { ButtonProps } from './BulkActions.types';

const nonNativeButtonProps = ['iconColor', 'margin'];

export const Wrapper = styled('div')(({ theme }) => ({
  color: theme?.color.typo.promo,
  backgroundColor: theme?.color.bg.primary,
  padding: '0 24px 16px 24px',
}));

export const SelectLines = styled('div')(() => ({
  fontSize: 13,
  marginBottom: 8,
  lineHeight: '24px',
}));

export const Button = styled(MaterialButton, {
  shouldForwardProp: blockNonNativeProps(nonNativeButtonProps),
})<ButtonProps>(({ theme, iconColor, margin }) => ({
  minWidth: 'auto',
  padding: 0,
  margin: margin || 0,
  '& > svg': {
    width: 32,
    height: 32,
    margin: '0 10px 0 -2px',
    ...(iconColor ? { stroke: theme?.color.icon[iconColor] } : null),
  },
  '& > span': {
    fontSize: '17px',
    fontFamily: 'Inter,sans-serif',
    fontWeight: '400',
    textTransform: 'initial',
    ...(iconColor ? { color: theme?.color.icon[iconColor] } : null),
  },
})) as React.ComponentType<ButtonProps>;

import { styled } from '../../theme';

export const Message = styled('div')(({ theme }) => ({
  color: theme.color.typo.inverse,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& a': {
    color: theme.color.typo.inverse,
    textDecoration: 'underline',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.size.text.lg,
  lineHeight: 1.3,
  width: '100%',
  minHeight: '24px',
}));

export const DescriptionContainer = styled('div')(() => ({
  display: 'flex',
  maxHeight: '300px',
  overflowY: 'auto',
  width: '110%',
  marginTop: '6px',
}));

export const Description = styled('div')(() => ({
  display: 'flex',
  fontSize: '14px',
  lineHeight: 1.3,
  overflowWrap: 'anywhere',
  paddingRight: '5%',
}));

import { FC, useCallback, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { useTheme } from '../../../theme';

import { TCalendarIconProps } from './types';

export const CalendarIcon: FC<TCalendarIconProps> = ({
  className,
  dataTestId,
  active = false,
  disabled = false,
}) => {
  const [hoverIndex, setHoverIndex] = useState(0);
  const theme = useTheme();
  const { color } = theme;

  const plusShown = active || (!disabled && hoverIndex);

  const hoverColors = [color.typo.secondary, color.typo.promo];

  const opacity = disabled ? 0.4 : 1;

  const animationProps = useSpring({
    lineColor: active ? color.typo.primary : hoverColors[hoverIndex],
    plusOpacity: plusShown ? 1 : 0,
  });

  const handleMouseEnter = useCallback(() => {
    setHoverIndex(1);
  }, []);

  const handleMouseOver = useCallback(() => {
    setHoverIndex(0);
  }, []);

  return (
    <svg
      className={className}
      data-test-id={dataTestId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOver}
      opacity={opacity}
    >
      <animated.path
        d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d="M16.5 2.25V5.25"
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d="M7.5 2.25V5.25"
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d="M3.75 8.25H20.25"
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d="M14.7126 14.25H9.46265"
        stroke={animationProps.lineColor}
        strokeOpacity={animationProps.plusOpacity}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d="M12.0876 11.625V16.875"
        stroke={animationProps.lineColor}
        strokeOpacity={animationProps.plusOpacity}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const TransferOut = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25.667 13.833v-7.5h-7.5M16.667 15.333l9-9M23 18v8a1 1 0 01-1 1H6a1 1 0 01-1-1V10a1 1 0 011-1h8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.333 13.167v-7.5h-7.5M17.333 14.667l9-9M23 18v8a1 1 0 01-1 1H6a1 1 0 01-1-1V10a1 1 0 011-1h8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.333 13.167v-7.5h-7.5M17.333 14.667l9-9M23 18v8a1 1 0 01-1 1H6a1 1 0 01-1-1V10a1 1 0 011-1h8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M25.667 13.833v-7.5h-7.5M16.667 15.333l9-9M23 18v8a1 1 0 01-1 1H6a1 1 0 01-1-1V10a1 1 0 011-1h8" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default TransferOut;

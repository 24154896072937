import { Row } from 'react-table';

export function getDisplaysOfTreeLines<Data extends object>(
  row: Row<Data>,
  prevRow: Row<Data>,
  nextRow: Row<Data>,
  index: number,
): Record<string, boolean> {
  const isFirstRow = index === 0;

  const displayTopTreeLine = !isFirstRow && row.depth >= (prevRow?.depth || 0);
  const displayBottomTreeLine = row.depth <= nextRow?.depth;
  const displayRoundedTopTreeLine = row.depth > prevRow?.depth;

  return {
    displayTopTreeLine,
    displayBottomTreeLine,
    displayRoundedTopTreeLine,
  };
}

import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const EyeDeactive = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 5l20 22M19.363 19.7a5 5 0 01-6.726-7.4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.25 8.574C4.152 11.154 2 16 2 16s4 8.999 14 8.999a14.753 14.753 0 006.75-1.574M26.076 21.137C28.801 18.697 30 16 30 16s-4-9.001-14-9.001c-.866-.001-1.73.069-2.585.21M16.94 11.089a5.003 5.003 0 014.038 4.44"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 5l20 22M19.363 19.7a5 5 0 01-6.726-7.4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.25 8.574C4.152 11.154 2 16 2 16s4 8.999 14 8.999a14.753 14.753 0 006.75-1.574M26.076 21.137C28.801 18.697 30 16 30 16s-4-9.001-14-9.001c-.866-.001-1.73.069-2.585.21M16.94 11.089a5.003 5.003 0 014.038 4.44"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6 5l20 22M19.363 19.7a5 5 0 01-6.726-7.4"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.25 8.574C4.152 11.154 2 16 2 16s4 8.999 14 8.999a14.753 14.753 0 006.75-1.574M26.076 21.137C28.801 18.697 30 16 30 16s-4-9.001-14-9.001c-.866-.001-1.73.069-2.585.21M16.94 11.089a5.003 5.003 0 014.038 4.44"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 6.999C6 6.999 2 16 2 16s4 8.999 14 8.999S30 16 30 16s-4-9.001-14-9.001z" />
            <path d="M16 21a5 5 0 100-10 5 5 0 000 10z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default EyeDeactive;
